import React, { PureComponent } from "react";
import ReactModal from "react-modal";
import { Switch } from "antd";
import { CloseIcon, DeleteIconBlack } from "../../components/icons";
import { Formik, ErrorMessage } from "formik";
import { createCustomer } from "../../utils/validations";
import ErrorFocus from "../../utils/error-focus";
import InputMask from "react-input-mask";
import PlacesAutocomplete from "../../components/forms/PlaceSearchBox";
import "./styles.scss";
import AddCardComponent from "../payabli/addNewCard";
import visa2 from "../../images/visa_2.svg";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import moment from "moment";
import AddCardPayEngineModal from "../../components/forms/addCardModalPayEngine";

export default class CreateCustomer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        companyName: "",
        displayName: "",
        email: "",
        phone: "",
        fax: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        creditTerms: "15",
        invoiceDelivery: "",
        paymentTerms: "cod",
        customerName: "",
        paymentType: "",
        pricingTier: "",
        dumpRates: {
          defaultOption: "",
          pricePerTonLight: "",
          pricePerTonHeavy: "",
          pricePerTonMixedCD: "",
          pricePerTonWood: "",
          pricePerTonMetal: "",
          pricePerTonRoofing: "",
          pricePerTonMasonry: "",
          pricePerTonBrick: "",
          pricePerTonDirt: "",
          pricePerTonConcrete: "",
          pricePerTonCD: "",
          pricePerTonLowRecovery: "",
          pricePerTonOpenContainer: "",
          pricePerTonClosedContainer: "",
          pricePerTonTrailer: "",
          pricePerTonAsbestos: "",
          pricePerYardLight: "",
          pricePerYardHeavy: "",
          pricePerYardMixedCD: "",
          pricePerYardWood: "",
          pricePerYardMetal: "",
          pricePerYardRoofing: "",
          pricePerYardMasonry: "",
          pricePerYardBrick: "",
          pricePerYardDirt: "",
          pricePerYardConcrete: "",
          pricePerYardCD: "",
          pricePerYardLowRecovery: "",
          pricePerYardOpenContainer: "",
          pricePerYardClosedContainer: "",
          pricePerYardTrailer: "",
          pricePerYardAsbestos: "",
        },
        capoutPrice: "",
        minimumPrice: "",
        invoiceFrequency: "auto",
        billingContactName: "",
        billingEmailAddress: "",
        secondaryBillingContacts:[]
      },
      hidePrice: false,
      isFuelSurchardeFee:true,
      includeAttachments: false,
      isEnvironmentalLoadFee: true,
      isInvoiceByProject:false,
      isLEEDsRequired: false,
      user: {},
      address: "",
      isCardModalOpen: false,
      stripePublicKey: "",
      cardData: {},
      ismodelopen: true,
      pricingTierList: [],
      payabliInfo: {},
      customerPayabliData: {
        StoredMethods: [],
      },
      pleaseWait: false,
      invoiceDeliveryData: [],
      paybliEnable: false,
      secondaryBillingContacts:[],
      newProjects: [],
      isRerender: true,
      validationArray:[],
      projectvalidationArray:[],
      paybliDetails:{}
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getMe();
    this.fetchPricingTier();
    this.fetchInvoiceDeliveryData();
    this.getPaybliIntegration();
  }

  addNewProject = () => {
    let newProjects = this.state.newProjects
    const payload = {
      projectName: "",
      startDate: new Date(),
      endDate: new Date(),
      projectStatus: "active",
      isLEEDsRequired:false
    }
    newProjects.push(payload)
    this.setState({ newProjects: newProjects, isRerender: !this.state.isRerender })
  }

  validateProjects=()=>{
  let   projectvalidationArray=[]
  let   validationArray=[]
  const newProjects=this.state.newProjects
        newProjects.map((project,index)=>{
          if(project.projectName === ""){
             validationArray.push("Project name is required")
             projectvalidationArray.push( 
             {errorMeassage:"Project name is required",
               errorIndex:index
              })
           }else{
             validationArray.push("")
           }
           return null 
       })
   this.setState({validationArray:validationArray,projectvalidationArray:projectvalidationArray})
   if(projectvalidationArray.length !==0){
    return false
  }else{
   return true
  }
  }

  async CreateNewProject(customerData) {
    
    let newProjects = this.state.newProjects;
        const promises = newProjects.map(async (values) => {
          const payload = {
            projectName: values.projectName,
            companyId: customerData?.companyId,
            customerId: customerData?._id,
            startDate: values.startDate,
            endDate: values.endDate,
            projectStatus: values.projectStatus,
            isLEEDsRequired : values.isLEEDsRequired 
          };        
          const {
            value: { data },
          } = await this.props.createProject(payload);
          return data; 
        });
        Promise.all(promises)
          .then((results) => {
            this.setState({ newProjects: [], validateProjects: [], pleaseWait: false });
            this.props.closeModel("customerAdded");
            if (this.props.assignNewCustomer) this.props.assignNewCustomer(customerData, results);
          })
          .catch((error) => {
            console.error(error);
          });

   }

  removeProject = (indexx) => {
    console.log(indexx)
    let newProjects = this.state.newProjects
    const result = newProjects.filter((vv,index)=>{
      return index !== indexx
     });
  if(this.state.projectvalidationArray.length ===1){
    this.setState({validationArray:[]})
  }
    this.setState({ newProjects: result, isRerender: !this.state.isRerender })
  }

  fetchPricingTier = async () => {
    const {
      value: { data },
    } = await this.props.getPricingTier({isFilteredData: true});
    this.setState({
      pricingTierList: data?.pricingList !== undefined ? data?.pricingList : [],
    });
  };

  getPaybliIntegration = async () => {
    const {
      value: { status , data },
    } = await this.props.getPaybliIntegration(); // get paybli integration
    if (status) {
      this.setState({ paybliEnable: true,paybliDetails:data });
    }
  };

  fetchInvoiceDeliveryData = async () => {
    const {
      value: { data },
    } = await this.props.fetchInvoiceDeliveryData();
    console.log(data, "invoiceDeliveryData");
    this.setState({ invoiceDeliveryData: data });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    }
    return null;
  }

  handleSubmit = async (
    {
      phone,
      capoutPrice,
      minimumPrice,
      fax,
      dumpRates,
      mattress,
      tire,
      sofa,
      tv,
      refrigerator,
      appliances,
      hardToHandle,
      overage,
      waitTime,
      travelFee,
      pricingTier,
      creditTerms,
      invoiceDelivery,
      ...values
    },
    { setSubmitting, setErrors }
  ) => {
    const {
      user: { companyId },
      hidePrice,
      isFuelSurchardeFee,
      includeAttachments,
      isEnvironmentalLoadFee,
      isInvoiceByProject,
      isLEEDsRequired,
      cardData
    } = this.state;
    setSubmitting(true);
    let objDefaultOption = {};
    let objTons = {};
    let objYards = {};
    for (let k in dumpRates) {
      if (k !== "defaultOption") {
        if (dumpRates[k] !== "") {
          let value = parseFloat(String(dumpRates[k]).replace(/[^\d]/g, ""));
          dumpRates[k] = value;
          if (k.indexOf("Ton") !== -1) {
            objTons[k] = dumpRates[k];
          } else {
            objYards[k] = dumpRates[k];
          }
        } else {
          dumpRates[k] = "";
        }
      } else {
        objDefaultOption[k] = dumpRates[k];
      }
    }
    if( this.state.projectvalidationArray.length !== 0){
      setSubmitting(false)
    }else{
      this.setState({ pleaseWait: true });
    }
    // let finalDumpRates = {
    //   ...objDefaultOption,
    //   ...objTons,
    //   ...objYards
    // };
    // const additionalItems = [
    //   { label: "Mattress", name: "mattress", value: parseFloat(String(mattress).replace(/[^\d.]/g, "")) },
    //   { label: "Tire", name: "tire", value: parseFloat(String(tire).replace(/[^\d.]/g, "")) },
    //   { label: "Sofa", name: "sofa", value: parseFloat(String(sofa).replace(/[^\d.]/g, "")) },
    //   { label: "Tv", name: "tv", value: parseFloat(String(tv).replace(/[^\d.]/g, "")) },
    //   { label: "Refrigerator", name: "refrigerator", value: parseFloat(String(refrigerator).replace(/[^\d.]/g, "")) },
    //   { label: "Appliances", name: "appliances", value: parseFloat(String(appliances).replace(/[^\d.]/g, "")) },
    //   { label: "Hard To Handle", name: "hardToHandle", value: parseFloat(String(hardToHandle).replace(/[^\d.]/g, "")) },
    //   { label: "Overage", name: "overage", value: parseFloat(String(overage).replace(/[^\d.]/g, "")) },
    //   { label: "Wait Time", name: "waitTime", value: parseFloat(String(waitTime).replace(/[^\d.]/g, "")) },
    //   { label: "Travel Fee", name: "travelFee", value: parseFloat(String(travelFee).replace(/[^\d.]/g, "")) }
    // ];
    // const cp = String(capoutPrice).replace(/[^\d]/g, "") === "" || String(capoutPrice).replace(/[^\d]/g, "") === "0";
    // const mp = String(minimumPrice).replace(/[^\d]/g, "") === "";
    let payload;
    if (this.state.paybliEnable) {
      payload = {
        ...values,
        companyId,
        phone: phone.replace(/[^\d]/g, ""),
        fax: fax.replace(/[^\d]/g, ""),
        hidePrice,
        isFuelSurchardeFee,
        includeAttachments,
        isEnvironmentalLoadFee,
        isInvoiceByProject,
        isLEEDsRequired,
        pricingTier,
        userId: this.props?.user?._id,
        payabliCustomer: this.state.payabliInfo,
        cardData: this.state.customerPayabliData,
        creditTerms: Number(creditTerms),
        invoiceDelivery,
        paymentType: values.paymentType === "" && values.paymentTerms === "cod"  ? "cash" : values.paymentTerms === "account" ? "" :values.paymentType,
        // dumpRates: finalDumpRates,
        // isCapoutPrice: !cp,
        // capoutPrice: cp ? 0 : String(capoutPrice).replace(/[^\d]/g, ""),
        // minimumPrice: mp ? 0 : String(minimumPrice).replace(/[^\d]/g, ""),
        // additionalItems
      };
    } else {
      payload = {
        ...values,
        companyId,
        phone: phone.replace(/[^\d]/g, ""),
        fax: fax.replace(/[^\d]/g, ""),
        hidePrice,
        isFuelSurchardeFee,
        includeAttachments,
        isEnvironmentalLoadFee,
        isInvoiceByProject,
        isLEEDsRequired,
        cardData,
        pricingTier,
        userId: this.props?.user?._id,
        creditTerms: Number(creditTerms),
        invoiceDelivery,
        paymentType: values.paymentType === "" && values.paymentTerms === "cod"  ? "cash" : values.paymentTerms === "account" ? "" :values.paymentType,
        // dumpRates: finalDumpRates,
        // isCapoutPrice: !cp,
        // capoutPrice: cp ? 0 : String(capoutPrice).replace(/[^\d]/g, ""),
        // minimumPrice: mp ? 0 : String(minimumPrice).replace(/[^\d]/g, ""),
        // additionalItems
      };
    }
    const {
      value: { status, message, key,data},
    } = await this.props.createCustomer(payload);
    if (status) {
      this.CreateNewProject(data)
    } else {
      setErrors({ [key]: message });
      this.setState({ pleaseWait: false });
    }
  };

  setDefaultOption({ e, values, setValues }) {
    const { initialValues } = this.state;
    setValues({
      ...values,
      dumpRates: { ...values.dumpRates, defaultOption: e.target.value },
    });
    this.setState({
      initialValues: {
        ...initialValues,
        dumpRates: {
          ...initialValues.dumpRates,
          defaultOption: e.target.value,
        },
      },
    });
  }

  setInitialValue({ field, value, values, setValues }) {
    const { initialValues } = this.state;
    setValues({ ...values, [field]: value });
    this.setState({ initialValues: { ...initialValues, [field]: value } });
  }

  setInitialValues({ e, values, setValues }) {
    const { initialValues } = this.state;
    setValues({ ...values, [e.target.name]: e.target.value });
    this.setState({
      initialValues: { ...initialValues, [e.target.name]: e.target.value },
    });
  }

  setDefaultValues({ field, value, values, setValues }) {
    const { initialValues } = this.state;
    setValues({
      ...values,
      dumpRates: { ...values.dumpRates, [field]: value },
    });
    this.setState({
      initialValues: {
        ...initialValues,
        dumpRates: { ...initialValues.dumpRates, [field]: value },
      },
    });
  }
  assignCardData = async (cardData) => {
    this.setState({ payabliInfo: cardData, isCardModalOpen: false });
    this.getCustomerPayment(cardData?.customerId);
  };

  getCustomerPayment = async (customerId) => {
    const payload = {
      customerId: customerId,
    };
    const {
      value: { data },
    } = await this.props.getPayabliCustomerData(payload);
    this.setState({ customerPayabliData: data });
  };
  insertSlash(val) {
    return val.replace(/^(\d{2})(\d{2})/, "$1/$2");
  }

  closeCardModal = (value) => {
    this.setState({ isCardModalOpen: value });
  };

  addSecondaryBilling = ({values,setValues})=>{
    const secondaryBillingContacts = [...this.state.secondaryBillingContacts,{
    billingContactName:"",
    billingEmailAddress:""
    }]
    this.setState({secondaryBillingContacts,initialValues:{...this.state.initialValues,secondaryBillingContacts}})
    setValues({...values,secondaryBillingContacts})
    }
  
    removeSecondaryBilling = ({index,values,setValues})=>{
    const secondaryBillingContacts = this.state.secondaryBillingContacts
    secondaryBillingContacts.splice(index,1)
    this.setState({secondaryBillingContacts,initialValues:{...this.state.initialValues,secondaryBillingContacts}})
    setValues({...values,secondaryBillingContacts})
    }
  
    changeSecondaryEmail = ({index,setValues,values,e})=>{
      const {name,value} = e.target;
      const spilttedName = name.split(".")[1]
      const secondaryBillingContacts = this.state.secondaryBillingContacts
      secondaryBillingContacts[index][spilttedName] =value
      this.setState({secondaryBillingContacts,initialValues:{...this.state.initialValues,secondaryBillingContacts}})
      setValues({...values,secondaryBillingContacts})
    }

  render() {
    const { initialValues, hidePrice, isCardModalOpen, invoiceDeliveryData,isFuelSurchardeFee, isEnvironmentalLoadFee , isLEEDsRequired , isInvoiceByProject,includeAttachments } =
      this.state;
    return (
      <div>
        <ReactModal
          isOpen={this.props.createCustomerModelOpen}
          onRequestClose={this.props.closeModel}
        >
          <div className="react-modal-dialog react-modal-dialog-centered create__customer-modal">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Create Customer</h5>
              <button
                onClick={() => {
                  this.props.closeModel();
                }}
                type="button"
                className="btn react-modal-close"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="edit__companyinfo-wrapper">
                <div className="custome__container">
                  {/* <div className="btn-back-header">
                    <button className="btn btn-link btn-back">
                      <span onClick={this.props.history.goBack}>
                        <BackArrowIcon />
                        Back
                      </span>
                    </button>
                  </div> */}
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={createCustomer}
                    onSubmit={(values, formikProps) =>{
                       if(this.validateProjects()){
                         this.handleSubmit(values, formikProps)
                       }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setValues,
                    }) => {
                      if (values.paymentTerms === "account") {
                        createCustomer.fields.creditTerms =
                          Yup.string().required("Billing Terms  is required");
                        createCustomer.fields.invoiceDelivery = 
                          Yup.string()?.trim()?.required("Invoice Delivery is required");
                        if (!createCustomer._nodes.includes("creditTerms")) {
                          createCustomer._nodes.push("creditTerms");
                        }
                        if (
                          !createCustomer._nodes.includes("invoiceDelivery")
                        ) {
                          createCustomer._nodes.push("invoiceDelivery");
                        }
                      }
                      if (values.paymentTerms === "cod") {
                        createCustomer.fields.invoiceDelivery = 
                          Yup.string()?.trim()?.required("Invoice Delivery is required");
                          if (
                            !createCustomer._nodes.includes("invoiceDelivery")
                          ) {
                            createCustomer._nodes.push("invoiceDelivery");
                          }
                        if (
                          createCustomer._nodes.includes("invoiceDelivery") &&
                          createCustomer._nodes.includes("creditTerms")
                        ) {
                          
                          let invoiceindex =
                            createCustomer._nodes.indexOf("invoiceDelivery");
                          let creditIndex =
                            createCustomer._nodes.indexOf("creditTerms");
                          if (invoiceindex !== -1 && creditIndex !== -1) {
                            createCustomer._nodes.splice(invoiceindex, 1);
                            createCustomer._nodes.splice(creditIndex, 1);
                          }
                        }
                      }
                      return (
                        <form onSubmit={handleSubmit} noValidate>
                          <ErrorFocus />
                          <div className="card">
                            {/* <div className="card-header d-flex flex-column align-items-start">
                            <div className="row">
                              <div className="col-md-12">
                                <h5 className="card-title">Create Customer</h5>
                              </div>
                            </div>
                          </div> */}
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.companyName}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="companyName"
                                    />
                                     <ErrorMessage
                                      component="span"
                                      name="companyName"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Company Name<span></span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.displayName}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="displayName"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="displayName"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Display Name<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.customerName}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="customerName"
                                      required
                                    />
                                    <label className="material-textfield-label">
                                      Primary Contact Name{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.email}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="email"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="email"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Company Email<span></span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <InputMask
                                      mask="(999) 999-9999"
                                      name="phone"
                                      maskChar=""
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.phone}
                                      className="form-control material-textfield-input"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="phone"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Phone Number<span></span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <InputMask
                                      mask="(999) 999-9999"
                                      name="fax"
                                      maskChar=""
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.fax}
                                      className="form-control material-textfield-input"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="fax"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Fax Number<span></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.billingContactName}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="billingContactName"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="billingContactName"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Billing Contact Name<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.billingEmailAddress}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="billingEmailAddress"
                                      required={values.paymentTerms !== 'cod' || (values.paymentTerms === 'cod' && values.billingEmailAddress)}
                                    />
                                    {values.paymentTerms !== 'cod' && values.billingEmailAddress === "" && (
                                    <ErrorMessage
                                      component="span"
                                      name="billingEmailAddress"
                                      className="invalid-feedback d-block"
                                    />)}
                                    <label className="material-textfield-label">
                                      Biliing Email Address{values.paymentTerms !== 'cod' && <span>*</span>}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {values.secondaryBillingContacts?.length > 0 ?  
                                values.secondaryBillingContacts?.map((v,i)=>{
                              return <div className="row">
                                <div className="col-md-11 p-0 m-0 row">
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={v.billingContactName}
                                      onChange={(e) =>
                                        this.changeSecondaryEmail({
                                          e,
                                          values,
                                          setValues,
                                          index:i
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name={`secondaryBillingContacts[${i}].billingContactName`}
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name={`secondaryBillingContacts[${i}].billingContactName`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Billing Contact Name<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={v.billingEmailAddress}
                                       onChange={(e) =>
                                        this.changeSecondaryEmail({
                                          e,
                                          values,
                                          setValues,
                                          index:i
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name={`secondaryBillingContacts[${i}].billingEmailAddress`}
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name={`secondaryBillingContacts[${i}].billingEmailAddress`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Biliing Email Address<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                </div>
                                <div className="col-md-1 d-flex align-items-center justify-content-center pb-3">
                                <div className="pointer" onClick={()=> this.removeSecondaryBilling({index:i,values,setValues})}>
                                <DeleteIconBlack />
                                </div>
                                </div>
                              </div>
                              })
                              :""}
                               <span
                              type="button"
                              onClick={()=> this.addSecondaryBilling({values,setValues})}
                               className="edit_btn add__container underline mb-1">
                               Add Additional Billing Contact
                               </span>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group material-textfield">
                                    <PlacesAutocomplete
                                      type="text"
                                      name="address"
                                      value={values.address}
                                      onChange={handleChange}
                                      className="form-control material-textfield-input"
                                      handleAddressChange={({
                                        address,
                                        city,
                                        state,
                                        zip,
                                      }) => {
                                        setValues({
                                          ...values,
                                          address,
                                          city,
                                          state,
                                          zip,
                                        });
                                        this.setState({
                                          initialValues: {
                                            ...this.state.initialValues,
                                            address,
                                            city,
                                            state,
                                            zip,
                                          },
                                        });
                                      }}
                                      setValue={(address) =>
                                        this.setState({ address }, () => {
                                          setValues({ ...values, address });
                                        })
                                      }
                                      address={this.state.address}
                                      isRequired={false}
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="address"
                                      className="invalid-feedback d-block"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.city}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="city"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name="city"
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      City<span></span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.state}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="state"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="state"
                                    />
                                    <label className="material-textfield-label">
                                      State<span></span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="text"
                                      value={values.zip}
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      className="form-control material-textfield-input"
                                      name="zip"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="zip"
                                    />
                                    <label className="material-textfield-label">
                                      Zip<span></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name="pricingTier"
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.pricingTier}
                                    >
                                      <option value="">Select</option>
                                      {
                                        this.state.pricingTierList?.map((item) => (
                                            <option value={item?._id}>
                                              {item?.pricingTier}
                                            </option>
                                          )
                                        )}
                                      {/* <option value="account">Account</option> */}
                                    </select>
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="pricingTier"
                                    />
                                    <label className="material-textfield-label">
                                      Pricing Tier<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name="paymentTerms"
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.paymentTerms}
                                    >
                                      <option value="cod">COD</option>
                                      <option value="account">Account</option>
                                    </select>
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="paymentTerms"
                                    />
                                    <label className="material-textfield-label">
                                      Payment Terms <span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name="invoiceFrequency"
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.invoiceFrequency}
                                    >
                                      <option value="auto">
                                        Each Transaction
                                      </option>
                                      <option value="daily">Daily</option>
                                      {values.paymentTerms === "account" && (
                                        <option value="weekly">Weekly</option>
                                      )}
                                      {values.paymentTerms === "account" && (
                                        <option value="monthly">Monthly</option>
                                      )}
                                    </select>
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="invoiceFrequency"
                                    />
                                    <label className="material-textfield-label">
                                      Invoice Frequency
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                {values.paymentTerms === "account" && (
                                  <>
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <select
                                          className="form-control custom-select w-150 material-textfield-input pr-22"
                                          name="creditTerms"
                                          onChange={(e) =>
                                            this.setInitialValues({
                                              e,
                                              values,
                                              setValues,
                                            })
                                          }
                                          value={values.creditTerms}
                                                                                  >
                                          <option value="15">NET 15</option>
                                          <option value="30">NET 30</option>
                                          <option value="45">NET 45</option>
                                          <option value="60">NET 60</option>
                                        </select>
                                        <ErrorMessage
                                          component="span"
                                          className="invalid-feedback d-block"
                                          name="creditTerms"
                                        />
                                        <label className="material-textfield-label">
                                          Billing Terms <span>*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                )}
                              
                                <div className="col-md-6">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name="invoiceDelivery"
                                      onChange={(e) =>
                                        this.setInitialValues({
                                          e,
                                          values,
                                          setValues,
                                        })
                                      }
                                      value={values.invoiceDelivery}
                                    >
                                      <option value="" selected disabled>select</option>
                                      {invoiceDeliveryData?.length > 0 &&
                                        invoiceDeliveryData.map(
                                          (item, index) => (
                                            <option
                                              key={index}
                                              value={item?._id}
                                            >
                                              {item?.name}
                                            </option>
                                          )
                                        )}
                                      {/* <option value="Email">Email</option>
                                    <option value="Mail">Mail</option>
                                    <option value="In-Person">In-Person</option> */}
                                    </select>
                                    <ErrorMessage
                                      component="span"
                                      className="invalid-feedback d-block"
                                      name="invoiceDelivery"
                                    />
                                    <label className="material-textfield-label">
                                      Invoice Delivery<span>*</span>
                                    </label>
                                  </div>
                                  {values.paymentTerms === "account" ? <div className="col-md-6 p-0 mb-4">
                                {!this.state.paybliEnable &&
                                      (!this.state.cardData.token ? (
                                        <p className="no_file-add-card">
                                          <span onClick={() => this.setState({isCardModalOpen:true})}>Add a Card</span>
                                        </p>
                                      ) : (
                                        <p className="no_file-add-card">
                                          {this.state.cardData && this.state.cardData.card_data ? this.state.cardData.card_data.brand : ""} **** {this.state.cardData && this.state.cardData.card_data ? this.state.cardData.card_data.last_4 : ""}
                                        </p>
                                      ))}

                                    {this.state.paybliEnable &&
                                      (this.state.customerPayabliData
                                        ?.StoredMethods.length === 0 ? (
                                        <p className="no_file-add-card">
                                          <span
                                            onClick={() => {
                                              this.setState({
                                                isCardModalOpen: true,
                                              });
                                            }}
                                          >
                                            Add a Card
                                          </span>
                                        </p>
                                      ) : (
                                        <ul className="filter__list">
                                          {this.state.paybliEnable && this.state.customerPayabliData
                                            ?.StoredMethods.length > 0 &&
                                            this.state.customerPayabliData?.StoredMethods.slice(
                                              0,
                                              1
                                            ).map((item) => {
                                              return (
                                                <li className="filter__list-item">
                                                  <span className="d-flex -align-items-center filter__check">
                                                    <label className="custom-checkbox custom-checkbox-line">
                                                      <input
                                                        type="checkbox"
                                                        className="custom-checkbox--input"
                                                      />
                                                      <span className="checkmark"></span>
                                                      <p className="card__number">
                                                        <img
                                                          src={visa2}
                                                          alt=""
                                                        />
                                                        {item.MaskedAccount}{" "}
                                                        <span>
                                                          {this.insertSlash(
                                                            item.ExpDate
                                                          )}{" "}
                                                        </span>
                                                      </p>
                                                    </label>
                                                  </span>
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      ))}
                                </div>:""}
                                </div>
                                {values.paymentTerms === "cod" && (
                                  <div className="col-md-6">
                                    <div className="form-group material-textfield">
                                      <select
                                        className="form-control custom-select w-150 material-textfield-input pr-22"
                                        name="paymentType"
                                        onChange={(e) =>
                                          this.setInitialValues({
                                            e,
                                            values,
                                            setValues,
                                          })
                                        }
                                        value={values.paymentType}
                                      >
                                        <option value="" selected disabled>select</option>
                                        <option value="cash">Cash</option>
                                        <option value="check">Check</option>
                                        <option value="credit-card">
                                          Credit Card On File
                                        </option>
                                        <option value="terminal">
                                          Credit Card On Terminal
                                        </option>
                                      </select>
                                      <label className="material-textfield-label">
                                        Payment Type
                                      </label>
                                    </div>
                                    {values.paymentType === "credit-card" && !this.state.paybliEnable &&
                                      (!this.state.cardData.token ? (
                                        <p className="no_file-add-card">
                                          <span onClick={() => this.setState({isCardModalOpen:true})}>Add a Card</span>
                                        </p>
                                      ) : (
                                        <p className="no_file-add-card">
                                          {this.state.cardData && this.state.cardData.card_data ? this.state.cardData.card_data.brand : ""} **** {this.state.cardData && this.state.cardData.card_data ? this.state.cardData.card_data.last_4 : ""}
                                        </p>
                                      ))}

                                    {values.paymentType === "credit-card" && this.state.paybliEnable &&
                                      (this.state.customerPayabliData
                                        ?.StoredMethods.length === 0 ? (
                                        <p className="no_file-add-card">
                                          <span
                                            onClick={() => {
                                              this.setState({
                                                isCardModalOpen: true,
                                              });
                                            }}
                                          >
                                            Add a Card
                                          </span>
                                        </p>
                                      ) : (
                                        <ul className="filter__list">
                                          {this.state.paybliEnable && this.state.customerPayabliData
                                            ?.StoredMethods.length > 0 &&
                                            this.state.customerPayabliData?.StoredMethods.slice(
                                              0,
                                              1
                                            ).map((item) => {
                                              return (
                                                <li className="filter__list-item">
                                                  <span className="d-flex -align-items-center filter__check">
                                                    <label className="custom-checkbox custom-checkbox-line">
                                                      <input
                                                        type="checkbox"
                                                        className="custom-checkbox--input"
                                                      />
                                                      <span className="checkmark"></span>
                                                      <p className="card__number">
                                                        <img
                                                          src={visa2}
                                                          alt=""
                                                        />
                                                        {item.MaskedAccount}{" "}
                                                        <span>
                                                          {this.insertSlash(
                                                            item.ExpDate
                                                          )}{" "}
                                                        </span>
                                                      </p>
                                                    </label>
                                                  </span>
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      ))}
                                  </div>
                                )}
                              </div>
                            
                              {/* <p className="no_file-add-card">
                                      <span onClick={() => { this.addNewProject() }}>Add a Project </span>
                                    </p> */}
                              <div>
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                    Hide Price
                                  </span>
                                  <Switch
                                    name="hidePrice"
                                    checked={hidePrice}
                                    onChange={() =>
                                      this.setState({ hidePrice: !hidePrice })
                                    }
                                  />
                                  <p
                                    className={
                                      hidePrice
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {hidePrice ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                    Add a Fuel Surcharge
                                  </span>
                                  <Switch
                                    name="isFuelSurchardeFee"
                                    checked={isFuelSurchardeFee}
                                    onChange={() =>
                                      this.setState({ isFuelSurchardeFee: !isFuelSurchardeFee })
                                    }
                                  />
                                  <p
                                    className={
                                      isFuelSurchardeFee
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {isFuelSurchardeFee ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                              { this.props?.user?.company?.isEnvironmentalLoadFee &&
                                <div>
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                    Add Environmental Load Fee
                                  </span>
                                  <Switch
                                    name="isEnvironmentalLoadFee"
                                    checked={isEnvironmentalLoadFee}
                                    onChange={() =>
                                      this.setState({ isEnvironmentalLoadFee: !isEnvironmentalLoadFee })
                                    }
                                  />
                                  <p
                                    className={
                                      isEnvironmentalLoadFee
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {isEnvironmentalLoadFee ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                              }
                              <div className="col-md-6 p-0">
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                  LEEDs Required
                                  </span>
                                  <Switch
                                    name="isLEEDsRequired"
                                    checked={isLEEDsRequired}
                                    onChange={() =>
                                      this.setState({ isLEEDsRequired: !isLEEDsRequired })
                                    }
                                  />
                                  <p
                                    className={
                                      isLEEDsRequired
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {isLEEDsRequired ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                               <div className="col-md-6 p-0">
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                    Invoice by project
                                  </span>
                                  {this.state.initialValues.paymentTerms === "cod" ? (
                                    <Switch
                                      name="isInvoiceByProject"
                                      checked={false} 
                                      disabled
                                    />
                                  ) : (
                                    <Switch
                                      name="isInvoiceByProject"
                                      checked={isInvoiceByProject}
                                      onChange={() =>
                                        this.setState({ isInvoiceByProject: !isInvoiceByProject })
                                      }
                                    />
                                  )}
                                  <p
                                    className={
                                      isInvoiceByProject
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {isInvoiceByProject ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                  Include Order/Invoice/PR PDF attachments on emails
                                  </span>
                                  <Switch
                                    name="includeAttachments"
                                    checked={includeAttachments}
                                    onChange={() =>
                                      this.setState({ includeAttachments: !includeAttachments })
                                    }
                                  />
                                  <p
                                    className={
                                      includeAttachments
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {includeAttachments ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                              {this.state.newProjects.length > 0 && this.state.newProjects.map((newProject, index) =>
                                <div className="customers__add-projects-form">
                                  <div className="card">
                                    <div className="card-header d-flex align-items-center justify-content-between">
                                      <h5 className="card-title">Project</h5>
                                      <button
                                        onClick={() => {
                                            this.removeProject(index)
                                        }}
                                        type="button"
                                        className="btn react-modal-close text-red underline"
                                      >
                                        {/* <CloseIcon /> */}
                                        Remove
                                      </button>
                                    </div>
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group material-textfield">
                                            <input
                                              type="text"
                                              value={newProject.projectName}
                                              onChange={(e) => {
                                                let projectValidation=this.state.validationArray
                                                projectValidation[index]=''
                                                let newProject = this.state.newProjects
                                                newProject[index].projectName=e.target.value
                                                this.setState({ newProjects: [...newProject],projectValidation:[...projectValidation] })
                                              }}
                                              className="form-control material-textfield-input"
                                              name="projectName"
                                              required
                                            />
                                            {/* <ErrorMessage
                                              component="span"
                                              name="projectName"
                                              className="invalid-feedback d-block"
                                            /> */}
                                             <span className="text-red">{this.state.validationArray[index] !== "" ? this.state.validationArray[index] : ""}</span>
                                            <label className="material-textfield-label">
                                              Project Name<span>*</span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group material-textfield">
                                            <select
                                              className="form-control custom-select w-150 material-textfield-input pr-22"
                                              name="projectStatus"
                                              onChange={(e) => {
                                                let newProject = this.state.newProjects
                                                newProject[index].projectStatus = e.target.value
                                                this.setState({ newProjects: newProject, isRerender:!this.state.isRerender })
                                              }}
                                              value={newProject.projectStatus}
                                            >
                                              <option value="active">Active</option>
                                              <option value="inActive">In Active</option>
                                            </select>
                                            {/* <ErrorMessage
                                              component="span"
                                              className="invalid-feedback d-block"
                                              name="projectStatus"
                                            /> */}
                                            <label className="material-textfield-label">
                                            Project Status
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="calendar__form">
                                            <div className="input-from mw-100">
                                              <div className="form-group">
                                                <DatePicker
                                                  name="startDate"
                                                  selected={moment(newProject.startDate, "MMM DD, YYYY").toDate()}
                                                  onChange={(e) => {
                                                    // setValues({ ...values, startDate: moment(e, "MMM DD, YYYY").toDate() })
                                                    let newProject = this.state.newProjects
                                                    newProject[index].startDate = moment(e, "MMM DD, YYYY").toDate() 
                                                    this.setState({ newProjects: [...newProject] })
                                                  }}
                                                  className="form-control material-textfield-input"
                                                  placeholder="10/01/20"
                                                  required
                                                  autoComplete="off"
                                                />
                                                <label className="material-textfield-label label__for-date">Start Date</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="calendar__form">
                                            <div className="input-to mw-100">
                                              <div className="form-group">
                                                <DatePicker
                                                  name="endDate"
                                                  // selected={moment(values.endDate, "MMM DD, YYYY").toDate()}
                                                  selected={moment(newProject.endDate, "MMM DD, YYYY").toDate()}
                                                  onChange={(e) => {
                                                    // setValues({ ...values, endDate: moment(e, "MMM DD, YYYY").toDate() })
                                                    let newProject = this.state.newProjects
                                                    newProject[index].endDate = moment(e, "MMM DD, YYYY").toDate() 
                                                    this.setState({ newProjects: [...newProject] })
                                                  }}
                                                  className="form-control material-textfield-input"
                                                  placeholder="10/01/20"
                                                  required
                                                  autoComplete="off"
                                                />
                                                <label className="material-textfield-label label__for-date">End Date</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                <div className="display-team-status">
                                  <span className="display-team-text">
                                  LEEDs Required
                                  </span>
                                  <Switch
                                    name="isLEEDsRequired"
                                    checked={newProject.isLEEDsRequired}
                                    onChange={(e) => {
                                      let newProject = this.state.newProjects
                                      newProject[index].isLEEDsRequired = !newProject[index].isLEEDsRequired 
                                      this.setState({ newProjects: newProject, isRerender:!this.state.isRerender })
                                    }}
                                    
                                  />
                                  <p
                                    className={
                                      this.state.newProjects[index].isLEEDsRequired 
                                        ? "on-of-text text-green"
                                        : "on-of-text text-red"
                                    }
                                  >
                                    {this.state.newProjects[index].isLEEDsRequired ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                               <p className="no_file-add-card">
                                      <span onClick={() => { this.addNewProject() }}>Add a Project </span>
                                    </p>
                              <div></div>
                              <br/>
                              <button
                                disabled={this.state.pleaseWait}
                                type="submit"
                                className="btn btn-dark btn-lg w-100"
                              >
                                {this.state.pleaseWait
                                  ? "Please Wait ..."
                                  : "Save"}
                              </button>
                            </div>
                          </div>
                          {/* <DumpRates
                          {...this.props}
                          values={values}
                          setValues={setValues}
                          isSubmitting={isSubmitting}
                          setDefaultOption={e => this.setDefaultOption({ e, values, setValues })}
                          setDefaultValues={(field, value) => this.setDefaultValues({ field, value, values, setValues })}
                          setInitialValues={e => this.setInitialValues({ e, values, setValues })}
                          setInitialValue={(field, value) => this.setInitialValue({ field, value, values, setValues })}
                        /> */}
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                {isCardModalOpen && this.state.paybliEnable && (
                  <AddCardComponent
                    paybliDetails={this.state.paybliDetails}
                    customerData={this.state.initialValues}
                    assignCardData={this.assignCardData}
                    closeCardModal={this.closeCardModal}
                  />
                )}
                {isCardModalOpen && !this.state.paybliEnable && (
                  <AddCardPayEngineModal
                    isCardModalOpen={isCardModalOpen}
                    closeModal={() => this.setState({ isCardModalOpen: false })}
                    setCardData={cardData => this.setState({ cardData })}
                  />
                )}
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
