import React, { PureComponent } from "react";
import { BackArrowIcon } from "../../components/icons";
import PlacesAutocomplete from "../../components/forms/PlaceSearchBox";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import _ from "lodash";
import "./styles.scss";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const allFields = [
  "waste",
  "brick",
  "dirt",
  "concrete",
  "cleanwood",
  "metal",
  "paper_cardboard",
  "plastic",
  "drywall",
  "glass",
  "asphalt",
  "totalpercentage"
];
export default class SustainabilityInformationComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      order: {},
      weight: 0,
      containersize: "",
      yardage: 0,
      companyName: "",
      yardagecalculated: [
        { key: "waste", value: 0 },
        { key: "brick", value: 0 },
        { key: "dirt", value: 0 },
        { key: "concrete", value: 0 },
        { key: "cleanwood", value: 0 },
        { key: "metal", value: 0 },
        { key: "paper_cardboard", value: 0 },
        { key: "plastic", value: 0 },
        { key: "drywall", value: 0 },
        { key: "glass", value: 0 },
        { key: "asphalt", value: 0 }
      ],
      tonnagecalculated: [
        { key: "waste", value: 0 },
        { key: "brick", value: 0 },
        { key: "dirt", value: 0 },
        { key: "concrete", value: 0 },
        { key: "cleanwood", value: 0 },
        { key: "metal", value: 0 },
        { key: "paper_cardboard", value: 0 },
        { key: "plastic", value: 0 },
        { key: "drywall", value: 0 },
        { key: "glass", value: 0 },
        { key: "asphalt", value: 0 }
      ],
      tonnagepercentage: [
        { key: "waste", value: 0 },
        { key: "brick", value: 0 },
        { key: "dirt", value: 0 },
        { key: "concrete", value: 0 },
        { key: "cleanwood", value: 0 },
        { key: "metal", value: 0 },
        { key: "paper_cardboard", value: 0 },
        { key: "plastic", value: 0 },
        { key: "drywall", value: 0 },
        { key: "glass", value: 0 },
        { key: "asphalt", value: 0 }
      ],
      tonnageweight: [
        { key: "waste", value: 0 },
        { key: "brick", value: 0 },
        { key: "dirt", value: 0 },
        { key: "concrete", value: 0 },
        { key: "cleanwood", value: 0 },
        { key: "metal", value: 0 },
        { key: "paper_cardboard", value: 0 },
        { key: "plastic", value: 0 },
        { key: "drywall", value: 0 },
        { key: "glass", value: 0 },
        { key: "asphalt", value: 0 }
      ],
      selectedFields: allFields,
      waste: 0,
      brick: 0,
      dirt: 0,
      concrete: 0,
      cleanwood: 0,
      metal: 0,
      paper_cardboard: 0,
      plastic: 0,
      drywall: 0,
      glass: 0,
      asphalt: 0,
      totalpercentage: 0,
      recyclingpercentage: 0,
      residualpercentage: 0,
      weight_total: 0,
      index: -2,
      address: this.props.history?.location?.state?.serviceLocation || "",
      page: "",
      additionalItems: [],
      notes: "",
      cityOfOrigin: "",
      last_page_yardage: 0,
      serviceLocation:"",
       customerRefrence: "",
    };
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    window.scrollTo(0, 0);
    const {
      history: {
        location: {
          state: { orderId, index, page, tonnage, dumpRate, yardage, additionalItems, notes, cityOfOrigin ,serviceLocation,customerRefrence,driver,project,truck, customer, loadImageUrl, plateImageUrl, truckTab, truckInputs, isFuelSurchardeFee, containerSize}
        }
      },
      fetchOrder
    } = this.props;
    this.setState({ index, page, dumpRate, yardage, additionalItems, notes, cityOfOrigin,serviceLocation,customerRefrence, last_page_yardage: yardage,driver,project,truck, customer, loadImageUrl, plateImageUrl, truckTab, truckInputs, isFuelSurchardeFee, containerSize });
    const {
      value: { data }
    } = await fetchOrder(orderId);

    this.setState({ order: data, loading: false });
    if (index >= 0) {
      this.setState({
        selectedFields: data.sustainabilityInfo[index].selectedFields,
        companyName: data.sustainabilityInfo[index].companyName,
        address: data.sustainabilityInfo[index].address,
        weight: tonnage,
        containersize: data.sustainabilityInfo[index].containersize,
        yardage: data.sustainabilityInfo[index].yardage,
        yardagecalculated: data.sustainabilityInfo[index].yardagecalculated,
        tonnagecalculated: data.sustainabilityInfo[index].tonnagecalculated,
        tonnagepercentage: data.sustainabilityInfo[index].tonnagepercentage,
        tonnageweight: data.sustainabilityInfo[index].tonnageweight,
        waste: data.sustainabilityInfo[index].waste,
        brick: data.sustainabilityInfo[index].brick,
        dirt: data.sustainabilityInfo[index].dirt,
        concrete: data.sustainabilityInfo[index].concrete,
        cleanwood: data.sustainabilityInfo[index].cleanwood,
        metal: data.sustainabilityInfo[index].metal,
        paper_cardboard: data.sustainabilityInfo[index].paper_cardboard,
        plastic: data.sustainabilityInfo[index].plastic,
        drywall: data.sustainabilityInfo[index].drywall,
        glass: data.sustainabilityInfo[index].glass,
        asphalt: data.sustainabilityInfo[index].asphalt,
        totalpercentage: data.sustainabilityInfo[index].totalpercentage,
        recyclingpercentage: data.sustainabilityInfo[index].recyclingpercentage,
        residualpercentage: data.sustainabilityInfo[index].residualpercentage,
        weight_total: data.sustainabilityInfo[index].weight_total
      });
    } else {
      this.setState({
        weight: tonnage
      });
    }
  };

  calculationArray = () => {
    let arr = [
      { key: "waste", lbs: 600, tonnage: 0.3 },
      { key: "brick", lbs: 3000, tonnage: 1.5 },
      { key: "dirt", lbs: 5000, tonnage: 2.5 },
      { key: "concrete", lbs: 4050, tonnage: 2.03 },
      { key: "cleanwood", lbs: 300, tonnage: 0.15 },
      { key: "metal", lbs: 1000, tonnage: 0.5 },
      { key: "paper_cardboard", lbs: 100, tonnage: 0.05 },
      { key: "plastic", lbs: 200, tonnage: 0.1 },
      { key: "drywall", lbs: 500, tonnage: 0.25 },
      { key: "glass", lbs: 700, tonnage: 0.35 },
      { key: "asphalt", lbs: 4140, tonnage: 2.07 }
    ];
    return arr;
  };

  isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  decimalPlaces(num) {
    var match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
      return 0;
    }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0) -
        // Adjust for scientific notation.
        (match[2] ? +match[2] : 0)
    );
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.getTotalPercentage();
      this.getRecyclingPercentage();
      this.calculateforall();
      this.getResidualPercentage();
      this.getWeightTotal();
    });
  };

  onchange(field, value) {
    const twodecimal = /^\d*\.?\d{0,2}$/;
    // var isnum = /^\d+$/.test(value);
    // const isdecimal=/^\d+(\.\d{2})?$/;
    switch (field) {
      case "dumpsite":
        this.setState({ dumpsite: value });
        break;
      case "weight":
        this.setState({ weight: value }, () => {
          this.calculateforall();
        });
        break;
      case "totalcost":
        let totalcost = value ? value.replace("$", "") : "";
        this.setState({ totalcost: totalcost.trim() });
        break;
      case "cost":
        let cost = value ? value.replace("$", "") : "";
        this.setState({ cost: cost.trim() });
        break;
      case "dumpticketnumber":
        this.setState({ dumpticketnumber: value });
        break;
      case "waste":
        if (this.decimalPlaces(value) <= 2) {
          this.setState({ waste: value && value.match(twodecimal) ? value : "" }, () => {
            this.getTotalPercentage();
            this.getRecyclingPercentage();
            this.calculateforall();
            this.getResidualPercentage();
            this.getWeightTotal();
          });
        }
        break;
      case "brick":
        if (this.decimalPlaces(value) <= 2) {
          this.setState({ brick: value && value.match(twodecimal) ? value : "" }, () => {
            this.getTotalPercentage();
            this.getRecyclingPercentage();
            this.calculateforall();
            this.getWeightTotal();
          });
        }
        break;
      case "dirt":
        if (this.decimalPlaces(value) <= 2) {
          this.setState({ dirt: value && value.match(twodecimal) ? value : "" }, () => {
            this.getTotalPercentage();
            this.getRecyclingPercentage();
            this.calculateforall();
            this.getWeightTotal();
          });
        }
        break;
      case "concrete":
        if (this.decimalPlaces(value) <= 2) {
          this.setState({ concrete: value && value.match(twodecimal) ? value : "" }, () => {
            this.getTotalPercentage();
            this.getRecyclingPercentage();
            this.calculateforall();
            this.getWeightTotal();
          });
        }
        break;
      case "cleanwood":
        if (this.decimalPlaces(value) <= 2) {
          this.setState({ cleanwood: value && value.match(twodecimal) ? value : "" }, () => {
            this.getTotalPercentage();
            this.getRecyclingPercentage();
            this.calculateforall();
            this.getWeightTotal();
          });
        }
        break;
      case "metal":
        if (this.decimalPlaces(value) <= 2) {
          this.setState({ metal: value && value.match(twodecimal) ? value : "" }, () => {
            this.getTotalPercentage();
            this.getRecyclingPercentage();
            this.calculateforall();
            this.getWeightTotal();
          });
        }
        break;
      case "paper_cardboard":
        if (this.decimalPlaces(value) <= 2) {
          this.setState({ paper_cardboard: value && value.match(twodecimal) ? value : "" }, () => {
            this.getTotalPercentage();
            this.getRecyclingPercentage();
            this.calculateforall();
            this.getWeightTotal();
          });
        }
        break;
      case "plastic":
        if (this.decimalPlaces(value) <= 2) {
          this.setState({ plastic: value && value.match(twodecimal) ? value : "" }, () => {
            this.getTotalPercentage();
            this.getRecyclingPercentage();
            this.calculateforall();
            this.getWeightTotal();
          });
        }
        break;
      case "drywall":
        if (this.decimalPlaces(value) <= 2) {
          this.setState({ drywall: value && value.match(twodecimal) ? value : "" }, () => {
            this.getTotalPercentage();
            this.getRecyclingPercentage();
            this.calculateforall();
            this.getWeightTotal();
          });
        }
        break;
      case "glass":
        if (this.decimalPlaces(value) <= 2) {
          this.setState({ glass: value && value.match(twodecimal) ? value : "" }, () => {
            this.getTotalPercentage();
            this.calculateforall();
            this.getWeightTotal();
          });
        }
        break;
      case "asphalt":
        if (this.decimalPlaces(value) <= 2) {
          this.setState({ asphalt: value && value.match(twodecimal) ? value : "" }, () => {
            this.getTotalPercentage();
            this.getRecyclingPercentage();
            this.getWeightTotal();
            this.calculateforall();
          });
        }
        break;
      default:
        break;
    }
  }

  // getContainerValue(value, order) {
  //   if(value === "5") {
  //     return 5
  //   } else if(value === "10") {
  //     return 10
  //   } else if(value === "15") {
  //     return 15
  //   } else if(value === "20") {
  //     return 20
  //   } else if(value === "25") {
  //     return 25
  //   } else if(value === "30") {
  //     return 30
  //   } else if(value === "40") {
  //     return 40
  //   } else if (value === "other") {
  //     return parseFloat(order.yardage)
  //   }
  // }

  calculateforall() {
    const self = this;
    const yardagecalculated = this.state.yardagecalculated;
    const tonnagecalculated = this.state.tonnagecalculated;
    let sum_of_all_tonnage = 0;
    const arr = [
      "waste",
      "brick",
      "dirt",
      "concrete",
      "cleanwood",
      "metal",
      "paper_cardboard",
      "plastic",
      "drywall",
      "glass",
      "asphalt"
    ];
    _.forEach(arr, function(item) {
      let calculationarray = self.calculationArray();
      let itemValue = self.state[item];
      let yard = self.state.containersize;
      if (yard === "other") {
        yard = self.state.yardage !== "" ? self.state.yardage : 0;
      }
      let yardIndex = _.findIndex(yardagecalculated, function(y) {
        return y.key === item;
      });
      let tonnageIndex = _.findIndex(tonnagecalculated, function(y) {
        return y.key === item;
      });
      let calculationIndex = _.findIndex(calculationarray, function(y) {
        return y.key === item;
      });
      if (itemValue === "") {
        itemValue = parseFloat(0);
      }
      if (yardIndex !== -1) {
        yardagecalculated[yardIndex].value = parseFloat((parseFloat(yard) * parseFloat(itemValue)) / 100);
      }
      if (tonnageIndex !== -1) {
        tonnagecalculated[tonnageIndex].value = parseFloat(
          parseFloat(yardagecalculated[yardIndex].value) * parseFloat(calculationarray[calculationIndex].tonnage)
        );
        sum_of_all_tonnage += parseFloat(tonnagecalculated[tonnageIndex].value);
      }
    });
    _.forEach(arr, function(a) {
      self.calculate(a, sum_of_all_tonnage, tonnagecalculated, yardagecalculated);
    });
  }

  calculate(item, sum_of_all_tonnage, tonnagecalculated, yardagecalculated) {
    // let calculationarray = this.calculationArray()
    const tonnagepercentage = this.state.tonnagepercentage;
    const tonnageweight = this.state.tonnageweight;
    let itemValue = this.state[item];
    //const yard = 30 //this.getContainerValue(this.props.selected.container)
    // let calculationIndex = _.findIndex(calculationarray, function(y) {
    //     return y.key === item
    // })
    let tonnagepercentageIndex = _.findIndex(tonnagepercentage, function(y) {
      return y.key === item;
    });
    let tonnageIndex = _.findIndex(tonnagecalculated, function(y) {
      return y.key === item;
    });
    let tonnageweightIndex = _.findIndex(tonnageweight, function(y) {
      return y.key === item;
    });
    if (itemValue === "") {
      itemValue = parseFloat(0);
    }
    if (tonnagepercentageIndex !== -1) {
      if (sum_of_all_tonnage !== 0) {
        sum_of_all_tonnage = parseFloat(sum_of_all_tonnage);
        const tonnage = tonnagecalculated[tonnageIndex].value;
        const cal = tonnage / sum_of_all_tonnage;
        tonnagepercentage[tonnagepercentageIndex].value = parseFloat(cal * 100);
      } else {
        tonnagepercentage[tonnagepercentageIndex].value = 0;
      }
      if (isNaN(tonnagepercentage[tonnagepercentageIndex].value)) {
        tonnagepercentage[tonnagepercentageIndex].value = 0;
      }
    }
    if (tonnageweightIndex !== -1) {
      tonnageweight[tonnageweightIndex].value = parseFloat(
        (parseFloat(tonnagepercentage[tonnagepercentageIndex].value) * parseFloat(this.state.weight)) / 100
      );
      tonnageweight[tonnageweightIndex].value = parseFloat(tonnageweight[tonnageweightIndex].value.toFixed(2));
      if (isNaN(tonnageweight[tonnageweightIndex].value)) {
        tonnageweight[tonnageweightIndex].value = 0;
      }
    }
    this.setState({ yardagecalculated, tonnagecalculated, tonnagepercentage, tonnageweight }, () => {
      this.getTotalPercentage();
      this.getRecyclingPercentage();
      this.getResidualPercentage();
      this.getWeightTotal();
    });
  }

  getTotalPercentage() {
    let sum = 0;
    const {
      waste,
      brick,
      dirt,
      concrete,
      plastic,
      glass,
      metal,
      cleanwood,
      paper_cardboard,
      drywall,
      asphalt
    } = this.state;
    sum =
      parseFloat(waste !== "" ? waste : 0) +
      parseFloat(brick !== "" ? brick : 0) +
      parseFloat(dirt !== "" ? dirt : 0) +
      parseFloat(concrete !== "" ? concrete : 0) +
      parseFloat(plastic !== "" ? plastic : 0) +
      parseFloat(glass !== "" ? glass : 0) +
      parseFloat(metal !== "" ? metal : 0) +
      parseFloat(cleanwood !== "" ? cleanwood : 0) +
      parseFloat(paper_cardboard !== "" ? paper_cardboard : 0) +
      parseFloat(drywall !== "" ? drywall : 0) +
      parseFloat(asphalt !== "" ? asphalt : 0);

    if (sum > 0) {
      this.setState({ totalpercentage: Math.round(sum * 100) / 100 });
    } else {
      this.setState({ totalpercentage: 0 });
    }
  }

  getWeightTotal() {
    let weight_total = 0;
    const { tonnagepercentage } = this.state;
    _.forEach(tonnagepercentage, function(tonnageper) {
      weight_total += Math.round(parseFloat(tonnageper.value));
    });
    this.setState({ weight_total: parseFloat(weight_total) });
  }

  getRecyclingPercentage() {
    // let sum=0;
    const { tonnagepercentage } = this.state;
    if (tonnagepercentage.length !== 0) {
      this.setState({
        recyclingpercentage: parseFloat(this.state.totalpercentage) - parseFloat(tonnagepercentage[0].value)
      });
    } else {
      this.setState({ recyclingpercentage: 0 });
    }
  }

  getResidualPercentage() {
    // let sum=0;
    const { tonnagepercentage } = this.state;
    if (tonnagepercentage.length !== 0) {
      this.setState({ residualpercentage: tonnagepercentage[0].value });
    } else {
      this.setState({ residualpercentage: 0 });
    }
  }

  focus(e) {
    if (e.target.value === "0") {
      this.setState({ [e.target.name]: "" });
    }
  }
  blur(e) {
    if (e.target.value === "") {
      this.setState({ [e.target.name]: "0" });
    }
  }

  onChangeCheckbox(e) {
    const selectedFields = [...this.state.selectedFields];
    if (e.target.name === "all") {
      if (selectedFields.length === 12) {
        this.setState({ selectedFields: [] });
      } else {
        this.setState({ selectedFields: allFields });
      }
    } else {
      const index = selectedFields.indexOf(e.target.name);
      if (index !== -1) {
        selectedFields.splice(index, 1);
      } else {
        selectedFields.push(e.target.name);
      }
      this.setState({ selectedFields });
    }
  }

  saveInfo = async () => {
    this.setState({ disable: true });
    const { order, index } = this.state;
    let sustainabilityInfo = order.sustainabilityInfo;
    let obj = {};
    obj.selectedFields = this.state.selectedFields;
    obj.companyName = this.state.companyName;
    obj.address = this.state.address;
    obj.containersize = this.state.containersize;
    obj.yardage = this.state.yardage;
    obj.weight = this.state.weight !== "" ? parseFloat(this.state.weight) : 0;
    obj.waste = this.state.waste !== "" ? parseFloat(this.state.waste) : 0;
    obj.brick = this.state.brick !== "" ? parseFloat(this.state.brick) : 0;
    obj.dirt = this.state.dirt !== "" ? parseFloat(this.state.dirt) : 0;
    obj.concrete = this.state.concrete !== "" ? parseFloat(this.state.concrete) : 0;
    obj.cleanwood = this.state.cleanwood !== "" ? parseFloat(this.state.cleanwood) : 0;
    obj.metal = this.state.metal !== "" ? parseFloat(this.state.metal) : 0;
    obj.paper_cardboard = this.state.paper_cardboard !== "" ? parseFloat(this.state.paper_cardboard) : 0;
    obj.plastic = this.state.plastic !== "" ? parseFloat(this.state.plastic) : 0;
    obj.drywall = this.state.drywall !== "" ? parseFloat(this.state.drywall) : 0;
    obj.glass = this.state.glass !== "" ? parseFloat(this.state.glass) : 0;
    obj.asphalt = this.state.asphalt !== "" ? parseFloat(this.state.asphalt) : 0;
    obj.weight_total = this.state.weight_total !== "" ? parseFloat(this.state.weight_total) : 0;
    obj.yardagecalculated = this.state.yardagecalculated;
    obj.tonnagecalculated = this.state.tonnagecalculated;
    obj.tonnagepercentage = this.state.tonnagepercentage;
    obj.tonnageweight = this.state.tonnageweight;
    obj.totalpercentage = this.state.totalpercentage;
    obj.recyclingpercentage = this.state.recyclingpercentage;
    obj.residualpercentage = this.state.residualpercentage;

    if (index >= 0) {
      sustainabilityInfo[index] = obj;
    } else {
      sustainabilityInfo.push(obj);
    }
    const { updateOrderSustainabilityInfo } = this.props;
    const {
      value: { status }
    } = await updateOrderSustainabilityInfo({ id: order._id, sustainabilityInfo });
    this.setState({ disable: false });
    if (status) {
      this.navigate(order.ticketNumber);
    }
  };

  navigate = orderId => {
    const { yardage, dumpRate, additionalItems, notes, cityOfOrigin ,serviceLocation,customerRefrence,driver,project,truck, customer, loadImageUrl, plateImageUrl, truckTab, truckInputs, isFuelSurchardeFee, containerSize} = this.state;
    console.log("rTruck",truck)
    const { page } = this.state;
    if (page === "pending-order-details") {
      this.props.history.push({
        pathname: `/pending-order-details/${orderId}`,
        state: { dumpRate, yardage, additionalItems, notes, cityOfOrigin, serviceLocation,customerRefrence,last_page_yardage: this.state?.last_page_yardage, last_route: "sustainability-information",driver,project,truck, customer, loadImageUrl, plateImageUrl, truckTab, truckInputs, isFuelSurchardeFee, containerSize }
      });
    } else {
      this.props.history.push({
        pathname: `/order-details/${orderId}`,
        state: { dumpRate, yardage, additionalItems, notes, cityOfOrigin,serviceLocation,customerRefrence,routeName:"sustainability-information",driver,project,truck, customer, loadImageUrl, plateImageUrl, truckTab, truckInputs, isFuelSurchardeFee, containerSize }
      });
    }
  };

  render() {
    const { order, selectedFields } = this.state;
    return (
      <div className="sustainability__information-wrapper">
        <div className="custome__container">
          <div className="btn-back-header">
            <button className="btn btn-link btn-back" onClick={() => this.navigate(order.ticketNumber)}>
              <BackArrowIcon />
              Back
            </button>
          </div>
          <div>
            <div className="col-md-12">
            <Spin indicator={antIcon} spinning={this.state.loading} >
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12 d-flex align-items-center justify-content-between">
                      <div>
                        <h5 className="card-title">Sustainability Information</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group material-textfield">
                        <input
                          type="text"
                          value={this.state.companyName}
                          onChange={e => this.setState({ companyName: e.target.value })}
                          className="form-control material-textfield-input"
                          name="companyName"
                          required
                        />
                        <label className="material-textfield-label">Service Customer</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group material-textfield">
                        <PlacesAutocomplete
                          type="text"
                          name="address"
                          value={this.state.address}
                          onChange={this.handleChange.bind(this)}
                          className="form-control material-textfield-input"
                          handleAddressChange={({ address }) => this.setState({ address })}
                          setValue={address => this.setState({ address })}
                          address={this.state.address}
                          isSetToState={true}
                          label="Service Location"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group material-textfield">
                        <select
                          value={this.state.containersize}
                          name="containersize"
                          className="form-control custom-select w-150 material-textfield-input pr-22"
                          required
                          onChange={this.handleChange.bind(this)}
                        >
                          <option>Select Yardage</option>
                          <option value="5">5 Yard</option>
                          <option value="10">10 Yard</option>
                          <option value="15">15 Yard</option>
                          <option value="20">20 Yard</option>
                          <option value="25">25 Yard</option>
                          <option value="30">30 Yard</option>
                          <option value="40">40 Yard</option>
                          <option value="other">Other</option>
                        </select>
                        <label className="material-textfield-label">Yardage</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {this.state.containersize === "other" ? (
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="yardage"
                            className="form-control material-textfield-input"
                            value={this.state.yardage}
                            onChange={this.handleChange.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Yardage</label>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="card__body-inner">
                    <ul className="check__list all__check-list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="all"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.length === 12}
                            />
                            <span className="checkmark"></span>
                            <p>Select All</p>
                          </label>
                        </span>
                      </li>
                    </ul>
                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="totalpercentage"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("totalpercentage") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            disabled={true}
                            value={this.state.totalpercentage}
                            required
                          />
                          <label className="material-textfield-label">Total % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            disabled={true}
                            value={Math.round(this.state.weight_total)}
                            required
                          />
                          <label className="material-textfield-label">Total % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.weight}
                            onChange={event => this.onchange("weight", event.target.value)}
                            required
                          />
                          <label className="material-textfield-label">Weight (tons)</label>
                        </div>
                      </li>
                    </ul>

                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="waste"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("waste") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="waste"
                            value={this.state.waste}
                            onChange={event => this.onchange("waste", event.target.value)}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Waste % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={Math.round(this.state.tonnagepercentage[0].value)}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Waste % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.tonnageweight[0].value}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Waste Weight (tons)</label>
                        </div>
                      </li>
                    </ul>

                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="brick"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("brick") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="brick"
                            value={this.state.brick}
                            onChange={event => this.onchange("brick", event.target.value)}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Brick % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={Math.round(this.state.tonnagepercentage[1].value)}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Brick % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.tonnageweight[1].value}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Brick Weight (tons)</label>
                        </div>
                      </li>
                    </ul>

                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="dirt"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("dirt") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="dirt"
                            value={this.state.dirt}
                            onChange={event => this.onchange("dirt", event.target.value)}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Dirt % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={Math.round(this.state.tonnagepercentage[2].value)}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Dirt % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.tonnageweight[2].value}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Dirt Weight (tons)</label>
                        </div>
                      </li>
                    </ul>

                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="concrete"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("concrete") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="concrete"
                            value={this.state.concrete}
                            onChange={event => this.onchange("concrete", event.target.value)}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Concrete % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={Math.round(this.state.tonnagepercentage[3].value)}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Concrete % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.tonnageweight[3].value}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Concrete Weight (tons)</label>
                        </div>
                      </li>
                    </ul>

                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="cleanwood"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("cleanwood") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="cleanwood"
                            value={this.state.cleanwood}
                            onChange={event => this.onchange("cleanwood", event.target.value)}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Wood % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={Math.round(this.state.tonnagepercentage[4].value)}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Wood % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.tonnageweight[4].value}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Wood Weight (tons)</label>
                        </div>
                      </li>
                    </ul>

                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="metal"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("metal") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="metal"
                            value={this.state.metal}
                            onChange={event => this.onchange("metal", event.target.value)}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Metal % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={Math.round(this.state.tonnagepercentage[5].value)}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Metal % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.tonnageweight[5].value}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Metal Weight (tons)</label>
                        </div>
                      </li>
                    </ul>

                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="paper_cardboard"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("paper_cardboard") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="paper_cardboard"
                            value={this.state.paper_cardboard}
                            onChange={event => this.onchange("paper_cardboard", event.target.value)}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Paper/Cardboard % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={Math.round(this.state.tonnagepercentage[6].value)}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Paper/Cardboard % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.tonnageweight[6].value}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Paper/Cardboard Weight (tons)</label>
                        </div>
                      </li>
                    </ul>

                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="plastic"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("plastic") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="plastic"
                            value={this.state.plastic}
                            onChange={event => this.onchange("plastic", event.target.value)}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Plastic % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={Math.round(this.state.tonnagepercentage[7].value)}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Plastic % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.tonnageweight[7].value}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Plastic Weight (tons)</label>
                        </div>
                      </li>
                    </ul>

                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="drywall"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("drywall") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="drywall"
                            value={this.state.drywall}
                            onChange={event => this.onchange("drywall", event.target.value)}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Drywall % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={Math.round(this.state.tonnagepercentage[8].value)}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Drywall % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.tonnageweight[8].value}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Drywall Weight (tons)</label>
                        </div>
                      </li>
                    </ul>

                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="glass"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("glass") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="glass"
                            value={this.state.glass}
                            onChange={event => this.onchange("glass", event.target.value)}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Glass % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={Math.round(this.state.tonnagepercentage[9].value)}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Glass % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.tonnageweight[9].value}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Glass Weight (tons)</label>
                        </div>
                      </li>
                    </ul>

                    <ul className="check__list">
                      <li>
                        <span className="d-flex -align-items-center filter__check">
                          <label className="custom-checkbox custom-checkbox-line">
                            <input
                              onChange={this.onChangeCheckbox}
                              name="asphalt"
                              type="checkbox"
                              className="custom-checkbox--input"
                              checked={selectedFields.indexOf("asphalt") === -1 ? false : true}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </span>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            name="asphalt"
                            value={this.state.asphalt}
                            onChange={event => this.onchange("asphalt", event.target.value)}
                            onFocus={this.focus.bind(this)}
                            onBlur={this.blur.bind(this)}
                            required
                          />
                          <label className="material-textfield-label">Asphalt % (yards)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={Math.round(this.state.tonnagepercentage[10].value)}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Asphalt % (tons)</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            value={this.state.tonnageweight[10].value}
                            disabled={true}
                            required
                          />
                          <label className="material-textfield-label">Asphalt Weight (tons)</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className="col-md-12 m-10">
                      <div className="recycling__title">
                        <span>Recycling %</span> {Math.round(this.state.recyclingpercentage)}%
                      </div>
                      <div className="risidual__waste">
                        <span>Waste %</span> {Math.round(this.state.residualpercentage)}%
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        className="btn btn-dark btn-lg w-100"
                        disabled={this.state.disable}
                        onClick={this.saveInfo.bind(this)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
