import { fetch } from "../../utils";
import moment from "moment";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const sendVerificationCode = data => {
  return fetch(`${HOSTNAME}/session/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const loginUser = credentials => {
  return fetch(`${HOSTNAME}/session/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(credentials)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const me = credentials => {
  const startDate = moment()
    .startOf("day")
    .toISOString();
  return fetch(`${HOSTNAME}/me?startDate=${startDate}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      if (localStorage.getItem("timezone") !== undefined || localStorage.getItem("timezone") !== null || !localStorage.getItem("timezone")) {
        localStorage.setItem('timezone', payload?.user?.company?.timezone)
      }
      if (payload?.['x-version'] !== localStorage.getItem('x-version')) { 
        localStorage.setItem('x-version', payload['x-version']); 
        localStorage.setItem('x-version-update-needed', 'true'); 
      };
      return payload;
    })
    .catch(error => {
      if(error.statusCode === 401){
        if(window.location.pathname !== '/login'){
          window.location.pathname = '/login'
        } 
      }
      throw error;
    });
};

export const fetchCompanyUsers = ({ searchText, limit, skip,allUsers=true, getInactiveUsers = true }) => {
  return fetch(`${HOSTNAME}/users?searchText=${searchText}&limit=${limit || 20}&skip=${skip || 0}&allUsers=${allUsers ? true : allUsers}&getInactiveUsers=${getInactiveUsers ? true : getInactiveUsers}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const createUser = data => {
  return fetch(`${HOSTNAME}/user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const uploadImage = data => {
  return fetch(`${HOSTNAME}/upload`, {
    method: "POST",
    body: data
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const updateProfile = data => {
  return fetch(`${HOSTNAME}/user`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const updateUserById = ({ id, ...data }) => {
  return fetch(`${HOSTNAME}/user/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const deleteUser = id => {
  return fetch(`${HOSTNAME}/user/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getUserDetail = id => {
  return fetch(`${HOSTNAME}/user/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const logout = data => {
  return fetch(`${HOSTNAME}/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchUsersByCompanyId = id => {
  return fetch(`${HOSTNAME}/users/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getUserToken = ({ _id }) => {
  return fetch(`${HOSTNAME}/user-token/${_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
export const updateUserToken = (data) => {
  return fetch(`${HOSTNAME}/update-token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getUserPermissions = (_id ) => {
  return fetch(`${HOSTNAME}/get_user_permissions/${_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
