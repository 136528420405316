import React, { PureComponent } from "react";
import { Table, Spin } from "antd";
import moment from "moment-timezone";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { numberFormat, numberFormatLbs } from "../../../constants/common";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const paymentTypeButton = (paymentType, order) => {
  return (  
    <>
    <div className="inbound-outbound-table-list">{
     order?.paymentTerms === "account" ?
    <button className={showPaymentTypes("css", '')}>{showPaymentTypes("paymentType", '')}</button>
    : paymentType === "" ?
    order && order?.splitPayment &&  order?.splitPayment.length > 0 && order?.splitPayment.map((payment, index) => 
        <button className={(showPaymentTypes("css", payment?.paymentType)) + (index < order?.splitPayment.length - 1 ? " mr-2" : "")}>{showPaymentTypes("paymentType", payment?.paymentType)}</button>
    )
    :    
    <button className={showPaymentTypes("css", paymentType)}>{showPaymentTypes("paymentType", paymentType)}</button>
}</div>
</> );
};

const showPaymentTypes=(type,payment)=>{
  let paymentType = payment !== "" ? `${_.startCase(payment)}` : "";
let btnClass = "btn btn-for-account";
if (paymentType === "") {
  paymentType = "ACCOUNT";
} else {
  paymentType = `${paymentType}`;
}
if (paymentType === "Credit Card") {
  btnClass = "btn btn-for-cc";
} else if (paymentType === "Cash" || paymentType === "Check") {
  btnClass = "btn btn-for-cash";
} else if (paymentType === "Terminal") {
  btnClass = "btn btn-for-terminal";
}
if(type === "css"){
  return btnClass
}else{
  return paymentType
}
}

const columns = [
  {
    title: "Date",
    width: 10,
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt, data) => moment(createdAt).tz(data.timezone).format("M-D-YYYY"),
    fixed: "left",
    align: "center"
  },
  {
    title: "Order #",
    width: 10,
    dataIndex: "ticketNumber",
    key: "ticketNumber",
    align: "center"
  },
  {
    title: "Customer",
    width: 10,
    dataIndex: "companyName",
    key: "companyName",
    align: "center"
  },
  {
    title: "Tons",
    width: 10,
    dataIndex: "tons",
    render: tons => numberFormat(tons),
    key: "tons",
    align: "center"
  },
  {
    title: "Material",
    width: 10,
    dataIndex: "dumpRateType",
    key: "dumpRateType",
    align: "center",
    render: (dumpRateType, orderBreakDownItems) => {
        if (!dumpRateType && orderBreakDownItems?.orderBreakDownItems) {
            let fixedItems = orderBreakDownItems.orderBreakDownItems.fixedItem.map(item => item?.fixedItem);
            let chargeByWeightItems = orderBreakDownItems.orderBreakDownItems.chargeByWeight.map(item => item?.fixedItem);

            let combinedItems = [...fixedItems, ...chargeByWeightItems];

          combinedItems = combinedItems.map(item => {
              let indexYard = item.indexOf("Price Per Yard");
              let indexTon = item.indexOf("Price Per Ton");
              if (indexYard !== -1) {
                  return item.slice(indexYard + "Price Per Yard".length).trim();
              } else if (indexTon !== -1) {
                  return item.slice(indexTon + "Price Per Ton".length).trim();
              } else {
                  return item;
              }
          });
            return combinedItems.join(', ');
        } else {
            const displayValue = dumpRateType ? dumpRateType.split(/(?<=[a-z])(?=[A-Z])/).slice(3).join(' ') : "N/A";
            return displayValue;
        }
         }
        },
  {
    title: "Yards",
    width: 10,
    dataIndex: "yards",
    render: yards => numberFormat(yards),
    key: "yards",
    align: "center"
  },
  {
    title: "Dump Rate",
    width: 10,
    dataIndex: "dumpRate",
    key: "dumpRate",
    align: "center",
    render: (dumpRate, orderBreakDownItems) => {
        if (!dumpRate && orderBreakDownItems?.orderBreakDownItems) {
            let unitPrices1 = orderBreakDownItems.orderBreakDownItems.fixedItem.map(item => `$${numberFormat(item?.unitPrice)}`);
            let unitPrices2 = orderBreakDownItems.orderBreakDownItems.chargeByWeight.map(item => `$${numberFormat(item?.unitPrice)}`);
            let unitPrices = [...unitPrices1, ...unitPrices2];
            return unitPrices.join(', ');
        } else {
            return `$${numberFormat(dumpRate)}`; 
        }
    }
},
{
  title: "Sales Tax",
  width: 10,
  dataIndex: "salesTaxFeeAmount",
  render: (salesTaxFeeAmount,order) => {
    return order?.isSalesTax ? salesTaxFeeAmount : 0
  },
  key: "salesTaxFeeAmount",
  align: "center"
},
  {
    title: "Total Revenue",
    width: 10,
    dataIndex: "totalAmount",
    render: totalAmount => `$${numberFormat(totalAmount)}`,
    key: "totalAmount",
    align: "center"
  },
  {
    title: "Payment Type",
    width: 30,
    dataIndex: "paymentType",
    render: paymentTypeButton,
    key: "paymentType",
    align: "center"
  }
];
const columns1 = [
  {
    title: "Date",
    width: 10,
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt, data) => moment(createdAt).tz(data.timezone).format("M-D-YYYY"),
    fixed: "left",
    align: "center"
  },
  {
    title: "Order #",
    width: 10,
    dataIndex: "ticketNumber",
    key: "ticketNumber",
    align: "center"
  },
  {
    title: "Customer",
    width: 10,
    dataIndex: "companyName",
    key: "companyName",
    align: "center"
  },
  {
    title: "Tons",
    width: 10,
    dataIndex: "tons",
    render: tons => numberFormat(tons),
    key: "tons",
    align: "center"
  },
  {
    title: "Material",
    width: 10,
    dataIndex: "dumpRateType",
    key: "dumpRateType",
    align: "center",
    render: (dumpRateType, orderBreakDownItems) => {
        if (!dumpRateType && orderBreakDownItems?.orderBreakDownItems) {
            let fixedItems = orderBreakDownItems.orderBreakDownItems.fixedItem.map(item => item?.fixedItem);
            let chargeByWeightItems = orderBreakDownItems.orderBreakDownItems.chargeByWeight.map(item => item?.fixedItem);

            let combinedItems = [...fixedItems, ...chargeByWeightItems];

          combinedItems = combinedItems.map(item => {
              let indexYard = item.indexOf("Price Per Yard");
              let indexTon = item.indexOf("Price Per Ton");
              if (indexYard !== -1) {
                  return item.slice(indexYard + "Price Per Yard".length).trim();
              } else if (indexTon !== -1) {
                  return item.slice(indexTon + "Price Per Ton".length).trim();
              } else {
                  return item;
              }
          });
            return combinedItems.join(', ');
        } else {
            const displayValue = dumpRateType ? dumpRateType.split(/(?<=[a-z])(?=[A-Z])/).slice(3).join(' ') : "N/A";
            return displayValue;
        }
         }
        },
  {
    title: "Yards",
    width: 10,
    dataIndex: "yards",
    render: yards => numberFormat(yards),
    key: "yards",
    align: "center"
  },
  {
    title: "Dump Rate",
    width: 10,
    dataIndex: "dumpRate",
    key: "dumpRate",
    align: "center",
    render: (dumpRate, orderBreakDownItems) => {
        if (!dumpRate && orderBreakDownItems?.orderBreakDownItems) {
            let unitPrices1 = orderBreakDownItems.orderBreakDownItems.fixedItem.map(item => `$${numberFormat(item?.unitPrice)}`);
            let unitPrices2 = orderBreakDownItems.orderBreakDownItems.chargeByWeight.map(item => `$${numberFormat(item?.unitPrice)}`);
            let unitPrices = [...unitPrices1, ...unitPrices2];
            return unitPrices.join(', ');
        } else {
            return `$${numberFormat(dumpRate)}`; 
        }
    }
},
{
  title: "Sales Tax",
  width: 10,
  dataIndex: "salesTaxFeeAmount",
  render: (salesTaxFeeAmount,order) => {
    return order?.isSalesTax ? salesTaxFeeAmount : 0
  },
  key: "salesTaxFeeAmount",
  align: "center"
},
{
  title: "Total Revenue",
  width: 10,
  dataIndex: "totalAmount",
  render: totalAmount => `$${numberFormat(totalAmount)}`,
  key: "totalAmount",
  align: "center"
},
  {
    title: "Payment Type",
    width: 30,
    dataIndex: "paymentType",
    render: paymentTypeButton,
    key: "paymentType",
    align: "center"
  }
];
const columns2 = [
  {
    title: "Date",
    width: 160,
    dataIndex: "date",
    key: "date",
    render: (date, data) => moment(date).tz(data.timezone).format("M-D-YYYY"),
    fixed: "left",
    align: "center"
  },
  {
    title: "Manifest #",
    dataIndex: "manifest",
    width: 120,
    key: "manifest",
    align: "center",
    render: (manifest, data) => {
      if (manifest && manifest.length > 0) {
        const manifestNumbers = manifest.map(item => item.manifestNumber).join(", ");
        if (manifestNumbers === "") {
          return "-";
        } else {
          return manifestNumbers;
        }
      } else {
        return "-";
      }
    },
  }, 
  {
    title: "Facility",
    width: 160,
    dataIndex: "facility",
    key: "facility",
    align: "center"
  },
  {
    title: "Hauler",
    width: 100,
    dataIndex: "hauler",
    key: "hauler",
    align: "center"
  },
  {
    title: "Material",
    width: 120,
    dataIndex: "materials",
    key: "materials",
    align: "center",
    render: (materials, data) => {
      const material = (materials && materials.length > 0) ? materials.map((items)=>(items.material)).join(", ") : data.material;
      if (material) {
        return (material)
      }
      return "";
    },
  },
  {
    title: "Tons",
    width: 100,
    dataIndex: "tons",
    render: tons => numberFormat(tons),
    key: "tons",
    align: "center"
  },
  // {
  //   title: "Disposal Rate/Ton",
  //   width: 15,
  //   dataIndex: "disposalRateTon",
  //   render: disposalRateTon=> `$${numberFormat(disposalRateTon)}`,
  //   key: "disposalRateTon",
  //   align: "center"
  // },
  {
    title: "Disposal Rate/Ton",
    width: 180,
    dataIndex: "disposalRate",
    render: disposalRate => `$${numberFormat(disposalRate)}`,
    key: "disposalRate",
    align: "center"
  },
  {
    title: "Yards",
    width: 120,
    dataIndex: "yards",
    render: yards => numberFormatLbs(yards),
    key: "yards",
    align: "center"
  },
  {
    title: "Disposal Rate/Yard",
    width: 160,
    dataIndex: "disposalRateYard",
    render: disposalRateYard=> `$${numberFormat(disposalRateYard)}`,
    key: "disposalRateYard",
    align: "center"
  },
  {
    title: "Disposal Cost",
    width: 160,
    dataIndex: "disposalCost",
    render: disposalCost => `$${numberFormat(disposalCost)}`,
    key: "disposalCost",
    align: "center"
  },
  {
    title: "Trucker",
    width: 120,
    dataIndex: "trucker",
    key: "trucker",
    align: "center"
  },
  {
    title: "Trucking Cost",
    width: 130,
    dataIndex: "transportationCost",
    render: transportationCost => `$${numberFormat(transportationCost)}`,
    key: "transportationCost",
    align: "center"
  },
  {
    title: "Status",
    width: 120,
    dataIndex: "status",
    key: "status",
    align: "center",
    render: status => <span className={status === "Completed" ? "text-yellow" : "text-green"}>{status}</span>
  }
];
export default class JobReportComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);

    this.state = {
      InboundSaleData: [],
      OutboundSaleData: [],
      outBoundActivities: [],
      totalInboundTons: 0,
      totalInboundYardage: 0,
      averageDumpRateInbound: 0,
      totalInboundMaterialRevenue: 0,
      totalOutboundTons: 0,
      totalOutboundYards: 0,
      averageDumpRateOutbound: 0,
      totalOutboundMaterialRevenue: 0,
      totalRevenue: 0,
      totalOutboundActivityTons: 0,
      totalOutboundActivityYards: 0,
      averageDisposalRateTon: 0,
      averageDisposalRateYard: 0,
      totalDisposalCost: 0,
      totalTruckingCost: 0,
      timezone: "",
      InboundMaterial: "All",
      OutboundMaterial: "All",
      OutboundSaleMaterial: "All",
      inboundMaterialOptions: [],
      outboundMaterialOptions: [],
      outboundSaleMaterialOptions: [],
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({loading: true})
    const { startDate, endDate, inboundMaterial, outboundMaterial ,outboundSaleMaterial} = this.props;
    const {
      value: {
        data: { InboundSaleData, OutboundSaleData, outBoundActivities, totalInboundOrdersCal, totaloutboundordercal , totalObCal, timezone }
      }
    } = await this.props.getReport({ url: "/material-report", startDate, endDate,InboundMaterial:inboundMaterial?.length > 0 && inboundMaterial !== "All" ?`${encodeURIComponent(inboundMaterial)}`:undefined , 
    OutboundMaterial:outboundMaterial?.length > 0 && outboundMaterial !== "All" ?`${encodeURIComponent(outboundMaterial)}`:undefined,
    OutboundSaleMaterial:outboundSaleMaterial?.length > 0 && outboundSaleMaterial !== "All" ?`${encodeURIComponent(outboundSaleMaterial)}`:undefined,
  });
    const updatedReport = outBoundActivities.map((item)=> ({...item,status:item.completed ? "Completed" : "Active"}))
    await this.getMaterials(startDate,endDate)
    this.setState({
      InboundSaleData,
      OutboundSaleData,
      totalInboundOrdersCal,
      totaloutboundordercal,
      outBoundActivities:updatedReport,
      totalInboundTons: _.get(totalInboundOrdersCal, "[0].totalTons", 0),
      totalInboundYardage: _.get(totalInboundOrdersCal, "[0].totalYardage", 0),
      averageDumpRateInbound: _.get(totalInboundOrdersCal, "[0].averageDumpRate", 0),
      totalInboundMaterialRevenue: _.get(totalInboundOrdersCal, "[0].totalRevenue", 0),
      totalOutboundTons: _.get(totaloutboundordercal, "[0].totalTons", 0),
      totalOutboundYards: _.get(totaloutboundordercal, "[0].totalYardage", 0),
      averageDumpRateOutbound: _.get(totaloutboundordercal, "[0].averageDumpRate", 0),
      totalOutboundMaterialRevenue: _.get(totaloutboundordercal, "[0].totalRevenue", 0),
      totalOutboundActivityTons: _.get(totalObCal, "[0].totalOutboundTons", 0),
      totalOutboundActivityYards: _.get(totalObCal, "[0].totalOutboundYards", 0),
      averageDisposalRateTon: _.get(totalObCal, "[0].averageDisposalRateTon", 0),
      averageDisposalRateYard: _.get(totalObCal, "[0].averageDisposalRateYard", 0),
      totalDisposalCost: _.get(totalObCal, "[0].totalDisposalCost", 0),
      totalTruckingCost: _.get(totalObCal, "[0].totalTruckingCost", 0),
      timezone,
      loading: false,
      InboundMaterial: inboundMaterial,
      OutboundMaterial: outboundMaterial,
      OutboundSaleMaterial: outboundSaleMaterial
    });
  }



  getMaterials = async (startDate, endDate ) => {
    const {
      value: {
        data: { inbound, outbound, outboundSale }
      }
    } = await this.props.getInboundOutboundMaterials({startDate, endDate });

    this.setState({
      inboundMaterialOptions: inbound,
      outboundMaterialOptions: outbound,
      outboundSaleMaterialOptions:outboundSale
    });
  }


  


  handleInboundChange = async (e) => {
    this.setState({loading: true})
    const {value} = e.target
    const { startDate, endDate } = this.props;
    const {
      value: {
        data: { InboundSaleData, OutboundSaleData, outBoundActivities, totalInboundOrdersCal, totaloutboundordercal ,totalObCal, timezone }
      }
    } = await this.props.getReport({ url: "/material-report", startDate, endDate,searchText:undefined, filterCustomers:undefined, filterLocations:undefined, timezone:undefined,
     InboundMaterial: value?.length > 0 && value !== "All" ? encodeURIComponent(value) : undefined,
      OutboundMaterial: this.state?.OutboundMaterial?.length > 0 && this.state?.OutboundMaterial !== "All" ? encodeURIComponent(this.state.OutboundMaterial) : undefined ,
      OutboundSaleMaterial: this.state?.OutboundSaleMaterial?.length > 0 && this.state?.OutboundSaleMaterial !== "All" ? encodeURIComponent(this.state.OutboundSaleMaterial) : undefined ,
});
    const updatedReport = outBoundActivities.map((item)=> ({...item,status:item.completed ? "Completed" : "Active"}))
    this.setState({
      InboundSaleData,
      OutboundSaleData,
      totalInboundOrdersCal,
      totaloutboundordercal,
      outBoundActivities:updatedReport,
      totalInboundTons: _.get(totalInboundOrdersCal, "[0].totalTons", 0),
      totalInboundYardage: _.get(totalInboundOrdersCal, "[0].totalYardage", 0),
      averageDumpRateInbound: _.get(totalInboundOrdersCal, "[0].averageDumpRate", 0),
      totalInboundMaterialRevenue: _.get(totalInboundOrdersCal, "[0].totalRevenue", 0),
      totalOutboundTons: _.get(totaloutboundordercal, "[0].totalTons", 0),
      totalOutboundYards: _.get(totaloutboundordercal, "[0].totalYardage", 0),
      averageDumpRateOutbound: _.get(totaloutboundordercal, "[0].averageDumpRate", 0),
      totalOutboundMaterialRevenue: _.get(totaloutboundordercal, "[0].totalRevenue", 0),
      totalOutboundActivityTons: _.get(totalObCal, "[0].totalOutboundTons", 0),
      totalOutboundActivityYards: _.get(totalObCal, "[0].totalOutboundYards", 0),
      averageDisposalRateTon: _.get(totalObCal, "[0].averageDisposalRateTon", 0),
      averageDisposalRateYard: _.get(totalObCal, "[0].averageDisposalRateYard", 0),
      totalDisposalCost: _.get(totalObCal, "[0].totalDisposalCost", 0),
      totalTruckingCost: _.get(totalObCal, "[0].totalTruckingCost", 0),
      timezone,
      InboundMaterial: value,
      loading: false
    });
  }
  handleOutboundSale = async (e) => {
    this.setState({loading: true})
    const {value} = e.target
    const { startDate, endDate } = this.props;
    const {
      value: {
        data: { InboundSaleData, OutboundSaleData, outBoundActivities, totalInboundOrdersCal, totaloutboundordercal ,totalObCal, timezone }
      }
    } = await this.props.getReport({ url: "/material-report", startDate, endDate,searchText:undefined, filterCustomers:undefined, filterLocations:undefined, timezone:undefined,OutboundSaleMaterial: value?.length > 0 && value !== "All" ? encodeURIComponent(value) : undefined, 
    OutboundMaterial: this.state?.OutboundMaterial?.length > 0 && this.state?.OutboundMaterial !== "All" ? encodeURIComponent(this.state.OutboundMaterial) : undefined ,
    InboundMaterial: this.state?.InboundMaterial?.length > 0 && this.state?.InboundMaterial !== "All" ? encodeURIComponent(this.state.InboundMaterial) : undefined 
  });
    const updatedReport = outBoundActivities.map((item)=> ({...item,status:item.completed ? "Completed" : "Active"}))
    this.setState({
      InboundSaleData,
      OutboundSaleData,
      totalInboundOrdersCal,
      totaloutboundordercal,
      outBoundActivities:updatedReport,
      totalInboundTons: _.get(totalInboundOrdersCal, "[0].totalTons", 0),
      totalInboundYardage: _.get(totalInboundOrdersCal, "[0].totalYardage", 0),
      averageDumpRateInbound: _.get(totalInboundOrdersCal, "[0].averageDumpRate", 0),
      totalInboundMaterialRevenue: _.get(totalInboundOrdersCal, "[0].totalRevenue", 0),
      totalOutboundTons: _.get(totaloutboundordercal, "[0].totalTons", 0),
      totalOutboundYards: _.get(totaloutboundordercal, "[0].totalYardage", 0),
      averageDumpRateOutbound: _.get(totaloutboundordercal, "[0].averageDumpRate", 0),
      totalOutboundMaterialRevenue: _.get(totaloutboundordercal, "[0].totalRevenue", 0),
      totalOutboundActivityTons: _.get(totalObCal, "[0].totalOutboundTons", 0),
      totalOutboundActivityYards: _.get(totalObCal, "[0].totalOutboundYards", 0),
      averageDisposalRateTon: _.get(totalObCal, "[0].averageDisposalRateTon", 0),
      averageDisposalRateYard: _.get(totalObCal, "[0].averageDisposalRateYard", 0),
      totalDisposalCost: _.get(totalObCal, "[0].totalDisposalCost", 0),
      totalTruckingCost: _.get(totalObCal, "[0].totalTruckingCost", 0),
      timezone,
      OutboundSaleMaterial: value,
      loading: false
    });
  }
   handleOutboundChange = async (e) => {
    this.setState({loading: true})
    const {value} = e.target
    const { startDate, endDate } = this.props;
    const {
      value: {
        data: { InboundSaleData,OutboundSaleData, outBoundActivities , totalInboundOrdersCal, totaloutboundordercal ,totalObCal, timezone }
      }
    } = await this.props.getReport({ url: "/material-report", startDate, endDate,searchText:undefined, filterCustomers:undefined, filterLocations:undefined, timezone:undefined, 
    InboundMaterial: this.state?.InboundMaterial?.length > 0 && this.state?.InboundMaterial !== "All" ? encodeURIComponent(this.state.InboundMaterial) : undefined ,
    OutboundSaleMaterial: this.state?.OutboundSaleMaterial?.length > 0 && this.state?.OutboundSaleMaterial !== "All" ? encodeURIComponent(this.state.OutboundSaleMaterial) : undefined ,
     OutboundMaterial: value?.length > 0 && value !== "All" ? encodeURIComponent(value) : undefined });
    const updatedReport = outBoundActivities.map((item)=> ({...item,status:item.completed ? "Completed" : "Active"}))
    this.setState({
      InboundSaleData,
      OutboundSaleData,
      totalInboundOrdersCal,
      totaloutboundordercal,
      outBoundActivities:updatedReport,
      totalInboundTons: _.get(totalInboundOrdersCal, "[0].totalTons", 0),
      totalInboundYardage: _.get(totalInboundOrdersCal, "[0].totalYardage", 0),
      averageDumpRateInbound: _.get(totalInboundOrdersCal, "[0].averageDumpRate", 0),
      totalInboundMaterialRevenue: _.get(totalInboundOrdersCal, "[0].totalRevenue", 0),
      totalOutboundTons: _.get(totaloutboundordercal, "[0].totalTons", 0),
      totalOutboundYards: _.get(totaloutboundordercal, "[0].totalYardage", 0),
      averageDumpRateOutbound: _.get(totaloutboundordercal, "[0].averageDumpRate", 0),
      totalOutboundMaterialRevenue: _.get(totaloutboundordercal, "[0].totalRevenue", 0),
      totalOutboundActivityTons: _.get(totalObCal, "[0].totalOutboundTons", 0),
      totalOutboundActivityYards: _.get(totalObCal, "[0].totalOutboundYards", 0),
      averageDisposalRateTon: _.get(totalObCal, "[0].averageDisposalRateTon", 0),
      averageDisposalRateYard: _.get(totalObCal, "[0].averageDisposalRateYard", 0),
      totalDisposalCost: _.get(totalObCal, "[0].totalDisposalCost", 0),
      totalTruckingCost: _.get(totalObCal, "[0].totalTruckingCost", 0),
      timezone,
      OutboundMaterial: value,
      loading: false
    });
  }

  render() {
        const {
      InboundSaleData,
      OutboundSaleData,
      outBoundActivities,
      totalInboundTons,
      totalInboundYardage,
      totalInboundMaterialRevenue,
      totalOutboundTons,
      averageDumpRateOutbound,
      totalOutboundMaterialRevenue,
      averageDumpRateInbound,
      totalOutboundYards,
      totalOutboundActivityTons,
      totalOutboundActivityYards,
      averageDisposalRateTon,
      averageDisposalRateYard,
      totalDisposalCost,
      totalTruckingCost
    } = this.state;
    const { pageSize, setPageSize } = this.props;
    const totalOutboundActivityCost = totalTruckingCost + totalDisposalCost ;
    const totalRevenue = totalOutboundMaterialRevenue + totalInboundMaterialRevenue ;
    const { inboundMaterialOptions,outboundMaterialOptions  } = this.state;

    return (
      <>
        <Spin indicator={antIcon} spinning={this.state?.loading}>
        <div className="work__repot-body">
        <div className="page-header">
          <h4 className="table-heading  mb-0">Inbound Material Sales</h4>
          <div className="page-header-inner">
            <div className="form-group material-textfield ml-3">
              <div className="form-group material-textfield">
                <select
                  onChange={(e)=>{this.handleInboundChange(e);this.props.handleValueChange(e)}}
                  className="form-control custom-select w-150 material-textfield-input pr-22"
                  required
                  name="InboundMaterial"
                  value={this.state.InboundMaterial}
                >
                  <option selected value="All">All</option>
                  {inboundMaterialOptions.length > 0 ? inboundMaterialOptions?.map(material => {
                      return (
                    <option value={_.lowerCase(material)} key={material}>
                      {material}
                    </option>
                  );
                    }):null}
                </select>
                <label className="material-textfield-label">
                  Material
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <Table
          className="inbound-outbound-table"
            columns={columns}
            dataSource={InboundSaleData.map(o => ({ ...o, timezone: this.state.timezone }))}
            onChange={event => this.setPageSize(event?.pageSize)}
            pagination={{ pageSize, hideOnSinglePage: true }}
            scroll={{ x: 1000, y: 300 }}
          />
        </div>
        </div>
        <div className="work__repot-body">
          <div className="page-header">
            <h4 className="table-heading mb-0">Outbound Material Sales</h4>
            <div className="page-header-inner">
              <div className="form-group material-textfield ml-3">
                <div className="form-group material-textfield">
                  <select
                    onChange={(e)=>{this.handleOutboundSale(e);this.props.handleValueChange(e)}}
                    className="form-control custom-select w-150 material-textfield-input pr-22"
                    required
                    name="OutboundSaleMaterial"
                    value={this.state.OutboundSaleMaterial}
                  >
                    <option value="All">All</option>
                    {inboundMaterialOptions.length > 0 ? inboundMaterialOptions.map(material => (
                      <option value={_.lowerCase(material)} key={material}>
                        {material}
                      </option>
                    )) : null}
                  </select>
                  <label className="material-textfield-label">
                    Material
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <Table
              columns={columns1}
              dataSource={OutboundSaleData?.map(o => ({ ...o, timezone: this.state.timezone }))}
              onChange={event => setPageSize(event?.pageSize)}
              pagination={{ pageSize, hideOnSinglePage: true }}
              scroll={{ x: 1000, y: 300 }}
            />
          </div>
        </div>
        <div className="work__repot-body">
        <div className="page-header">
              <h4 className="table-heading mb-0">Outbound Activity</h4>
              <div className="page-header-inner">
                <div className="form-group material-textfield ml-3">
                <div className="form-group material-textfield">
                  <select
                    onChange={(e)=>{this.handleOutboundChange(e);this.props.handleValueChange(e)}}
                    className="form-control custom-select w-150 material-textfield-input pr-22"
                    required
                    name="OutboundMaterial"
                    value={this.state.OutboundMaterial}
                  >
                    <option selected value="All">All</option>
                    {outboundMaterialOptions?.map((material, index) => {
                      return (
                        <option value={material?.value} key={index}>
                          {material?.label}
                        </option>
                      );
                    })}
                  </select>
                  <label className="material-textfield-label">
                    Material
                  </label>
                </div>
                </div>
              </div>
            </div>
          <div className="card">
            <Table
              columns={columns2}
              dataSource={outBoundActivities && outBoundActivities.map(o => {
                return(
                  {...o, timezone: this.state.timezone}
                  ) 
                }
              )}
              pagination={{ pageSize, hideOnSinglePage: true }}
              scroll={{ x: 1500, y: 300 }}
            />
          </div>
        </div>
            
        <div className="row">
          <div className="col-lg-12">
            <div className="inbound-outbound-invoice-list">
                
              <div className="inbound-outbound-invoice-list-items">
                <div className="inbound-outbound-invoice-title">Sales</div>
                <div className="invoice-list">
                  <div className="inbound-outbound-invoice-sub-title">Inbound materials Sales</div>
                  <div className="invoice-list-items">Total Inbound Tons <span>{numberFormat(totalInboundTons)}</span></div>
                  <div className="invoice-list-items">Total Inbound Yardage <span>{numberFormatLbs(totalInboundYardage)}</span></div>
                  <div className="invoice-list-items">Average Dump Rate <span>${numberFormat(averageDumpRateInbound)}</span></div>
                  <div className="invoice-list-items">Total Inbound Material Revenue <span>${numberFormat(totalInboundMaterialRevenue)}</span></div>
                </div>
                <div className="invoice-list">
                  <div className="inbound-outbound-invoice-sub-title">Outbound materials Sales</div>
                  <div className="invoice-list-items">Total Outbound Tons <span>{numberFormat(totalOutboundTons)}</span></div>
                  <div className="invoice-list-items">Total Outbound Yardage <span>{numberFormatLbs(totalOutboundYards)}</span></div>
                  <div className="invoice-list-items">Average Dump Rate <span>${numberFormat(averageDumpRateOutbound)}</span></div>
                  <div className="invoice-list-items">Total Outbound Material Revenue <span>${numberFormat(totalOutboundMaterialRevenue)}</span></div>
                </div>
                <div className="inbound-outbound-invoice-total">Total Revenue <span>${numberFormat(totalRevenue)}</span></div>
              </div> 
              <div className="inbound-outbound-invoice-list-items">
                <div className="invoice-list">
                  <div className="inbound-outbound-invoice-title">Outbound Activity</div>
                  <div className="invoice-list-items">Total Outbound Tons <span>{numberFormat(totalOutboundActivityTons)}</span></div>
                  <div className="invoice-list-items">Total Outbound Yards <span>{numberFormatLbs(totalOutboundActivityYards)}</span></div>
                  <div className="invoice-list-items">Average Disposal Cost Ton <span>${numberFormat(averageDisposalRateTon)}</span></div>
                  <div className="invoice-list-items">Average Disposal Cost Yard<span>${numberFormat(averageDisposalRateYard)}</span></div>
                  <div className="invoice-list-items">Total Disposal Cost <span>${numberFormat(totalDisposalCost)}</span></div>
                  <div className="invoice-list-items">Total Trucking Cost <span>${numberFormat(totalTruckingCost)}</span></div>
                </div>
                <div className="inbound-outbound-invoice-total">Total Outbound Activity Costs<span>${numberFormat(totalOutboundActivityCost)}</span></div>
              </div>
              <div className="inbound-outbound-invoice-list-items">
                <div className="invoice-list">
                  <div className="inbound-outbound-invoice-title">Profit Margin</div>
                  <div className="invoice-list-items">Total Revenue <span>${numberFormat(totalOutboundMaterialRevenue + totalInboundMaterialRevenue)}</span></div>
                  <div className="invoice-list-items">Total Costs <span>${numberFormat(totalTruckingCost + totalDisposalCost)}</span></div>
                </div>
                <div className="inbound-outbound-invoice-total">Profit Margin<span>${numberFormat(totalRevenue - totalOutboundActivityCost)}</span></div>
              </div>
                
            </div>
          </div>
        </div>

        {/* <div >
          <h3>Scales</h3>
          <div>
          <h4>Inbound materials Sales</h4>

          <div>
          <h6>
            Total Inbound Tons <span>{numberFormat(totalInboundTons)}</span>
          </h6>
          <h6>
            Total Inbound Yardage <span>{numberFormatLbs(totalInboundYardage)}</span>
          </h6>
          <h6>
            Average Dump Rate <span>${numberFormat(averageDumpRate)}</span>
          </h6>
          <h6>
            Total Inbound Material Revenue <span>${numberFormat(totalRevenue)}</span>
          </h6>
          </div>
          </div>
          <div>
          <h4>Outbound materials Sales</h4>

          <div>
          <h6>
            Total Outbound Tons <span>{numberFormat(totalInboundTons)}</span>
          </h6>
          <h6>
            Total Outbound Yardage <span>{numberFormatLbs(totalInboundYardage)}</span>
          </h6>
          <h6>
            Average Dump Rate <span>${numberFormat(averageDumpRate)}</span>
          </h6>
          <h6>
            Total Outbound Material Revenue <span>${numberFormat(totalRevenue)}</span>
          </h6>
          </div>
          </div>
          <div>
          <h4>
            Total Revenue <span>{numberFormat(totalInboundTons)}</span>
          </h4> 
          </div>
        </div>
        <div >
         <h3>Outbound Activity</h3>
        <div>
        <h5>
            Total Outbound Tons <span>{numberFormat(totalOutboundTons)}</span>
          </h5>
          <h5>
            Total Outbound Yards <span>{numberFormatLbs(totalOutboundYards)}</span>
          </h5>
          <h5>
            Average Disposal Cost Ton <span>${numberFormat(averageDisposalRateTon)}</span>
          </h5>
          <h5>
            Average Disposal Cost Yard<span>${numberFormat(averageDisposalRateYard)}</span>
          </h5>
          <h5>
            Total Disposal Cost <span>${numberFormat(totalDisposalCost)}</span>
          </h5>
          <h5>
            Total Trucking Cost <span>${numberFormat(totalTruckingCost)}</span>
          </h5>
        </div>
          <div>
          <h4>
            Total Outbound Activity Costs <span>{numberFormat(totalInboundTons)}</span>
          </h4> 
          </div>
        </div>
        <div>
        <h3>Profit Margin</h3>
        <h5>
            Total Revenue <span>${numberFormat(totalRevenue)}</span>
          </h5>
        <h5>
            Total Costs <span>${numberFormat(totalRevenue)}</span>
          </h5>
          <div>
          <h4>
          Profit Margin <span>{numberFormat(profitMargin)}</span>
          </h4> 
          </div>
        </div> */}
          
        </Spin>
      </>
    );
  }
}
