import React, { PureComponent } from "react";
import moment from "moment";
import _ from "lodash";

class PrintTicketInvoiceComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      order: {},
      user: null,
      ismodelopen: true,
      spinning: false,
      notes: "",
      showEditor: false,
      saveNotes: "",
      isSustainabilityInformationModelOpen: false,
      sustainabilityInfo: {},
      orderLogs: [],
      isDeleteModal: false,
      progressBar: false,
      emailBtnTxt: "Email Receipt",
      emailProgress: false,
      orderCreationType:"",
      isDumpRateTypeTon:false
    };

  }

componentDidMount= async()=>{ 

  window.scrollTo(0, 0)
   await this.fetchOrderDetail()
  window.print()
  this.props.history.goBack()
}

fetchOrderDetail = async () => {
  const {
    match: {
      params: { id }
    },
    fetchOrder,
    fetchMe
  } = this.props;
  const [{ value: { data, orderLogs } }, { value: { user } }] = await Promise.all([fetchOrder(id), fetchMe()])
  this.checkOrderType(data.dumpRateType)
  this.setState({ order: data, saveNotes: data.notes, notes: data.notes, sustainabilityInfo: data.sustainabilityInfo?.length > 0 ? data.sustainabilityInfo[0] : {}, user, orderLogs, orderCreationType: data.orderCreationType });
  this.setState({ spinning: false })
}

numberWithCommas = (x) => {
  var parts = x.toFixed(2).split(".");
  var num = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
    (parts[1] ? "." + parts[1] : "");
  return num;
}

round2decimal = (number) => {
  return Math.round(parseFloat(number) * 100) / 100;
};

checkOrderType=(type)=>{
  if(type !== ""){
  let dumpRateType= _.startCase(type)
  if(dumpRateType.split(" ").includes("Ton") ) {
    this.setState({isDumpRateTypeTon:true})
  }else{
    this.setState({isDumpRateTypeTon:false})
  }
  }
  this.setState({isDumpRateTypeTon:false})

}
  render() {
   
    const {order} = this.state;
    let total = ( order?.totalAmount + order?.environmentalLoadFeeAmount + order?.fuelSurchardeFeeAmount)
    return (
    <>
      <div className="invoice__each-transaction">
        <div className="invoice__each-inner">
          <section className="invoice__header">
            <h1 className="invoice-title">{order?.company?.businessInfo?.businessName}</h1>
            <p>
                {order?.company?.businessInfo?.address}
                <br />
                {order?.company?.businessInfo?.city +
                  " " +
                  order?.company?.businessInfo?.state +
                  ((order?.company?.businessInfo?.state?.length > 0 || order?.company?.businessInfo?.city?.length > 0 ) && 
                  order?.company?.businessInfo?.zip?.length > 0 ? ", " : "") +
                  order?.company?.businessInfo?.zip}
              </p> 
          </section>
          <section className="details__list-section">
            <ul className="list">
              <li className="items">
                <h3>Vehicle:</h3><p>
                              {(order?.driver && order?.driver?.licenseNumber)  || (order?.truck && order?.truck?.plateNumber)? _.get(order, "driver.licenseNumber", "") +
                              order?.driver? order?.driver?.licenseNumber && order?.truck?.plateNumber ? " | " : "":''+
                              _.get(order, "truck.plateNumber", ""):'N/A'
                            }
                           </p>
              </li>
              <li className="items">
                <h3>Customer:</h3><p>{order?.customer?.displayName}</p>
              </li>
              <li className="items">
                <h3>Driver:</h3><p>{order?.driver?.firstName ? order?.driver?.firstName + " " + order?.driver?.lastName : "N/A"}</p>
              </li>
              <li className="items">
                <h3>Origin City:</h3><p>{order?.cityOfOrigin ? order?.cityOfOrigin:"N/A"}</p>
              </li>
            </ul>
            <ul className="list">
              <li className="items">
                <h3>Ticket Number:</h3><p>{order.ticketNumber}</p>
              </li>
              <li className="items">
                <h3>Date:</h3><p>
                {moment(order?.completedDate).format("l")}
                </p>
              </li>
              <li className="items">
                <h3>Inbound:</h3><p>{order?.inboundWeight} lbs</p>
              </li>
              <li className="items">
                <h3>Outbound:</h3><p>{order?.outboundWeight} lbs</p>
              </li>
            </ul>
            <ul className="list">
              <li className="items">
                <h3>Gross:</h3><p>{order?.inboundWeight} lbs</p>
              </li>
              <li className="items">
                <h3>Tare:</h3><p>{order?.outboundWeight} lbs</p>
              </li>
              <li className="items">
                <h3>Net:</h3><p>{order?.netWeight} lbs</p>
              </li>
              <li className="items">
                <h3>Tons:</h3><p>2.09</p>
              </li>
            </ul>
            
          </section>
          <section className="not__comments-section">
            <h2 className="not__comments-title">Notes/Comments:</h2>
            <p>{order?.notes}</p>
          </section>
          <section className="scale__ticket-section">
            <table className="scale__ticket-invoice">
              <thead>
                <tr>
                  <th>Materials & Services</th>
                  <th>Description</th>
                  <th>Unit</th>
                  <th>MRate </th>
                  <th className="last-child">Amount</th>
                </tr>
              </thead>
              { order?.orderCreationType==="createCharge" ? <>

              { order?.orderBreakDownItems && order.orderBreakDownItems.fixedItem.length  > 0 &&
                  order.orderBreakDownItems.fixedItem.map((item, index) => (
                    <>

                        <>
                          <tbody>
                            {
                                  <>
                                    <tr>
                                      <td>{item.fixedItem}</td>
                                      <td>
                                        <strong>Ticket: </strong>
                                        {order?.ticketNumber}
                                      </td>
                                      <td>{item.units}</td>
                                      <td>${item.unitPrice}</td>
                                      <td>
                                        $
                                        {this.numberWithCommas(
                                          Number(item.units) *
                                            Number(item.unitPrice)
                                        )}
                                      </td>
                                    </tr>
                                  </>
                              }
                            {order?.orderBreakDownItems?.chargeByWeight?.length >
                              0 &&
                              order?.orderBreakDownItems?.chargeByWeight.map(
                                (orderItem, index) => (
                                  <>
                                    <tr>
                                      <td>{orderItem?.fixedItem}</td>
                                      <td>
                                        <strong>Ticket: </strong>
                                        {order?.ticketNumber}
                                      </td>
                                      <td>{orderItem?.units}</td>
                                      <td>${orderItem?.unitPrice}</td>
                                      <td>
                                        $
                                        {Number(orderItem?.units) *
                                          orderItem?.unitPrice}
                                      </td>
                                    </tr>
                                  </>
                                )
                              )}
                          </tbody>
                        </>
                     
                        
                    </>
                  ))} 
                  </> 
                  :   
                  <tbody>
                            {
                                  <>
                                   <tr>
                                      <td>{_.startCase(order?.dumpRateType)}</td>
                                      <td>
                                        <strong>Ticket: </strong>
                                        {order?.ticketNumber}
                                      </td>
                                      <td>{order?.yardage}</td>
                                      { this.state.isDumpRateTypeTon ?
                                      <td>${order?.dumpRate}</td> 
                                    : <td>${order?.tonnage}</td>  }
                                      <td>
                                        $
                                        {Number(order?.yardage) *
                                          order?.dumpRate}
                                      </td>
                                    </tr>
                                  </>
                              }

                              {
                                <>


                               {/* {order.additionalItems.map(
                                (items, index) => (
                                  <>
                                    <tr>
                                    <strong>Ticket: </strong>
                                      <td>{items?.label}</td>
                                      <td>
                                        <strong>Ticket: </strong>
                                        {items?.name}
                                      </td>
                                      <td>{items?.quantity}</td>
                                      <td>${items?.value}</td>
                                      
                                    </tr>
                                  </>
                                )
                              )} */}

                                
                               </>
                              }
                          </tbody>   
                  } 
            </table>          
          </section>
          <section className="total__amount-section">
            <div className="card__details">
              <ul className="list">
                <li className="items"><h3>Account Type</h3>COD</li>
                <li className="items"><h3>Credit Card:</h3>VISA****3039</li>
              </ul>
            </div>
            <div className="total__amount">
              <ul className="list">
                <li className="items"><h3>Subtotal</h3>$
                {this.numberWithCommas(
                      this.round2decimal(
                        Number(order?.totalAmount)
                      )
                    )}
                </li>
                <li className="items"><h3>Environmental Load Fee</h3>$
                {this.numberWithCommas(
                      this.round2decimal(
                        Number(order?.environmentalLoadFeeAmount)
                      )
                    )}
                
                </li>
                <li className="items"><h3>Fuel Surcharge</h3>$
                {this.numberWithCommas(
                      this.round2decimal(
                        Number(order?.fuelSurchargeTotal)
                      )
                    )}
                </li>
                <li className="items"><h3>Total</h3>$
                {this.numberWithCommas(
                      this.round2decimal(
                        Number(total)
                      )
                    )}
                </li>
              </ul>
            </div>
          </section>
          <section className="signature__section">
            <h1>Driver Signature:</h1>
            <img src={order?.driverSignature} alt=''/>
            {/* <div className="signature"></div> */}
          </section>
        </div>
      </div>
    </>
    );
  }
}

export default PrintTicketInvoiceComponent;

