import React, { PureComponent } from "react";
import NoRecords from "../NoRecords/component";
import moment from "moment-timezone";
import _ from "lodash";
import NumberFormat from "react-number-format";
import { numberFormat, numberFormatLbs } from "../../constants/common";
import "./styles.scss";

const timezone = moment().format("Z");
export default class PrintOrderDetailsComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess();
    super(props);
    console.log(timezone, "<<timezone");

    this.state = {
      order: {},
      user: null,
      ismodelopen: true,
      spinning: false,
      notes: "",
      showEditor: false,
      saveNotes: "",
      isSustainabilityInformationModelOpen: false,
      sustainabilityInfo: {},
      orderLogs: [],
      isDeleteModal: false,
      progressBar: false,
      emailBtnTxt: "Email Receipt",
      emailProgress: false,
      isTicketInvoiceLoader: false,
      isTicketInvoiceLoaderPrint: false,
      orderCreationType: "",
      visible: false,
      loaderPerforated: false,
      is_previous_page_scale: false,
      tz,
      softDelete: false,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    await this.fetchOrderDetail();
    window.print();
    this.props.history.goBack();
    // this.setState({ismodelopen:true})
  };

  fetchOrderDetail = async () => {
    this.setState({ spinning: true });
    const {
      match: {
        params: { id },
      },
      fetchOrder,
      fetchMe,
    } = this.props;
    const [
      {
        value: { data, orderLogs },
      },
      {
        value: { user },
      },
    ] = await Promise.all([fetchOrder(id), fetchMe()]);
    this.setState({
      order: data,
      saveNotes: data.notes,
      notes: data.notes,
      sustainabilityInfo:
        data.sustainabilityInfo?.length > 0 ? data.sustainabilityInfo[0] : {},
      user,
      orderLogs,
      orderCreationType: data.orderCreationType,
    });
    this.setState({ spinning: false });
  };

  numberWithCommas = (x) => {
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };

  render() {
    const { order, user, orderLogs } = this.state;
    const sustainabilityInfos =
      order && order?.sustainabilityInfo?.length > 0
        ? order?.sustainabilityInfo
        : [];

    return (
      <div>
        <main className="no-page-break">
          {this.state.orderCreationType !== "createCharge" &&
          this.state.orderCreationType !== "createCharge" ? (
            <div className="react-modal-dialog ticket__modal order__details-modal-- print-order-modal">
              <div className="react-modal-body">
                <div className="custome__container">
                  {order._id ? (
                    <>
                      <div className="card ticket__section">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-8 col-sm-8 col-xs-8 col-xl-8">
                              <div className="card__header-inner">
                                <h5 className="card-title">
                                  Ticket #:
                                  <span>
                                    {_.get(order, "ticketNumber", "")}
                                  </span>
                                  {order.orderType === "manual" && (
                                    <button className="btn btn-for-cc">
                                      Manual Entry
                                    </button>
                                  )}
                                </h5>
                                <input
                                  type="file"
                                  id="file"
                                  ref="fileUploader"
                                  style={{ display: "none" }}
                                  onChange={this.onChangeFile}
                                />
                              </div>
                            </div>
                            <div className="col-md- col-sm-4 col-xs-4 col-xl-4">
                              {order.qbInvoiceId && (
                                <button className="btn btn-for-cc float-right">
                                  #{order.qbInvoiceId}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="card__body-inner">
                                <div className="card__left">
                                  <ul className="card__list">
                                    <li>
                                      <div className="company__driver-title">
                                        <h6 className="title">Company Name</h6>
                                        <p className="sub__title">{`${_.get(
                                          order,
                                          "customer.displayName",
                                          ""
                                        )}`}</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="company__driver-title">
                                        <h6 className="title">Driver</h6>
                                        <p className="sub__title">
                                          {`${_.get(
                                            order,
                                            "driver.firstName",
                                            "N/A"
                                          )} ${_.get(
                                            order,
                                            "driver.lastName",
                                            ""
                                          )}`}
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="company__driver-title">
                                        <h6 className="title">Scale Master</h6>
                                        <p className="sub__title">{`${_.get(
                                          order,
                                          "user.firstName"
                                        )} ${_.get(
                                          order,
                                          "user.lastName"
                                        )}`}</p>
                                      </div>
                                    </li>
                                  </ul>
                                  <ul className="card__list">
                                    <li>
                                      <div className="company__driver-title">
                                        <h6 className="title">Company ID</h6>
                                        <p className="sub__title">{`${_.get(
                                          order,
                                          "customer.customerId",
                                          ""
                                        )}`}</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="company__driver-title">
                                        <h6 className="title">
                                          License Plate #
                                        </h6>
                                        <p className="sub__title">
                                          {_.get(order, "truck.plateNumber")
                                            ? order?.truck?.plateNumber
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="company__driver-title">
                                        <h6 className="title">Payment Terms</h6>
                                        <p className="sub__title">{`${_.toUpper(
                                          _.get(order, "paymentTerms", "cod")
                                        )}`}</p>
                                      </div>
                                    </li>
                                  </ul>
                                  <ul className="card__list">
                                    <li>
                                      <div className="company__driver-title">
                                        <h6 className="title">
                                          Company Address
                                        </h6>
                                        {order.customer &&
                                        order.customer.address ? (
                                          <p className="sub__title">
                                            {order &&
                                              order.customer &&
                                              order.customer.address}
                                            <br />
                                            {order &&
                                              order.customer &&
                                              order.customer.city}
                                            ,{" "}
                                            {order &&
                                              order.customer &&
                                              order.customer.state}{" "}
                                            {order &&
                                              order.customer &&
                                              order.customer.zip}
                                          </p>
                                        ) : (
                                          <p>N/A</p>
                                        )}
                                      </div>
                                    </li>
                                    <li>
                                      <div className="company__driver-title">
                                        <h6 className="title">
                                          Driver Signature
                                        </h6>
                                        {_.get(order, "driverSignature", "") !==
                                        "" ? (
                                          <p
                                            className="signature-thumb-preview"
                                            onClick={() =>
                                              this.setState({
                                                images: [
                                                  _.get(
                                                    order,
                                                    "driverSignature",
                                                    ""
                                                  ),
                                                ],
                                                isLightBoxOpen: true,
                                              })
                                            }
                                          >
                                            <img
                                              src={_.get(
                                                order,
                                                "driverSignature",
                                                ""
                                              )}
                                              alt=""
                                            />
                                          </p>
                                        ) : (
                                          <p className="text-red">
                                            {order.signatureRequired
                                              ? "Awaiting Signature"
                                              : "Not Required"}
                                          </p>
                                        )}
                                      </div>
                                    </li>
                                    <li>
                                      <div className="company__driver-title">
                                        <h6 className="title">
                                          Payment Method
                                        </h6>
                                        <p className="sub__title">
                                          {order.paymentType
                                            ? _.startCase(order.paymentType)
                                            : "N/A"}
                                        </p>
                                        {order.signatureUrl !== "" && (
                                          <img
                                            src={`${order.signatureUrl}`}
                                            alt="signature"
                                          ></img>
                                        )}
                                      </div>
                                    </li>
                                  </ul>
                                  <ul className="card__list">
                                    <li>
                                      <div className="company__driver-title">
                                        <h6 className="title">
                                          City of Origin
                                        </h6>
                                        <p className="sub__title">
                                          {order.cityOfOrigin
                                            ? order.cityOfOrigin
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="company__driver-title">
                                        <h6 className="title">Project</h6>
                                        <p className="sub__title">
                                          {order?.projects?.projectName
                                            ? order?.projects?.projectName
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                {_.get(order, "driver.licenseImageUrl", "") ||
                                _.get(order, "truck.plateImageUrl", "") !==
                                  "" ||
                                order?.paperTicketUrl ||
                                _.get(order, "loadImageUrl", "") !== "" ? (
                                  <div className="card__right">
                                    {_.get(
                                      order,
                                      "driver.licenseImageUrl",
                                      ""
                                    ) && (
                                      <div className="card__thum-item">
                                        <h6 className="thumb-preview-title">
                                          Drivers License
                                        </h6>

                                        <div
                                          className="thumb-preview"
                                          onClick={() =>
                                            this.setState({
                                              images: [
                                                _.get(
                                                  order,
                                                  "driver.licenseImageUrl",
                                                  ""
                                                ),
                                              ],
                                              isLightBoxOpen: true,
                                            })
                                          }
                                        >
                                          <img
                                            style={{
                                              transform: `rotate(${_.get(
                                                order,
                                                "driver.rotation",
                                                0
                                              )}deg)`,
                                            }}
                                            src={_.get(
                                              order,
                                              "driver.licenseImageUrl",
                                              ""
                                            )}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {_.get(order, "truck.plateImageUrl", "") !==
                                    "" ? (
                                      <div className="card__thum-item">
                                        <h6 className="thumb-preview-title">
                                          License Plate
                                        </h6>
                                        <div
                                          className="thumb-preview"
                                          onClick={() =>
                                            this.setState({
                                              images: [
                                                _.get(
                                                  order,
                                                  "truck.plateImageUrl",
                                                  ""
                                                ),
                                              ],
                                              isLightBoxOpen: true,
                                            })
                                          }
                                        >
                                          <img
                                            src={_.get(
                                              order,
                                              "truck.plateImageUrl",
                                              ""
                                            )}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {order?.paperTicketUrl && (
                                      <div className="card__thum-item paper__ticket-card">
                                        <h6 className="thumb-preview-title">
                                          Paper Ticket
                                        </h6>
                                        {order.paperTicketUrl.indexOf(
                                          ".pdf"
                                        ) !== -1 ? (
                                          <object
                                            data={`${_.get(
                                              order,
                                              "paperTicketUrl",
                                              ""
                                            )}`}
                                            type="application/pdf"
                                            width="300"
                                            height="200"
                                          >
                                            <a
                                              href={`${_.get(
                                                order,
                                                "paperTicketUrl",
                                                ""
                                              )}`}
                                            >
                                              test.pdf
                                            </a>
                                          </object>
                                        ) : (
                                          <div
                                            className="thumb-preview"
                                            onClick={() =>
                                              this.setState({
                                                images: [
                                                  _.get(
                                                    order,
                                                    "paperTicketUrl",
                                                    ""
                                                  ),
                                                ],
                                                isLightBoxOpen: true,
                                              })
                                            }
                                          >
                                            <img
                                              src={order.paperTicketUrl}
                                              alt=""
                                            />
                                          </div>
                                        )}
                                        <strong>
                                          {moment(order.paperTicketDate).format(
                                            "MMMM DD, YYYY h:mm a"
                                          )}
                                        </strong>
                                      </div>
                                    )}
                                    {_.get(order, "loadImageUrl", "") !== "" ? (
                                      <div className="card__thum-item">
                                        <h6 className="thumb-preview-title">
                                          Inbound Truck Photo
                                        </h6>
                                        <div
                                          className="thumb-preview"
                                          onClick={() =>
                                            this.setState({
                                              images: [
                                                _.get(
                                                  order,
                                                  "loadImageUrl",
                                                  ""
                                                ),
                                              ],
                                              isLightBoxOpen: true,
                                            })
                                          }
                                        >
                                          <img
                                            src={_.get(
                                              order,
                                              "loadImageUrl",
                                              ""
                                            )}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                              <h5 className="card-title">
                                Company Information
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <ul className="card__list">
                                <li>
                                  <div className="company__driver-title">
                                    <h6 className="title">Name</h6>
                                    <p className="sub__title">{`${_.get(
                                      order,
                                      "company.name",
                                      ""
                                    )}`}</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="company__driver-title">
                                    <h6 className="title">Fax #</h6>
                                    <p className="sub__title">{`${_.get(
                                      order,
                                      "company.businessInfo.fax",
                                      ""
                                    )?.length > 0 ? _.get(
                                      order,
                                      "company.businessInfo.fax",
                                      ""
                                    ) : "N/A"}`}</p>
                                  </div>
                                </li>
                              </ul>
                              <ul className="card__list">
                                <li>
                                  <div className="company__driver-title">
                                    <h6 className="title">Phone #</h6>
                                    <p className="sub__title">{`${_.get(
                                      order,
                                      "company.businessInfo.phone",
                                      ""
                                    )}`}</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="company__driver-title">
                                    <h6 className="title">
                                      DSNY Transfer Station
                                    </h6>
                                    <p className="sub__title">{`#${_.get(
                                      order,
                                      "company.businessInfo.dsnyTransferStation",
                                      ""
                                    )}`}</p>
                                  </div>
                                </li>
                              </ul>
                              <ul className="card__list">
                                {_.get(order, "company.businessInfo") && (
                                  <li>
                                    <div className="company__driver-title">
                                      <h6 className="title">Address </h6>
                                      <p className="sub__title">
                                        {_.get(
                                          order,
                                          "company.businessInfo.address",
                                          ""
                                        )}{" "}
                                        <br />
                                        {_.get(
                                          order,
                                          "company.businessInfo.city",
                                          ""
                                        )}
                                        ,{" "}
                                        {_.get(
                                          order,
                                          "company.businessInfo.state",
                                          ""
                                        )}{" "}
                                        {_.get(
                                          order,
                                          "company.businessInfo.zip",
                                          ""
                                        )}
                                      </p>
                                    </div>
                                  </li>
                                )}
                                <li>
                                  <div className="company__driver-title">
                                    <h6 className="title">NYCDEC PERMIT: </h6>
                                    <p className="sub__title">{`#${_.get(
                                      order,
                                      "company.businessInfo.nycdecPermit",
                                      ""
                                    )}`}</p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card scale__readings">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                              <h5 className="card-title">Scale Readings</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <ul className="card__scale-list">
                                <li>
                                  <span>
                                    Inbound Weight |{" "}
                                    {`${moment(
                                      _.get(
                                        order,
                                        "inboundWeightDate",
                                        moment()
                                      )
                                    )
                                      ?.tz(this.state?.tz)
                                      ?.format("MM/DD/YY")}
                          at
                          ${moment(_.get(order, "inboundWeightDate", moment()))
                            ?.tz(this.state?.tz)
                            ?.format("hh:mm a")}`}
                                  </span>
                                  <span>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"text"}
                                      value={order.inboundWeight}
                                    />{" "}
                                    lbs
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Outbound Weight |{" "}
                                    {`${moment(
                                      _.get(
                                        order,
                                        "outboundWeightDate",
                                        moment()
                                      )
                                    )
                                      ?.tz(this.state?.tz)
                                      ?.format("MM/DD/YY")}
                          at
                          ${moment(_.get(order, "outboundWeightDate", moment()))
                            ?.tz(this.state?.tz)
                            ?.format("hh:mm a")}`}
                                  </span>
                                  <span>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"text"}
                                      value={order.outboundWeight}
                                    />{" "}
                                    Ibs
                                  </span>
                                </li>
                                <li>
                                  <span>Net</span>
                                  <span>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"text"}
                                      value={order.netWeight}
                                    />{" "}
                                    Ibs
                                  </span>
                                </li>
                                <li>
                                  <span>Tonnage </span>
                                  <span>
                                    {numberFormatLbs(order.tonnage)} tons
                                  </span>
                                </li>
                              </ul>
                              <div className="company__driver-title">
                                <h6 className="title">Yardage</h6>
                                <p className="sub__title">
                                  <NumberFormat
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType={"text"}
                                    value={order.yardage}
                                  />
                                </p>
                              </div>
                              {(!_.get(order, "customer.hidePrice", true) ||
                                _.get(user, "role", false) !== "user") && (
                                <React.Fragment>
                                  <div className="company__driver-title">
                                    <h6 className="title">
                                      Dump Rate:{" "}
                                      {`${_.startCase(
                                        _.get(order, "dumpRateType", "")
                                      )}`}
                                    </h6>
                                    <p className="sub__title">
                                      <NumberFormat
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType={"text"}
                                        value={order.dumpRate}
                                      />
                                    </p>
                                  </div>
                                  {_.get(
                                    order,
                                    "isEnvironmentalLoadFee",
                                    false
                                  ) && (
                                    <div className="company__driver-title">
                                      <h6 className="title">
                                        Environmental Load Fee
                                      </h6>
                                      <p className="sub__title">
                                        {numberFormat(
                                          order.environmentalLoadFeeAmount
                                        )}
                                      </p>
                                    </div>
                                  )}
                                  {_.get(
                                    order,
                                    "isFuelSurchardeFee",
                                    false
                                  ) && (
                                    <div className="company__driver-title">
                                      <h6 className="title">Fuel Surcharge</h6>
                                      <p className="sub__title">
                                        {numberFormat(order.fuelSurchargeTotal)}
                                      </p>
                                    </div>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {order.notes && (
                        <div className="card">
                          <div className="card-header">
                            <div className="row align-items-center">
                              <div className="col-md-9 col-sm-9 col-xs-9 col-xl-9">
                                <h5 className="card-title">Notes</h5>
                              </div>
                            </div>
                          </div>
                          {order.notes && (
                            <div className="card-body">
                              <div className="row">
                                {order && order.notes ? (
                                  <div className="col-md-12 notes">
                                    {_.get(order, "notes", "")}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      <div className="card payment__breakdown pt-5 mt-5">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                              <h5 className="card-title">Payment Breakdown</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <ul className="card__scale-list">
                                <li>
                                  <span>Inbound Weight</span>
                                  <span>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"text"}
                                      value={order.inboundWeight}
                                    />{" "}
                                    lbs
                                  </span>
                                </li>
                                <li>
                                  <span>Outbound Weight </span>
                                  <span>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"text"}
                                      value={order.outboundWeight}
                                    />{" "}
                                    lbs
                                  </span>
                                </li>
                                <li>
                                  <span>Net Weight</span>
                                  <span>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"text"}
                                      value={order.netWeight}
                                    />{" "}
                                    lbs
                                  </span>
                                </li>
                                <li>
                                  <span>Tonnage </span>
                                  <span>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"text"}
                                      value={order.tonnage}
                                    />{" "}
                                    tons
                                  </span>
                                </li>
                                {(!_.get(order, "customer.hidePrice", true) ||
                                  _.get(user, "role", false) !== "user") && (
                                  <React.Fragment>
                                    <li>
                                      <span>
                                        Dump Rate (
                                        {`${_.startCase(
                                          _.get(order, "dumpRateType", "")
                                        )}`}
                                        )
                                      </span>
                                      <span>
                                        <NumberFormat
                                          thousandSeparator={true}
                                          prefix={"$"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          displayType={"text"}
                                          value={order.dumpRate}
                                        />
                                      </span>
                                    </li>
                                    {_.get(order, "additionalItems", [])
                                      .length !== 0 && (
                                      <>
                                        <hr />
                                        {_.get(
                                          order,
                                          "additionalItems",
                                          []
                                        ).map(({ label, value, quantity }) => {
                                          return (
                                            <li key={label}>
                                              <span>
                                                {label} ({quantity}) (${value}
                                                /unit)
                                              </span>
                                              <span>
                                                $
                                                {numberFormat(value * quantity)}
                                              </span>
                                            </li>
                                          );
                                        })}
                                      </>
                                    )}
                                    {_.get(
                                      order,
                                      "isEnvironmentalLoadFee",
                                      false
                                    ) && (
                                      <React.Fragment>
                                        <hr />
                                        <li>
                                          <span>Environmental Load Fee</span>
                                          <span>
                                            <NumberFormat
                                              thousandSeparator={true}
                                              prefix={"$"}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              displayType={"text"}
                                              value={
                                                order.environmentalLoadFeeAmount
                                              }
                                            />
                                          </span>
                                        </li>
                                      </React.Fragment>
                                    )}
                                    {_.get(
                                      order,
                                      "isFuelSurchardeFee",
                                      false
                                    ) && (
                                      <React.Fragment>
                                        <hr />
                                        <li>
                                          <span>Fuel Surcharge</span>
                                          <span>
                                            <NumberFormat
                                              thousandSeparator={true}
                                              prefix={"$"}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              displayType={"text"}
                                              value={order.fuelSurchargeTotal}
                                            />
                                          </span>
                                        </li>
                                      </React.Fragment>
                                    )}
                                    {_.get(order, "capoutPrice", 0) !== 0 && (
                                      <>
                                        <hr />
                                        <li>
                                          <span>Capped </span>
                                          <span>
                                            {_.get(
                                              order,
                                              "tonnageDifference",
                                              false
                                            ) < 0
                                              ? "Yes"
                                              : "No"}{" "}
                                          </span>
                                        </li>
                                        <li>
                                          <span>{`${_.startCase(
                                            _.get(order, "dumpRateType", "")
                                          )}`}</span>
                                          <span>
                                            {numberFormat(
                                              _.get(order, "dumpRate", 0)
                                            )}{" "}
                                          </span>
                                        </li>
                                        <li>
                                          <span>Tonnage Cap</span>
                                          <span>
                                            {numberFormatLbs(
                                              _.get(order, "tonnageCap", 0)
                                            )}{" "}
                                            tons{" "}
                                          </span>
                                        </li>
                                        <li>
                                          <span>Capout Price</span>
                                          <span>
                                            $
                                            {numberFormat(
                                              _.get(order, "capoutPrice", 0)
                                            )}{" "}
                                          </span>
                                        </li>
                                        <li>
                                          <span>Tonnage Difference</span>
                                          <span>
                                            {numberFormatLbs(
                                              _.get(
                                                order,
                                                "tonnageDifference",
                                                0
                                              )
                                            )}{" "}
                                            tons{" "}
                                          </span>
                                        </li>
                                      </>
                                    )}
                                    {_.get(
                                      order,
                                      "chargeStripeCardFees",
                                      false
                                    ) &&
                                      (order.paymentType === "credit-card" ||
                                        order?.paymentType === "terminal") && (
                                        <>
                                          <hr />
                                          <li className="">
                                            <span>Handling Fee</span>
                                            {numberFormat(order.stripeCardFees)}
                                          </li>
                                          <li className="">
                                            <span>Order Amount </span>
                                            {order?.totalActualAmount
                                              ? numberFormat(
                                                  order?.totalActualAmount
                                                )
                                              : numberFormat(
                                                  order.totalAmount -
                                                    order.stripeCardFees
                                                )}
                                          </li>
                                        </>
                                      )}
                                    <hr />
                                    <li className=" font-weight-bold">
                                      <span>Total Order Amount </span>
                                      <span className="amount cursor-pointer d-flex align-items-center">
                                        <NumberFormat
                                          thousandSeparator={true}
                                          prefix={"$"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          displayType={"text"}
                                          value={order.totalAmount}
                                        />
                                        {/*  {user.role === "admin" && (
                                      <img
                                        className="img-fluid edit__icon"
                                        src={Edit}
                                        alt=""
                                      />
                                    )} */}
                                      </span>
                                    </li>
                                  </React.Fragment>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                              <h5 className="card-title">
                                Payment Information
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group material-textfield">
                                <div className="company__driver-title">
                                  <h6 className="title">Payment Terms</h6>
                                  <p className="sub__title">{`${_.toUpper(
                                    _.get(order, "paymentTerms", "cod")
                                  )}`}</p>
                                </div>
                              </div>
                              {_.get(order, "paymentTerms", "cod") !==
                                "account" && (
                                <div className="company__driver-title">
                                  <h6 className="title">Payment Method</h6>
                                  <p className="sub__title">{`${_.startCase(
                                    _.get(order, "paymentType", "")
                                  )}`}</p>
                                  {order.signatureUrl !== "" && (
                                    <img
                                      src={`${order.signatureUrl}`}
                                      alt="signature"
                                    ></img>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sustainability__information">
                        {sustainabilityInfos?.length > 0 &&
                          sustainabilityInfos.map((sustainabilityInfo, i) => {
                            return (
                              sustainabilityInfo?.selectedFields?.length >
                                0 && (
                                <div className="card" key={i}>
                                  <div className="card-header">
                                    <div className="row align-items-center">
                                      <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                                        <div className="sustainability__information-inner">
                                          <div>
                                            <h5 className="card-title">
                                              Sustainability Information
                                            </h5>
                                            <p className="card-title-sm">
                                              {sustainabilityInfo.address}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-12">
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "totalpercentage"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Total % (yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo.totalpercentage
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Total % (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo.weight_total
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Weight (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {sustainabilityInfo.weight ||
                                                      _.get(
                                                        order,
                                                        "tonnage",
                                                        0
                                                      )}
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "waste"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Waste % (Yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {sustainabilityInfo.waste}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Waste % (Tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo
                                                        .tonnagepercentage[0]
                                                        .value
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Waste Weight (Tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo
                                                        .tonnageweight[0].value
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "brick"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Brick % (yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {sustainabilityInfo.brick}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Brick % (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo
                                                        .tonnagepercentage[1]
                                                        .value
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Brick Weight (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo
                                                        .tonnageweight[1].value
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "dirt"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Dirt % (yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {sustainabilityInfo.dirt}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Dirt % (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo
                                                        .tonnagepercentage[2]
                                                        .value
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Dirt Weight (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo
                                                        .tonnageweight[2].value
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "concrete"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Concrete % (yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo.concrete
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Concrete % (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo
                                                        .tonnagepercentage[3]
                                                        .value
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Concrete Weight (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo
                                                        .tonnageweight[3].value
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "cleanwood"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Wood % (yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo.cleanwood
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Wood % (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo
                                                        .tonnagepercentage[4]
                                                        .value
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Wood Weight (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo
                                                        .tonnageweight[4].value
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "metal"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Metal % (yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {sustainabilityInfo.metal}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Metal % (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo
                                                        .tonnagepercentage[5]
                                                        .value
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Metal Weight (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo
                                                        .tonnageweight[5].value
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "paper_cardboard"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Paper/Cardboard % (yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo.paper_cardboard
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Paper/Cardboard % (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo
                                                        .tonnagepercentage[6]
                                                        .value
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Paper/Cardboard Weight
                                                    (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo
                                                        .tonnageweight[6].value
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "plastic"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Plastic % (yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {sustainabilityInfo.plastic}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Plastic % (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo
                                                        .tonnagepercentage[7]
                                                        .value
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Plastic Weight (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo
                                                        .tonnageweight[7].value
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "drywall"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Drywall % (yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {sustainabilityInfo.drywall}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Drywall % (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo
                                                        .tonnagepercentage[8]
                                                        .value
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Drywall Weight (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo
                                                        .tonnageweight[8].value
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "glass"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Glass % (yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {sustainabilityInfo.glass}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Glass % (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo
                                                        .tonnagepercentage[9]
                                                        .value
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Glass Weight (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo
                                                        .tonnageweight[9].value
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        {sustainabilityInfo?.selectedFields
                                          ?.length > 0 &&
                                          sustainabilityInfo.selectedFields.indexOf(
                                            "asphalt"
                                          ) !== -1 && (
                                            <ul className="card__list">
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Asphalt % (yards)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {sustainabilityInfo.asphalt}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Asphalt % (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {Math.round(
                                                      sustainabilityInfo
                                                        .tonnagepercentage[10]
                                                        .value
                                                    )}
                                                  </p>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="company__driver-title">
                                                  <h6 className="title">
                                                    Asphalt Weight (tons)
                                                  </h6>
                                                  <p className="sub__title">
                                                    {
                                                      sustainabilityInfo
                                                        .tonnageweight[10].value
                                                    }
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          )}
                                        <div className="recycling__title">
                                          <span>Recycling %</span>{" "}
                                          {Math.round(
                                            sustainabilityInfo.recyclingpercentage
                                          )}
                                          %
                                        </div>
                                        <div className="risidual__waste">
                                          <span>Waste %</span>{" "}
                                          {Math.round(
                                            sustainabilityInfo.residualpercentage
                                          )}
                                          %
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            );
                          })}
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <div className="row align-items-center">
                            <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                              <h5 className="card-title">Order Log</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <ul className="card__scale-list ordercard__list">
                                {orderLogs.map((olog) => {
                                  return (
                                    <li key={olog._id}>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: `${olog.log}`,
                                        }}
                                      ></span>
                                      <span>{`${moment(olog.createdAt)
                                        ?.tz(this.state?.tz)
                                        ?.format("MM/DD/YY")} ${moment(
                                        olog.createdAt
                                      )
                                        ?.tz(this.state?.tz)
                                        ?.format("hh:mm a")}`}</span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <NoRecords title="Order Unavailable" />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="react-modal-dialog ticket__modal order__details-modal--">
              <div className="react-modal-header">
                <div>
                  <h5 className="react-modal-title">
                    Ticket: {order?.ticketNumber}
                  </h5>
                  <h6 className="sub-title">{order?.customer?.displayName} </h6>
                </div>
              </div>

              <div className="react-modal-body">
                <section className="top__list-section">
                  <ul className="list">
                    <li className="items">
                      <h3 className="title">Company Name</h3>
                      <p className="sub-title">
                        {order?.customer?.displayName}
                      </p>
                    </li>
                    <li className="items">
                      <h3 className="title">Pricing Tier</h3>
                      <p className="sub-title">{order?.pricingTier}</p>
                    </li>
                    <li className="items">
                      <h3 className="title">License Plate Number</h3>
                      <p className="sub-title">
                        {order?.truck?.plateNumber
                          ? order?.truck?.plateNumber
                          : "N/A"}
                      </p>
                    </li>
                    <li className="items">
                      <h3 className="title">Scale Master</h3>
                      <p className="sub-title">
                        {order?.user?.firstName + ""} {order?.user?.lastName}{" "}
                      </p>
                    </li>
                    <li className="items">
                      <h3 className="title">Company ID </h3>
                      <p className="sub-title">{order?.customer?.customerId}</p>
                    </li>
                    <li className="items">
                      <h3 className="title">Payment Terms</h3>
                      <p className="sub-title text-uppercase">
                        {order.paymentTerms === "" ? "COD" : order.paymentTerms}{" "}
                      </p>
                    </li>
                    <li className="items">
                      <h3 className="title">Driver</h3>
                      <p className="sub-title">
                        {order?.driver
                          ? order?.driver?.firstName +
                            " " +
                            order?.driver?.lastName
                          : "N/A"}
                      </p>
                    </li>
                    <li className="items">
                      <h3 className="title">City or Origin</h3>
                      <p className="sub-title">
                        {order.cityOfOrigin ? order.cityOfOrigin : "N/A"}
                      </p>
                    </li>
                    <li className="items">
                      <h3 className="title">Company Address</h3>
                      {order.customer && order.customer.address ? (
                        <p className="sub__title">
                          {order && order.customer && order.customer.address}
                          <br />
                          {order && order.customer && order.customer.city},{" "}
                          {order && order.customer && order.customer.state}{" "}
                          {order && order.customer && order.customer.zip}
                        </p>
                      ) : (
                        <p>N/A</p>
                      )}
                    </li>
                    <li className="items">
                      <h3 className="title">Payment Methods</h3>
                      <p className="sub-title">
                        {order.paymentType
                          ? _.startCase(order.paymentType)
                          : "N/A"}
                      </p>
                      {order.signatureUrl !== "" && (
                        <img
                          src={`${order.signatureUrl}`}
                          alt="signature"
                        ></img>
                      )}
                    </li>
                    <li className="items">
                      <h3 className="title">Project</h3>
                      <p className="sub-title">
                        {order?.projects?.projectName
                          ? order?.projects?.projectName
                          : "N/A"}
                      </p>
                    </li>
                    <li className="items">
                      {order?.driver &&
                      order?.driver?.licenseImageUrl !== "" ? (
                        <>
                          <h3 className="title">Drivers License</h3>
                          <img
                            src={order?.driver?.licenseImageUrl}
                            alt=""
                          />{" "}
                        </>
                      ) : null}
                      {order?.driverSignature !== "" && (
                        <>
                          <div className="signechar__box">
                            <h3 className="signechar__title">
                              Drivers Signature
                            </h3>
                            <img src={order?.driverSignature} alt="" />
                          </div>{" "}
                        </>
                      )}
                    </li>
                    {(order?.loadImageUrl !== "" ||
                      order?.truck?.plateImageUrl !== "") && (
                      <>
                        <li className="items">
                          {order?.loadImageUrl !== "" && (
                            <>
                              <h3 className="title">Picture Of Load</h3>
                              <img src={order?.loadImageUrl} alt="" />
                            </>
                          )}
                          {order?.truck?.plateImageUrl !== "" && (
                            <>
                              <h3 className="title">License plate</h3>
                              <img src={order?.truck?.plateImageUrl} alt="" />
                            </>
                          )}
                        </li>
                      </>
                    )}
                  </ul>
                </section>
                <section className="order__breakdown-section">
                  <h1 className="title">Order Breakdown </h1>

                  <div className="table-responsive">
                    <table className="table custom-table">
                      <tbody>
                        <tr>&nbsp;</tr>
                        {order?.orderBreakDownItems &&
                          order.orderBreakDownItems.fixedItem.length > 0 ?<tr>
                          <td className="bold-title">Fixed Item(s) </td>
                          <td className="bold-title">Unit Price</td>
                          <td className="bold-title">Yards</td>
                          <td className="bold-title">&nbsp;</td>
                        </tr>:""}
                        {order?.orderBreakDownItems &&
                          order.orderBreakDownItems.fixedItem.length > 0 &&
                          order.orderBreakDownItems.fixedItem.map(
                            (item, index) => (
                              <tr className="row-bg-none last__border-0">
                                <td className="border-bottom">
                                  {item.fixedItem}
                                </td>
                                <td className="border-bottom">
                                  ${item.unitPrice}
                                </td>
                                <td className="border-bottom">{item.units}</td>
                                <td className="text-right border-bottom">
                                  $
                                  {this.numberWithCommas(
                                    Number(item.unitPrice) * Number(item.units)
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        {order?.orderBreakDownItems &&
                          order.orderBreakDownItems.chargeByWeight.length > 0 ? <tr>
                          <td className="bold-title">Charge by Weight</td>
                          <td className="bold-title">Unit Price</td>
                          <td className="bold-title">Tons</td>
                          <td className="bold-title">&nbsp;</td>
                        </tr>:""}
                        {order?.orderBreakDownItems &&
                          order.orderBreakDownItems.chargeByWeight.length > 0 &&
                          order.orderBreakDownItems.chargeByWeight.map(
                            (item, index) => (
                              <tr>
                                <td className="border-bottom">
                                  {item.fixedItem}
                                </td>
                                <td className="border-bottom">
                                  ${item.unitPrice}
                                </td>
                                <td className="border-bottom">{item.units}</td>
                                <td className="text-right border-bottom">
                                  $
                                  {this.numberWithCommas(
                                    Number(item.unitPrice) * Number(item.units)
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        <tr className="row-bg-none">
                          <td>Capped Pricing</td>
                          <td>
                            $
                            {Number(order?.orderBreakDownItems?.capoutPrice) > 0
                              ? this.numberWithCommas(
                                  Number(
                                    order.orderBreakDownItems &&
                                      order?.orderBreakDownItems?.capoutPrice
                                  )
                                )
                              : 0}
                          </td>
                          <td>&nbsp;</td>
                          <td className="text-right">
                            {order.orderBreakDownItems &&
                            order?.orderBreakDownItems?.isCapOutPrice
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr className="row-bg-none">
                          <td>Miniumum Threshold</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td className="text-right">
                            {order.orderBreakDownItems &&
                            order?.orderBreakDownItems?.isminimumPrice
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section className="total__order-amount">
                  <div className="total__order-amount-inner">
                    <div className="left-section">
                      <h1 className="title">Total Order Amount</h1>
                    </div>
                    <div className="right-section">
                      <ul className="list">
                        {order?.orderBreakDownItems?.isCapOutPrice && (
                          <li className="items">
                            <span>Cap Out Total</span>
                            <span
                              className={
                                order?.orderBreakDownItems?.isminimumPrice
                                  ? "bold-title line-through"
                                  : "bold-title"
                              }
                            >
                              $
                              {this.numberWithCommas(
                                Number(
                                  order.orderBreakDownItems &&
                                    order?.orderBreakDownItems?.totalCapoutPrice
                                )
                              )}
                            </span>
                          </li>
                        )}
                        {order?.isEnvironmentalLoadFee && (
                          <li className="items">
                            <span>Environmental Load Fee</span>
                            <span className={"bold-title"}>
                              $
                              {this.numberWithCommas(
                                Number(order?.environmentalLoadFeeAmount)
                              )}
                            </span>
                          </li>
                        )}
                        {order?.isFuelSurchardeFee && (
                          <li className="items">
                            <span>Fuel Surcharge Fee</span>
                            <span className={"bold-title"}>
                              $
                              {this.numberWithCommas(
                                Number(order?.fuelSurchardeFeeAmount)
                              )}
                            </span>
                          </li>
                        )}
                        {this.state.user?.company?.chargeStripeCardFees &&
                          (order?.paymentType === "credit-card" ||
                            order?.paymentType === "terminal") &&
                          Number(order?.stripeCardFees) > 0 && (
                            <li className="items">
                              <span>Handling Fee</span>
                              <span className={"bold-title"}>
                                $
                                {this.numberWithCommas(
                                  Number(order?.stripeCardFees)
                                )}
                              </span>
                            </li>
                          )}
                          {order?.isSalesTax && (
                          <li className="items">
                            <span>Sales Tax</span>
                            <span className={"bold-title"}>
                              $
                              {this.numberWithCommas(
                                Number(order?.salesTaxFeeAmount)
                              )}
                            </span>
                          </li>
                        )}
                        <li className="items">
                          <span>Original Amount</span>
                          <span
                            className={
                              order?.orderBreakDownItems?.isCapOutPrice
                                ? "bold-title line-through"
                                : order?.orderBreakDownItems?.isminimumPrice
                                ? "bold-title line-through"
                                : "bold-title"
                            }
                          >
                            $
                            {Number(
                              order?.orderBreakDownItems?.totalOrderAmout
                            ) > 0
                              ? this.numberWithCommas(
                                  Number(
                                    order.orderBreakDownItems &&
                                      order?.orderBreakDownItems
                                        ?.totalOrderAmout
                                  )
                                )
                              : "0"}
                          </span>
                        </li>
                        <li className="items">
                          <span>Amount Due </span>
                          <span className="bold-title">
                            $
                            {this.numberWithCommas(
                              Number(
                                order.orderBreakDownItems &&
                                  order?.orderBreakDownItems?.totalAmountDue
                              )
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
                {this.state.saveNotes && (
                  <section className="not__section">
                    <div className="not__header">
                      <h1 className="title">Notes</h1>
                    </div>
                    <div className="not__body">
                      {this.state.saveNotes && (
                        <>
                          {" "}
                          <p>{this.state.saveNotes}</p>
                        </>
                      )}
                    </div>
                  </section>
                )}
                {this.state.sustainabilityInfo?.selectedFields?.length > 0 ||
                this.state.sustainabilityInfo?.address ? (
                  <section className="diversion__section">
                    <div className="diversion__section-header">
                      <div className="diversion__section-header-inner">
                        <div>
                          <h1 className="title">LEED/Diversion %</h1>
                          <p>{this.state.sustainabilityInfo?.address}</p>
                        </div>
                      </div>
                    </div>
                    <div className="diversion__section-body">
                      {this.state.sustainabilityInfo?.selectedFields?.length >
                        0 &&
                        this.state.sustainabilityInfo?.tonnagepercentage.map(
                          (item, index) => (
                            <ul className="list">
                              {item.value > 0 && (
                                <>
                                  <li className="items">
                                    <h3 className="title">
                                      {item.key} % (yards)
                                    </h3>
                                    <h4 className="sub-title">
                                      {
                                        this.state.sustainabilityInfo[
                                          `${item.key}`
                                        ]
                                      }
                                    </h4>
                                  </li>
                                  <li className="items">
                                    <h3 className="title">
                                      {item.key} % (tons)
                                    </h3>
                                    <h4 className="sub-title">
                                      {Math.trunc(item.value)}
                                    </h4>
                                  </li>
                                  <li className="items">
                                    <h3 className="title">
                                      {item.key} Weight (tons)
                                    </h3>
                                    <h4 className="sub-title">
                                      {
                                        this.state.sustainabilityInfo
                                          ?.tonnageweight[index].value
                                      }
                                    </h4>
                                  </li>
                                </>
                              )}
                            </ul>
                          )
                        )}
                    </div>
                  </section>
                ) : null}
                {this.state.sustainabilityInfo?.selectedFields?.length > 0 && (
                  <section className="recycling__section">
                    <div className="recycling__section-inner">
                      <ul className="list">
                        <li className="items">
                          <span className="title">Recycling %</span>
                          <span className="text">
                            {Math.round(
                              this.state.sustainabilityInfo?.recyclingpercentage
                            )
                              ? Math.round(
                                  this.state.sustainabilityInfo
                                    ?.recyclingpercentage
                                )
                              : 0}
                            %
                          </span>
                        </li>
                        <li className="items">
                          <span className="title">Waste %</span>
                          <span className="text">
                            {Math.round(
                              this.state.sustainabilityInfo?.residualpercentage
                            )
                              ? Math.round(
                                  this.state.sustainabilityInfo
                                    ?.residualpercentage
                                )
                              : 0}
                            %
                          </span>
                        </li>
                      </ul>
                    </div>
                  </section>
                )}
                {this.state.orderLogs.length > 0 &&
                  this.state.orderLogs.map((item, index) => {
                    return (
                      <section key={index} className="order__log-section">
                        <div className="order__log-inner">
                          <div className="left__section">
                            <h3 className="title">Order Log</h3>
                            <p>
                              This order was created by{" "}
                              {order?.user?.firstName + ""}{" "}
                              {order?.user?.lastName}{" "}
                            </p>
                          </div>
                          <div className="title-date">
                            {`${moment(item.createdAt)
                              ?.tz(this.state?.tz)
                              ?.format("MM/DD/YY")} at  ${moment(item.createdAt)
                              ?.tz(this.state?.tz)
                              ?.format("hh:mm a")}`}
                          </div>
                        </div>
                      </section>
                    );
                  })}
              </div>
            </div>
          )}
        </main>
      </div>
    );
  }
}
