import React, { PureComponent } from "react";
import _,{capitalize} from "lodash";
import { CloseIcon, DeleteIcon } from "../../../components/icons";
import NumberFormat from "react-number-format";
import {Drawer, message, Switch, Tooltip } from 'antd';
import "../styles.scss";
import moment from "moment-timezone";
import ReactModal from "react-modal";
import LightBox from "../../../utils/lightbox";
import { numberFormat, numberFormatLbs } from "../../../constants/common";
import ReactWebcam from "../../CreateOrder/partials/react-webcam";
import DeleteOrderModal from "../../PendingOrderDetails/partials/delete-order";
import EditTotalAmount from "./edit-total-amount";
import DatePicker from "react-datepicker";
import ReactImageCrop from "../../CreateOrder/partials/react-crop";
import Edit from "../../../images/edit.svg";
import cropIcon from "../../../images/crop_icon.svg";

const timezone = moment().format("Z");
class CompletedOrder extends PureComponent {
  constructor(props) {
    const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
    super(props);
    this.state = {
      images: [],
      isLightBoxOpen: false,
      showEditor: false,
      notes: "",
      emailProgress: false,
      emailBtnTxt: "Email Receipt",
      manualCameraOn: false,
      paperTicketUrl: "",
      uploadProgress: false,
      qbSync : true,
      isDeleteModal: false,
      progressBar: false,
      fileType: "",
      paperTicketDate: moment().toDate(),
      editTAModal: false,
      isCrop: false,
      tz,
      softDelete:false,
      softDelProcess:false,
      hideWieghtToggle:false,
    };
  }

  navigate = (orderId, index) => {
    const {
      order: { tonnage }
    } = this.props;
    this.props.history.push({ pathname: "/sustainability-information", state: { orderId, index, tonnage } });
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  deleteOrder = async (reason) => {
    const {
      order: { _id }
    } = this.props;
    this.setState({ progressBar: !this.state.softDelete,softDelProcess:this.state.softDelete });
    const {
      value: { status }
    } = await this.props.deleteOrder(_id,reason);
    if (status) {
      this.props.history.push("/completed-orders");
    }
  };

  openPrintScreen = () => {
    const {
      order: { ticketNumber }
    } = this.props;
    this.props.history.push(`/receipt/${ticketNumber}`);
  };

  sendEmailReceipt = async () => {
    const { emailBtnTxt, emailProgress } = this.state;
    const {
      order: { ticketNumber },
      sendEmailReceipt
    } = this.props;
    if (emailBtnTxt !== "Email Receipt" || emailProgress) return;
    this.setState({ emailProgress: true });
    const { value } = await sendEmailReceipt({ ticketNumber, timezone });
    message.success(value.message);
    this.setState({ emailProgress: false, emailBtnTxt: "Email Receipt" });
  };

  saveNotes = async orderId => {
    const { notes } = this.state;
    const payload = { id: orderId, notes};
    const { updateOrder } = this.props;
    const {
      value: { status }
    } = await updateOrder(payload);
    if (status) {
      this.setState({ showEditor: false });
      this.props.onSaveNotesSuccess();
    }
  };

  dataURItoBlob = dataURI => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  submitImages(data) {
    const { fileType } = this.state;
    const blob = this.dataURItoBlob(data);
    const formdata = new FormData();
    formdata.append("photos", blob);
    formdata.append("fileName", `${new Date().getTime()}.${fileType === "pdf" ? "pdf" : "png"}`);
    formdata.append("folderName", "load");
    formdata.append("fileType", fileType);
    return formdata;
  }

  savePaperTicket = async () => {
    const { paperTicketUrl, paperTicketDate } = this.state;
    if (!paperTicketUrl) return;
    const {
      order: { _id },
      uploadImage
    } = this.props;
    this.setState({ uploadProgress: true });
    const {
      value: { url }
    } = await uploadImage(this.submitImages(paperTicketUrl));
    const payload = {
      id: _id,
      paperTicketUrl: url,
      paperTicketDate
    };
    const { updateOrder } = this.props;
    const {
      value: { status }
    } = await updateOrder(payload);
    if (status) {
      this.setState({
        visible: false,
        uploadProgress: false,
        fileType: "",
        paperTicketDate: moment().toDate(),
        paperTicketUrl: ""
      });
      this.props.onSaveNotesSuccess();
    }
  };

  onTakePhoto = async (dataUri, field, field2) => {
    this.setState({ [field]: dataUri, [field2]: false });
  };

  showDrawer = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  convertBlobToBase64(blob) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var base64data = reader.result;
        return resolve(base64data);
      };
    });
  }

  async onCropImage(blob) {
    const paperTicketUrl = await this.convertBlobToBase64(blob);
    this.setState({ paperTicketUrl, isCrop: false });
  }

  onChangeFile = async e => {
    const file = e.target.files[0];
    let fileType = "";
    if (!file) return;
    if (file.type === "application/pdf") {
      fileType = "pdf";
    }
    const paperTicketUrl = await this.convertBlobToBase64(file);
    this.setState({ paperTicketUrl, fileType });
  };

   handleWeightToggle (e)  {
    if (e) {
      this.setState({ hideWieghtToggle: true },()=>this.handleWeightUpdate());
    }else{
      this.setState({ hideWieghtToggle: false },()=>this.handleWeightUpdate());
    }
}

  async handleWeightUpdate() {
    const { hideWieghtToggle } = this.state;
    const {
      order: { _id },
      updateOrderData,
    } = this.props;
    const payload = {
      id: _id,
      hideWeight: hideWieghtToggle ? true : false,
    }

    const {
      value: { status }
    } = await updateOrderData(payload);
    if (status) {
      this.props.onSaveNotesSuccess();
    }
  }
  checkPaymentType(order){
    let returnType=false
      order &&_.get(order,"splitPayment",false) && order.splitPayment.length > 0 && order.splitPayment.map((paymet)=>{
      if(paymet.paymentType ==="credit-card" || paymet.paymentType === 'terminal'){
        returnType= true
      }
      return null
    })
   return returnType
  }
  render() {
    const {
      showEditor,
      // emailProgress,
      // emailBtnTxt,
      manualCameraOn,
      paperTicketUrl,
      uploadProgress,
      progressBar,
      fileType,
      paperTicketDate,
      qbSync,
      isCrop,
      softDelProcess
    } = this.state;
    const { order, user, orderLogs, updateTotalAmount } = this.props;
    if((order?.invoiceDetails?.qbInvoiceId )=== "" || (order?.invoiceDetails?.qbInvoiceId) === null || Number(order?.invoiceDetails?.qbInvoice) === undefined){
      this.setState({qbSync : false})
    }
    if (order?.hideWeight !== undefined) {
      this.setState({hideWieghtToggle: order?.hideWeight})
    }
    const sustainabilityInfos = order && order.sustainabilityInfo.length > 0 ? order.sustainabilityInfo : [];
    return (
      <>
        <div className="card ticket__section">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-md-12 col-md-12 col-sm-8 col-xs-8 col-xl-12">
                <div className="card__header-inner">
                  <div className="sub-title">
                    <h5 className="card-title">
                      Ticket #:<span>{_.get(order, "ticketNumber", "")}</span>
                      {order.orderType === "manual" && <button className="btn btn-for-cc">Manual Entry</button>}&nbsp;
                      {order.qbInvoiceId && <button className="btn btn-for-cc float-right">#{order.qbInvoiceId}</button>}
                    </h5>
                  
                    {order?.isDeleted && ( 
                      <p className="ticket-void-order-text">Void: {capitalize(order?.deleteReason)}</p> 
                    )}
                  </div>
                  <input
                    type="file"
                    id="file"
                    ref="fileUploader"
                    style={{ display: "none" }}
                    onChange={this.onChangeFile}
                  />
                </div>
              </div>
              {/* <div className="col-md-4 col-sm-4 col-xs-4 col-xl-4">
               
              </div> */}
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card__body-inner">
                  <div className="card__left">
                    <ul className="card__list">
                      <li>
                        <div className="company__driver-title">
                          <h6 className="title">Company Name</h6>
                          <p className="sub__title">{`${_.get(order, "customer.displayName", "")}`}</p>
                        </div>
                      </li>
                      <li>
                        <div className="company__driver-title">
                          <h6 className="title">Driver</h6>
                          <p className="sub__title">
                            {`${_.get(order, "driver.firstName", "N/A")} ${_.get(order, "driver.lastName", "")}`}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="company__driver-title">
                          <h6 className="title">Scale Master</h6>
                          <p className="sub__title">{`${_.get(order, "user.firstName")} ${_.get(
                            order,
                            "user.lastName"
                          )}`}</p>
                        </div>
                      </li>
                    </ul>
                    <ul className="card__list">
                      <li>
                        <div className="company__driver-title">
                          <h6 className="title">Company ID</h6>
                          <p className="sub__title">{`${_.get(order, "customer.customerId", "")}`}</p>
                        </div>
                      </li>
                      <li>
                        <div className="company__driver-title">
                          <h6 className="title">License Plate #</h6>
                          <p className="sub__title">
                            {_.get(order, "truck.plateNumber") ? order?.truck?.plateNumber : "N/A"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="company__driver-title">
                          <h6 className="title">Payment Terms</h6>
                          <p className="sub__title">{`${_.toUpper(_.get(order, "paymentTerms", "cod"))}`}</p>
                        </div>
                      </li>
                    </ul>
                    <ul className="card__list">
                      <li>
                        <div className="company__driver-title">
                          <h6 className="title">Company Address</h6>
                          {order.customer && order.customer.address ? (
                            <p className="sub__title">
                              {order && order.customer && order.customer.address}
                              <br />
                              {order && order.customer && order.customer.city},{" "}
                              {order && order.customer && order.customer.state}{" "}
                              {order && order.customer && order.customer.zip}
                            </p>
                          ) : (
                            <p>N/A</p>
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="company__driver-title">
                          <h6 className="title">Driver Signature</h6>
                          {_.get(order, "driverSignature", "") !== "" ? (
                            <div
                              className="signature-thumb-preview-"
                              onClick={() =>
                                this.setState({
                                  images: [_.get(order, "driverSignature", "")],
                                  isLightBoxOpen: true
                                })
                              }
                            >
                              <img style={{width: '100%',maxWidth: '200px'}} src={_.get(order, "driverSignature", "")} alt="" />
                            </div>
                          ) : (
                            <p className="text-red">
                              {order.signatureRequired ? "Awaiting Signature" : "Not Required"}
                            </p>
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="company__driver-title">
                          <h6 className="title">Payment Method</h6>
                          <p className="sub__title">{_.get(order, "paymentType", "") ==="" ? _.get(order, "splitPayment", []).length > 0 && order.splitPayment.map((payment,index)=>_.startCase(payment?.paymentType) + ( index < order.splitPayment.length - 1 ?", " :" "))  :`${_.startCase(_.get(order, "paymentType", ""))}`}</p>
                          {order.signatureUrl !== "" &&
                            <img src={`${order.signatureUrl}`} alt="signature"></img>
                          }
                        </div>
                      </li>
                    </ul>
                    <ul className="card__list">
                      <li>
                        <div className="company__driver-title">
                          <h6 className="title">City of Origin</h6>
                          <p className="sub__title">{order.cityOfOrigin ? order.cityOfOrigin : "N/A"}</p>
                        </div>
                      </li>
                      <li>
                        <div className="company__driver-title">
                          <h6 className="title">Project</h6>
                          <p className="sub__title">{order?.projects?.projectName ? order?.projects?.projectName : "N/A"}</p>
                        </div>
                      </li>
                      {order?.company?.serviceAddress && order?.serviceLocation && order?.serviceLocation !== "" && (<li>
                        <div className="company__driver-title">
                          <h6 className="title">Service Location</h6>
                          <p className="sub__title">{order?.serviceLocation ? order?.serviceLocation : ""}</p>
                        </div>
                      </li>)}
                    </ul>
                    <ul className="card__list">
                    {order?.customerRefrence && order?.customerRefrence !== "" && (
                      <li>
                        <div className="company__driver-title">
                        <h6 className="title">Customer Reference #</h6>
                          <p className="sub__title">{order.customerRefrence ? order.customerRefrence : ""}</p>
                        </div>
                      </li>)}
                      </ul>
                    
                  </div>
                  <div className="card__right">
                  {_.get(order, "driver.licenseImageUrl", "") &&
                    <div className="card__thum-item">
                      <h6 className="thumb-preview-title">Drivers License</h6>
                     
                        <div
                          className="thumb-preview"
                          onClick={() =>
                            this.setState({
                              images: [_.get(order, "driver.licenseImageUrl", "")],
                              isLightBoxOpen: true
                            })
                          }
                        >
                          <img
                            style={{ transform: `rotate(${_.get(order, "driver.rotation", 0)}deg)` }}
                            src={_.get(order, "driver.licenseImageUrl", "")}
                            alt=""
                          />
                        </div>
                    </div>
                      }
                    {_.get(order, "truck.plateImageUrl", "") !== "" ? (
                      <div className="card__thum-item">
                        <h6 className="thumb-preview-title">License Plate</h6>
                        <div
                          className="thumb-preview"
                          onClick={() =>
                            this.setState({
                              images: [_.get(order, "truck.plateImageUrl", "")],
                              isLightBoxOpen: true
                            })
                          }
                        >
                          <img src={_.get(order, "truck.plateImageUrl", "")} alt="" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {order?.paperTicketUrl && (
                      <div className="card__thum-item paper__ticket-card">
                        <h6 className="thumb-preview-title">Paper Ticket</h6>
                        {order.paperTicketUrl.indexOf(".pdf") !== -1 ? (
                          <object
                            data={`${_.get(order, "paperTicketUrl", "")}`}
                            type="application/pdf"
                            width="300"
                            height="200"
                          >
                            <a href={`${_.get(order, "paperTicketUrl", "")}`}>test.pdf</a>
                          </object>
                        ) : (
                          <div
                            className="thumb-preview"
                            onClick={() =>
                              this.setState({
                                images: [_.get(order, "paperTicketUrl", "")],
                                isLightBoxOpen: true
                              })
                            }
                          >
                            <img src={order.paperTicketUrl} alt="" />
                          </div>
                        )}
                        <strong>{moment(order.paperTicketDate).format("MMMM DD, YYYY h:mm a")}</strong>
                      </div>
                    )}
                    {_.get(order, "loadImageUrl", "") !== "" ? (
                      <div className="card__thum-item">
                        <h6 className="thumb-preview-title">Inbound Truck Photo</h6>
                        <div
                          className="thumb-preview"
                          onClick={() =>
                            this.setState({
                              images: [_.get(order, "loadImageUrl", "")],
                              isLightBoxOpen: true
                            })
                          }
                        >
                          <img src={_.get(order, "loadImageUrl", "")} alt="" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                <h5 className="card-title">Company Information</h5>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <ul className="card__list">
                  <li>
                    <div className="company__driver-title">
                      <h6 className="title">Name</h6>
                      <p className="sub__title">{`${_.get(order, "company.name", "")}`}</p>
                    </div>
                  </li>
                  <li>
                    <div className="company__driver-title">
                      <h6 className="title">Fax #</h6>
                      <p className="sub__title">{`${_.get(order, "company.businessInfo.fax", "")?.length > 0 ? _.get(order, "company.businessInfo.fax", "") : "N/A" }`}</p>
                    </div>
                  </li>
                </ul>
                <ul className="card__list">
                  <li>
                    <div className="company__driver-title">
                      <h6 className="title">Phone #</h6>
                      <p className="sub__title">{`${_.get(order, "company.businessInfo.phone", "")}`}</p>
                    </div>
                  </li>
                  <li>
                    <div className="company__driver-title">
                      <h6 className="title">DSNY Transfer Station</h6>
                      <p className="sub__title">{`#${_.get(order, "company.businessInfo.dsnyTransferStation", "")}`}</p>
                    </div>
                  </li>
                </ul>
                <ul className="card__list">
                  {_.get(order, "company.businessInfo") && (
                    <li>
                      <div className="company__driver-title">
                        <h6 className="title">Address </h6>
                        <p className="sub__title">
                          {_.get(order, "company.businessInfo.address", "")} <br />
                          {_.get(order, "company.businessInfo.city", "")},{" "}
                          {_.get(order, "company.businessInfo.state", "")}{" "}
                          {_.get(order, "company.businessInfo.zip", "")}
                        </p>
                      </div>
                    </li>
                  )}
                  <li>
                    <div className="company__driver-title">
                      <h6 className="title">NYCDEC PERMIT: </h6>
                      <p className="sub__title">{`#${_.get(order, "company.businessInfo.nycdecPermit", "")}`}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="card scale__readings">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                <h5 className="card-title">Scale Readings</h5>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <ul className="card__scale-list">
                  <li>
                    <span>
                      Inbound Weight |{" "}
                      {`${moment(_.get(order, "inboundWeightDate", moment()))?.tz(this.state?.tz)?.format("MM/DD/YY")}
                          at
                          ${moment(_.get(order, "inboundWeightDate", moment()))?.tz(this.state?.tz)?.format("hh:mm a")}`}
                    </span>
                    <span>
                      <NumberFormat thousandSeparator={true} displayType={"text"} value={order.inboundWeight} /> lbs
                    </span>
                  </li>
                  <li>
                    <span>
                      Outbound Weight |{" "}
                      {`${moment(_.get(order, "outboundWeightDate", moment()))?.tz(this.state?.tz)?.format("MM/DD/YY")}
                          at
                          ${moment(_.get(order, "outboundWeightDate", moment()))?.tz(this.state?.tz)?.format("hh:mm a")}`}
                    </span>
                    <span>
                      <NumberFormat thousandSeparator={true} displayType={"text"} value={order.outboundWeight} /> Ibs
                    </span>
                  </li>
                  <li>
                    <span>Net</span>
                    <span>
                      <NumberFormat thousandSeparator={true} displayType={"text"} value={order.netWeight} /> Ibs
                    </span>
                  </li>
                  <li>
                    <span>Tonnage </span>
                    <span>
                      {numberFormatLbs(order.tonnage)} tons
                    </span>
                  </li>
                  {user?.company?.sisterCompany ? null :<li>
                    <span>Hide weight on order receipts </span>
                    <span>
                    <Switch
                    checked={this.state.hideWieghtToggle}
                    onChange={(e) => this.handleWeightToggle(e)}
                    />
                    </span>
                  </li>}
                </ul>
                <div className="company__driver-title">
                  <h6 className="title">Yardage</h6>
                  <p className="sub__title">
                    <NumberFormat thousandSeparator={true} decimalScale={2}
                          fixedDecimalScale={true} displayType={"text"} value={order.yardage} />
                  </p>
                </div>
                {(!_.get(order, "customer.hidePrice", true) || _.get(user, "role", false) !== "user") && (
                  <React.Fragment>
                    <div className="company__driver-title">
                      <h6 className="title">Dump Rate: {`${_.startCase(_.get(order, "dumpRateType", ""))}`}</h6>
                      <p className="sub__title">
                        <NumberFormat
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          value={order.dumpRate}
                        />
                      </p>
                    </div>
                    {_.get(order, "isEnvironmentalLoadFee", false) && (
                      <div className="company__driver-title">
                        <h6 className="title">Environmental Load Fee</h6>
                        <p className="sub__title">{numberFormat(order.environmentalLoadFeeAmount)}</p>
                      </div>
                    )}
                    {_.get(order, "isFuelSurchardeFee", false) && (
                      <div className="company__driver-title">
                        <h6 className="title">Fuel Surcharge</h6>
                        <p className="sub__title">{numberFormat(order.fuelSurchargeTotal)}</p>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-md-9 col-sm-9 col-xs-9 col-xl-9">
                <h5 className="card-title">Notes</h5>
              </div>
              <div className="col-md-3 col-sm-3 col-xs-3 col-xl-3 text-right">
                {!showEditor &&
                  (order && order.notes ? (
                    <button
                      type="button"
                      className="btn btn-dark btn-sm"
                      onClick={() => {
                        this.setState({ showEditor: true, notes: order.notes });
                      }}
                    >
                      Edit Note
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-dark btn-sm"
                      onClick={() => this.setState({ showEditor: true })}
                    >
                      Add Note
                    </button>
                  ))}
              </div>
            </div>
          </div>
          {(showEditor || order.notes) && (
            <div className="card-body">
              <div className="row">
                {order && order.notes ? (
                  !showEditor ? (
                    <div className="col-md-12 notes">{_.get(order, "notes", "")}</div>
                  ) : (
                    <div className="notes__default col-md-12">
                      <div className="notes__default-inner">
                        <textarea
                          name="notes"
                          className="notes__default-input"
                          onChange={this.handleChange.bind(this)}
                          value={this.state.notes}
                        ></textarea>
                      </div>
                      <button
                        type="button"
                        className="btn btn-dark btn-sm"
                        onClick={this.saveNotes.bind(this, order._id)}
                      >
                        Save Note
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        type="button"
                        className="btn btn-gray btn-sm"
                        onClick={() => this.setState({ showEditor: false })}
                      >
                        Cancel
                      </button>
                    </div>
                  )
                ) : (
                  <div className="notes__default col-md-12">
                    <div className="notes__default-inner">
                      <textarea
                        name="notes"
                        className="notes__default-input"
                        onChange={this.handleChange.bind(this)}
                        value={this.state.notes}
                      ></textarea>
                    </div>
                    <button
                      type="button"
                      className="btn btn-dark btn-sm"
                      onClick={this.saveNotes.bind(this, order._id)}
                    >
                      Save Note
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="card payment__breakdown">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                <h5 className="card-title">Payment Breakdown</h5>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <ul className="card__scale-list">
                  <li>
                    <span>Inbound Weight</span>
                    <span>
                      <NumberFormat thousandSeparator={true} displayType={"text"} value={order.inboundWeight} /> lbs
                    </span>
                  </li>
                  <li>
                    <span>Outbound Weight </span>
                    <span>
                      <NumberFormat thousandSeparator={true} displayType={"text"} value={order.outboundWeight} /> lbs
                    </span>
                  </li>
                  <li>
                    <span>Net Weight</span>
                    <span>
                      <NumberFormat thousandSeparator={true} displayType={"text"} value={order.netWeight} /> lbs
                    </span>
                  </li>
                  <li>
                    <span>Tonnage </span>
                    <span>
                      <NumberFormat thousandSeparator={true} displayType={"text"} value={order.tonnage} /> tons
                    </span>
                  </li>
                  {(!_.get(order, "customer.hidePrice", true) || _.get(user, "role", false) !== "user") && (
                    <React.Fragment>
                      <li>
                        <span>Dump Rate ({`${_.startCase(_.get(order, "dumpRateType", ""))}`})</span>
                        <span>
                          <NumberFormat
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                            value={order.dumpRate}
                          />
                        </span>
                      </li>
                      {_.get(order, "additionalItems", []).length !== 0 && (
                        <>
                          <hr />
                          {_.get(order, "additionalItems", []).map(({ label, value, quantity }) => {
                            return (
                              <li key={label}>
                                <span>
                                  {label} ({quantity}) (${value}/unit)
                                </span>
                                <span>${numberFormat(value * quantity)}</span>
                              </li>
                            );
                          })}
                        </>
                      )}
                      {_.get(order, "isEnvironmentalLoadFee", false) && (
                        <React.Fragment>
                          <hr />
                          <li>
                            <span>Environmental Load Fee</span>
                            <span>
                              <NumberFormat
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                displayType={"text"}
                                value={order.environmentalLoadFeeAmount}
                              />
                            </span>
                          </li>
                        </React.Fragment>
                      )}
                      {_.get(order, "isFuelSurchardeFee", false) && (
                        <React.Fragment>
                          <hr />
                          <li>
                            <span>Fuel Surcharge</span>
                            <span>
                              <NumberFormat
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                displayType={"text"}
                                value={order.fuelSurchargeTotal}
                              />
                            </span>
                          </li>
                        </React.Fragment>
                      )}
                      {_.get(order, "capoutPrice", 0) !== 0 && (
                        <>
                          <hr />
                          <li>
                            <span>Capped </span>
                            <span>{_.get(order, "tonnageDifference", false) < 0 ? "Yes" : "No"} </span>
                          </li>
                          <li>
                            <span>{`${_.startCase(_.get(order, "dumpRateType", ""))}`}</span>
                            <span>{numberFormat(_.get(order, "dumpRate", 0))} </span>
                          </li>
                          <li>
                            <span>Tonnage Cap</span>
                            <span>{numberFormatLbs(_.get(order, "tonnageCap", 0))} tons </span>
                          </li>
                          <li>
                            <span>Capout Price</span>
                            <span>${numberFormat(_.get(order, "capoutPrice", 0))} </span>
                          </li>
                          <li>
                            <span>Tonnage Difference</span>
                            <span>{numberFormatLbs(_.get(order, "tonnageDifference", 0))} tons </span>
                          </li>
                        </>
                      )}
                      {_.get(order, "chargeStripeCardFees", false) && ((order.paymentType === "credit-card" || order?.paymentType === "terminal") || this.checkPaymentType(order) ) && (
                        <>
                          <hr />
                          <li className="">
                            <span>Handling Fee</span>
                            {numberFormat(order.stripeCardFees)}
                          </li>
                          <li className="">
                            <span>Order Amount </span>
                            {  order?.totalActualAmount ? numberFormat(order?.totalActualAmount) : numberFormat(order.totalAmount - order.stripeCardFees)}
                          </li>
                        </>
                      )}
                      <hr />
                      <li className="total__order-amount align-items-start">
                        <span>Total Order Amount</span>
                     <div>
                         <span className="amount cursor-pointer d-flex align-items-center">
                         <NumberFormat
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        value={order.totalAmount}
                        />
                       <Tooltip
                       placement="top"
                       title={qbSync ? "Unable to edit order because invoice has already been synced to Quickbooks" : user?.permissions?.permissionOptions?.editSyncedOrders === true ? "" : "You do not have permission to edit this order"}
                       zIndex="9999"
                       >
                       <img
                      className="img-fluid edit__icon"
                      src={Edit}
                      alt="Edit"
                      onClick={() => !qbSync && (user?.permissions?.permissionOptions?.editSyncedOrders === true) && this.setState({ editTAModal: true })}
                      />
                     </Tooltip>
                     </span>
                     <div className="rounded-text">
                     {order?.isRoundOffAmount && <small><pre>(Rounded)</pre></small>}
                     </div>
                     </div>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                <h5 className="card-title">Payment Information</h5>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group material-textfield">
                  <div className="company__driver-title">
                    <h6 className="title">Payment Terms</h6>
                    <p className="sub__title">{`${_.toUpper(_.get(order, "paymentTerms", "cod"))}`}</p>
                  </div>
                </div>
                {_.get(order, "paymentTerms", "cod") !== "account" && (
                  <div className="company__driver-title">
                    <h6 className="title">Payment Method</h6>
                    <p className="sub__title">
                      {_.get(order, "paymentType", "") ==="" ? _.get(order, "splitPayment", []).length > 0 && order.splitPayment.map((payment,index)=>_.startCase(payment?.paymentType) + ( index < order.splitPayment.length - 1 ?", " :" "))  :`${_.startCase(_.get(order, "paymentType", ""))}`}</p>
                    {order.signatureUrl !== "" &&
                      <img src={`${order.signatureUrl}`} alt="signature"></img>
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="sustainability__information">
          { sustainabilityInfos?.length > 0 && sustainabilityInfos.map((sustainabilityInfo, i) => {
            return (
              sustainabilityInfo?.selectedFields?.length > 0 &&
              <div className="card" key={i}>
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-md-7 col-sm-7 col-xs-7 col-xl-7">
                      <div className="sustainability__information-inner">
                        <div>
                          <h5 className="card-title">Sustainability Information</h5>
                          <p className="card-title-sm">{sustainabilityInfo.address}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-5 col-xs-5 col-xl-5">
                      <div className="sustainability__information-inner float-right">
                        <div>
                        <button
                          onClick={() => this.navigate(order.ticketNumber, i)}
                          className="btn btn-dark btn-sm update__information"
                        >
                          Update Sustainability Information
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("totalpercentage") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Total % (yards)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.totalpercentage)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Total % (tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.weight_total)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Weight (tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.weight || _.get(order, "tonnage", 0)}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("waste") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Waste % (Yards)</h6>
                              <p className="sub__title">{sustainabilityInfo.waste}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Waste % (Tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.tonnagepercentage[0].value)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Waste Weight (Tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.tonnageweight[0].value}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("brick") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Brick % (yards)</h6>
                              <p className="sub__title">{sustainabilityInfo.brick}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Brick % (tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.tonnagepercentage[1].value)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Brick Weight (tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.tonnageweight[1].value}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("dirt") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Dirt % (yards)</h6>
                              <p className="sub__title">{sustainabilityInfo.dirt}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Dirt % (tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.tonnagepercentage[2].value)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Dirt Weight (tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.tonnageweight[2].value}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("concrete") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Concrete % (yards)</h6>
                              <p className="sub__title">{sustainabilityInfo.concrete}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Concrete % (tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.tonnagepercentage[3].value)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Concrete Weight (tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.tonnageweight[3].value}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("cleanwood") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Wood % (yards)</h6>
                              <p className="sub__title">{sustainabilityInfo.cleanwood}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Wood % (tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.tonnagepercentage[4].value)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Wood Weight (tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.tonnageweight[4].value}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("metal") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Metal % (yards)</h6>
                              <p className="sub__title">{sustainabilityInfo.metal}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Metal % (tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.tonnagepercentage[5].value)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Metal Weight (tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.tonnageweight[5].value}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("paper_cardboard") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Paper/Cardboard % (yards)</h6>
                              <p className="sub__title">{sustainabilityInfo.paper_cardboard}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Paper/Cardboard % (tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.tonnagepercentage[6].value)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Paper/Cardboard Weight (tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.tonnageweight[6].value}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("plastic") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Plastic % (yards)</h6>
                              <p className="sub__title">{sustainabilityInfo.plastic}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Plastic % (tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.tonnagepercentage[7].value)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Plastic Weight (tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.tonnageweight[7].value}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("drywall") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Drywall % (yards)</h6>
                              <p className="sub__title">{sustainabilityInfo.drywall}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Drywall % (tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.tonnagepercentage[8].value)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Drywall Weight (tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.tonnageweight[8].value}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("glass") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Glass % (yards)</h6>
                              <p className="sub__title">{sustainabilityInfo.glass}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Glass % (tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.tonnagepercentage[9].value)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Glass Weight (tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.tonnageweight[9].value}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      {sustainabilityInfo?.selectedFields?.length > 0&&sustainabilityInfo.selectedFields.indexOf("asphalt") !== -1 && (
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Asphalt % (yards)</h6>
                              <p className="sub__title">{sustainabilityInfo.asphalt}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Asphalt % (tons)</h6>
                              <p className="sub__title">{Math.round(sustainabilityInfo.tonnagepercentage[10].value)}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Asphalt Weight (tons)</h6>
                              <p className="sub__title">{sustainabilityInfo.tonnageweight[10].value}</p>
                            </div>
                          </li>
                        </ul>
                      )}
                      <div className="recycling__title">
                        <span>Recycling %</span> {Math.round(sustainabilityInfo.recyclingpercentage)}%
                      </div>
                      <div className="risidual__waste">
                        <span>Waste %</span> {Math.round(sustainabilityInfo.residualpercentage)}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <button
            className="btn btn-dark btn-lg w-100 btn-addlead"
            onClick={() =>
              this.props.history.push({
                pathname: `/sustainability-information`,
                state: {
                  orderId: order.ticketNumber,
                  tonnage: order.tonnage,
                  index: -1
                }
              })
            }
          >
            Add LEED/Diversion %
          </button>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                <h5 className="card-title">Order Log</h5>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <ul className="card__scale-list ordercard__list">
                  {orderLogs.map(olog => {
                    return (
                      <li key={olog._id}>
                        <span dangerouslySetInnerHTML={{ __html: `${olog.log}` }}></span>
                        <span>{`${moment(olog.createdAt)?.tz(this.state?.tz)?.format("MM/DD/YY")} ${moment(olog.createdAt)?.tz(this.state?.tz)?.format(
                          "hh:mm a"
                        )}`}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
           {(user?.permissions?.permissionOptions && user?.permissions?.permissionOptions?.voidOrders === true) ? 
           <div className="page-footer-btn-grp">
              {/* <button
                className="btn btn-sm btn-red btn-delete"
                disabled={progressBar}
                onClick={() => this.setState({ isDeleteModal: true })}
               >
                <DeleteIcon />
                {progressBar ? "Please wait..." : "Delete Order"}
              </button> */}
              {!this?.props?.order?.isDeleted && (
              <button
                className="btn btn-sm btn-red btn-delete mx-2"
                disabled={softDelProcess}
                onClick={() => this.setState({ isDeleteModal: true ,softDelete:true})}
              >
                <DeleteIcon />
                {softDelProcess ? "Please wait..." : "Void"}
              </button>
          )}
          </div>:null}
        <Drawer
          placement="right"
          closable={false}
          onClose={this.props.onClose}
          visible={this.props.visible}
          className="upload__paper-ticket"
        >
          <div className="upload__paper-header-inner">
            <div className="drawer__header d-flex align-items-center justify-content-between">
              <div className="drawer__header-title">Upload Paper Ticket </div>
              <div onClick={this.props.onClose} className="btn btn-close">
                <CloseIcon />
              </div>
            </div>
            <div className="upload__paper-header-btn">
              <button
                className="btn btn-dark btn-lg w-100 upload__photo-btn"
                onClick={() => {
                  this.setState({ manualCameraOn: false, isCrop: false, paperTicketUrl: "" });
                  this.refs.fileUploader.click();
                }}
              >
                Upload Photo of Paper Receipt
              </button>
              <button
                className="btn btn-dark btn-lg w-100 take__photo-btn"
                onClick={() => this.setState({ manualCameraOn: true, isCrop: false, paperTicketUrl: "" })}
              >
                Take Photo of Paper Receipt
              </button>
            </div>
            <div className="calendar__form">
              <div className="input-from">
                <div className="form-group">
                  <DatePicker
                    selected={paperTicketDate}
                    onChange={paperTicketDate => this.setState({ paperTicketDate })}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    className="form-control material-textfield-input"
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                  <label className="material-textfield-label label__for-date">Date</label>
                </div>
              </div>
            </div>
          </div>
          <div className="drawer__inner-body">
            <div className="contain__box">
              {manualCameraOn ? (
                <ReactWebcam onTakePhoto={paperTicketUrl => this.setState({ paperTicketUrl, manualCameraOn: false })} />
              ) : (
                paperTicketUrl !== "" &&
                (fileType === "pdf" ? (
                  <object data={paperTicketUrl} alt="">
                    <a href={`${_.get(order, "paperTicketUrl", "")}`}>test.pdf</a>
                  </object>
                ) : (
                  <>
                    {isCrop ? (
                      <ReactImageCrop licenseImageUrl={paperTicketUrl} setIsCrop={blob => this.onCropImage(blob)} />
                    ) : (
                      <>
                        <img className="img-fluid" src={paperTicketUrl} alt="" />
                        <div className="crop_icon">
                          <img
                            className="crop_icon-img"
                            src={cropIcon}
                            alt=""
                            onClick={() => this.setState({ isCrop: true })}
                          />
                        </div>
                      </>
                    )}
                  </>
                ))
              )}
            </div>
          </div>
          <button
            disabled={uploadProgress}
            className="btn btn-dark btn-lg w-100 btn-save"
            onClick={this.savePaperTicket}
          >
            {uploadProgress ? "Please wait..." : "Save"}
          </button>
        </Drawer>
        <LightBox
          isOpen={this.state.isLightBoxOpen}
          images={this.state.images}
          onClose={() => this.setState({ isLightBoxOpen: false })}
          className="adadad"
        />
        <DeleteOrderModal
          isDeleteModal={this.state.isDeleteModal}
          closeModal={() => this.setState({ isDeleteModal: false ,softDelete:false})}
          deleteOrder={this.deleteOrder}
          progressBar={progressBar}
          is_paid_order_message={order?.completed}
          softDelete={this.state.softDelete}
          hasPaymentReceipt={this.props.hasPaymentReceipt}
        />
        <ReactModal ariaHideApp={false} isOpen={this.state.editTAModal}>
          <EditTotalAmount
            closeModal={() => this.setState({ editTAModal: false })}
            order={order}
            updateTotalAmount={updateTotalAmount}
            fetchOrder={this.props.onSaveNotesSuccess}
            getPricingTierById={this.props.getPricingTierById}
          />
        </ReactModal>
      </>
    );
  }
}

export default CompletedOrder;
