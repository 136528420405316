import React, { PureComponent } from "react";
import "../styles.scss";
import { Dropdown, Menu } from "antd";
import {
  FilterIcon,
  MoreIcon,
  PlushIcon,
  CloseIcon,
} from "../../../components/icons";
import moment from "moment-timezone";
import _ from "lodash";
import { Drawer } from "antd";
import InvoiceDetailComponent from "./invoiceDetail";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import deleteIcon from "../../../images/delete-icon-red.svg";
import closeIcon from "../../../images/close_black.svg";
import CustomerDetailComponent from "../../CustomerDetail/component";
import EmailAlert from "../../../utils/email_warning_popup";
import { reOrderArray } from "../../../constants/common";
import { DebounceInput } from "react-debounce-input";
import SearchCheckboxComponent from "../../Common/SearchCheckbox";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default class NeedReviewComponent extends PureComponent {
  constructor(props) {
    const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
    super(props);
    this.state = {
      searchStartsWith: this.props.searchStartsWith,
      toggleMenu: false,
      activeUrl: "",
      needReviedData: [],
      searchText: this.props.searchText || "",
      startDate: moment(props.date.startDate)?.format("MMM DD, YYYY"),
      endDate: moment(props.date.endDate)?.format("MMM DD, YYYY"),
      visible: false,
      filtersData: [],
      invoiceDeliveryData: [],
      reRender: true,
      invoicesTotal:0,
      isModelOpen: false,
      modelData: {},
      pleaseWait: false,
      postPleaseWait: false,
      isPrintRecipt: false,
      selectedCheckBoxData: [],
      batchAction: "",
      bulkInvoiceEmailPleaseWait: false,
      spinning: false,
      savePleaseWait: false,
      isCustomerModelOpen: false,
      customerId: "",
      defaultUrl: "/billing/needs-review",
      actionClass: false,
      limit: 20,
      isLoading:true,
      isSisterCompany:false,
      showModal:false,
      sisterCompanyData:{},
      API_URL:process.env.REACT_APP_API_HOSTNAME,
      transactionFrequency: [
        {
          name: "Each Transaction",
          value: "auto",
        },
        {
          name: "Daily",
          value: "daily",
        },
        {
          name: "Weekly",
          value: "weekly",
        },
        {
          name: "monthly",
          value: "monthly",
        },
      ],
      tz
    };
  }

  // searchTextFilter=()=>{

  // }
  closeModalAlert = () => {
    this.setState({ showModal: false });
  };
  componentDidMount = async () => {
    const searchText = this.props.searchText;
    // this.setState({ searchText });
    document.title = " Need Review | Weighworks";

    const sessionFilterData = sessionStorage.getItem('need_review_filtersData');
    if (sessionFilterData) {
      try {
        const filtersDataArray = JSON.parse(sessionFilterData);
        this.setState({
          filtersData: filtersDataArray,
        });
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }

    setTimeout(() => {
      this.fetchNeedReviewData(
        searchText,
        moment(this.props.date.startDate)?.format("MMM DD, YYYY"),
        moment(this.props.date.endDate)?.format("MMM DD, YYYY")
      );
    }, 500);
    
    this.fetchInvoiceDeliveryData();
    const limit = await localStorage.getItem("needReviewLimit");
    if (limit) {
      this.setState({ limit: Number(limit) });
    }
    const urlarray = window.location.pathname.split("/");
    if (urlarray[4] === "general") {
      this.setState({ isCustomerModelOpen: true, customerId: urlarray[3] });
    }
    // const urlarray=window.location.pathname.split('/')
    // if(urlarray[3]==="past-weigh-ins"){
    //   this.setState({isCustomerModelOpen:true})
    // }
    // if(urlarray[3]==="general"){
    //   this.setState({isCustomerModelOpen:true})
    // }
  };

  submitImages(data) {
    const blob = this.dataURItoBlob(data);
    const formdata = new FormData();
    formdata.append("photos", blob);
    formdata.append("fileName", `${new Date().getTime()}.png`);
    formdata.append("folderName", "load");
    return formdata;
  }

  dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  fetchInvoiceDeliveryData = async (id) => {
    const {
      value: { data },
    } = await this.props.getInvoiceDeliveryByIdData(id);
    this.setState({ invoiceDelivery: data?.name });
  };

  round2decimal = (number) => {
    return Math.round(parseFloat(number) * 100) / 100;
  };

  onChangeFile = async (e, index) => {
    // const file = e.target.files[0];
    const needReviedData = this.state.needReviedData;
    const imageShow = URL.createObjectURL(e.target.files[0]);
    this.getBase64(e.target.files[0], (result) => {
      needReviedData[index].customAttachments.push(result);
      needReviedData[index].showAttachments.push(imageShow);
      //  needReviedData?.Attachments.push(imageShow);
      this.setState({ needReviedData: needReviedData }, () => {
        this.setState({ reRender: !this.state.reRender });
      });
    });
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  reArrangOrdersData = (orderData) => {
    let order = orderData;
    let reArrangOrders = [];
    if (order.length > 0) {
      order.map((item) => {
        if (item?.orderCreationType === "createCharge") {
          if (item?.orderBreakDownItems?.isminimumPrice) {
            let obj = {
              fixedItems: [
                {
                  fixedItem: "Dumping services",
                  unitPrice: item?.orderBreakDownItems?.minimumPrice,
                  units: 1,
                  totalamout: item?.orderBreakDownItems?.totalAmountDue,
                },
              ],
              ticketNumber: item?.ticketNumber,
              orderCreationType: item?.orderCreationType,
            };
            reArrangOrders.push(obj);
            return null;
          } else {
            if (item?.orderBreakDownItems?.isCapOutPrice) {
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType,
              };
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  const fixedItemName = _.startCase(fixedItems?.fixedItem);
                  if (fixedItemName.includes("Yard")) {
                    if (
                      Number(fixedItems?.unitPrice) >
                      Number(item?.orderBreakDownItems?.capoutPrice)
                    ) {
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: item?.orderBreakDownItems?.capoutPrice,
                        units: fixedItems.units,
                        totalamout:
                          item?.orderBreakDownItems?.capoutPrice *
                          fixedItems.units,
                      };
                      obj.fixedItems.push(fixedItemObject);
                    } else {
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: fixedItems?.unitPrice,
                        units: fixedItems.units,
                        totalamout: fixedItems?.unitPrice * fixedItems.units,
                      };
                      obj.fixedItems.push(fixedItemObject);
                    }
                  } else {
                    let fixedItemObject = {
                      fixedItem: fixedItems.fixedItem,
                      unitPrice: fixedItems?.unitPrice,
                      units: fixedItems.units,
                      totalamout: fixedItems?.unitPrice * fixedItems.units,
                    };
                    obj.fixedItems.push(fixedItemObject);
                  }
                  return null;
                });
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map(
                  (chargeByWeightFixedItems) => {
                    let chargeByWeightItemObject = {
                      fixedItem: chargeByWeightFixedItems?.fixedItem,
                      unitPrice: chargeByWeightFixedItems?.unitPrice,
                      units: chargeByWeightFixedItems?.units,
                      totalamout:
                        chargeByWeightFixedItems?.unitPrice *
                        chargeByWeightFixedItems?.units,
                    };

                    obj.fixedItems.push(chargeByWeightItemObject);
                    return null;
                  }
                );
              }
              reArrangOrders.push(obj);
            } else {
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType,
              };
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  let fixedItemObject = {
                    fixedItem: fixedItems?.fixedItem,
                    unitPrice: fixedItems?.unitPrice,
                    units: fixedItems?.units,
                    totalamout: fixedItems?.unitPrice * fixedItems?.units,
                  };
                  obj.fixedItems.push(fixedItemObject);
                  return null;
                });
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map(
                  (chargeByWeightItems) => {
                    let chargeByWeightItemsObject = {
                      fixedItem: chargeByWeightItems?.fixedItem,
                      unitPrice: chargeByWeightItems?.unitPrice,
                      units: chargeByWeightItems?.units,
                      totalamout:
                        chargeByWeightItems?.unitPrice *
                        chargeByWeightItems?.units,
                    };
                    obj.fixedItems.push(chargeByWeightItemsObject);
                    return null;
                  }
                );
              }
              reArrangOrders.push(obj);
            }
          }
        } else {
          let total = 0;
          let dumpRate = 0;
          let isCapOutPrice = item?.pricingTierCapoutPrice > 0;
          let units = 0;
          if (!item?.isMinimumPrice) {
            if (item?.dumpRateType.indexOf("Yard") !== -1) {
              units = Number(item?.yardage);
              if (item?.tonnageDifference < 0) {
                if (isCapOutPrice) {
                  dumpRate = Number(item.pricingTierCapoutPrice);
                  total = Number(item?.capoutPrice);
                } else {
                  dumpRate = Number(item?.dumpRate);
                  total = Number(item?.yardage) * Number(item?.dumpRate);
                }
              } else {
                dumpRate = Number(item?.dumpRate);
                total = Number(item?.yardage) * Number(item?.dumpRate);
              }
            } else {
              units = Number(item?.tonnage);
              if (item?.tonnageDifference < 0) {
                if (isCapOutPrice) {
                  dumpRate = Number(item?.capoutPrice);
                  total = Number(item?.capoutPrice);
                } else {
                  dumpRate = Number(item?.dumpRate);
                  total = Number(item?.tonnage) * Number(item?.dumpRate);
                }
              } else {
                dumpRate = Number(item?.dumpRate);
                total = Number(item?.tonnage) * Number(item?.dumpRate);
              }
            }
            let obj = {
              inboundWeight: item?.inboundWeight,
              outboundWeight: item?.outboundWeight,
              tonnageDifference: item?.tonnageDifference,
              isCapped: item?.isCapped,
              additionalItems: item?.additionalItems,
              // dumpRateType: item?.dumpRateType,
              dumpRate: Number(dumpRate),
              // ticketNumber: item?.ticketNumber,
              netWeight: item?.netWeight,
              // yardage: item?.yardage,
              // tonnage: item?.tonnage,
              // totalAmount: item?.totalAmount,
              totalAmount: total,
              units: units,
              dumpRateType: item?.dumpRateType,
              ticketNumber: item?.ticketNumber,
              isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
              environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
              isFuelSurchardeFee: item?.isFuelSurchardeFee,
              fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
              fuelSurchargeTotal: item?.fuelSurchargeTotal,
              createdAt: item?.createdAt,
            };
            reArrangOrders.push(obj);
          } else {
            let obj = {
              isMinimumPrice: item?.isMinimumPrice,
              inboundWeight: item?.inboundWeight,
              outboundWeight: item?.outboundWeight,
              tonnageDifference: item?.tonnageDifference,
              isCapped: item?.isCapped,
              additionalItems: item?.additionalItems,
              // dumpRateType: item?.dumpRateType,
              dumpRate: Number(item?.totalAmount),
              // ticketNumber: item?.ticketNumber,
              netWeight: item?.netWeight,
              // yardage: item?.yardage,
              // tonnage: item?.tonnage,
              // totalAmount: item?.totalAmount,
              totalAmount: item?.totalAmount,
              units: 1,
              dumpRateType: "Dumping services",
              ticketNumber: item?.ticketNumber,
              isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
              environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
              isFuelSurchardeFee: item?.isFuelSurchardeFee,
              fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
              fuelSurchargeTotal: item?.fuelSurchargeTotal,
              createdAt: item?.createdAt,
            };
            reArrangOrders.push(obj);
          }
        }
        return null;
      });
    }
    // this.setState({reArrangOrders:reArrangOrders,reRender: !this.state.reRender})
    return reArrangOrders;
  };

  updateInvoiceDetail = async (needReviewIndex, type, billingStatus) => {
    const { uploadImage } = this.props;
    const needReviedData = this.state.needReviedData[needReviewIndex];
    if (type === "postAndSend") this.setState({ pleaseWait: true });
    else if (type === "save") this.setState({ savePleaseWait: true });
    else this.setState({ postPleaseWait: true });
    let attachments = [];
    let finalAttachments = [];
    if (
      needReviedData?.customAttachments &&
      needReviedData?.customAttachments.length > 0
    ) {
      needReviedData.customAttachments.forEach((item) => {
        attachments.push(uploadImage(this.submitImages(item)));
      });
    }
    const attachImage = await Promise.all(attachments);
    const attachImages = attachImage.map((item) => {
      return item.value.url;
    });
    finalAttachments = needReviedData?.attachments.concat(attachImages);
    const payload = {
      _id: needReviedData._id,
      notes: needReviedData.notes,
      total: needReviedData.totalOrdersAmount,
      balacnce:
        Number(needReviedData?.totalOrdersAmount) - needReviedData?.amountPaid,
      orders: [],
      updateType: type,
      attachments: finalAttachments,
      billingStatus: billingStatus,
    };
    needReviedData.order.map((item, index) => {
      if (item.orderCreationType === "createCharge") {
        const orderBreakDownItems = item?.orderBreakDownItems;

        if (item.orderBreakDownItems.fixedItem.length > 0) {
          orderBreakDownItems.fixedItem =
            item.orderBreakDownItems.fixedItem.filter((data) => {
              delete data.isDeleteAble;
              return (
                data.fixedItem !== "" &&
                Number(data.units) > 0 &&
                Number(data.unitPrice) > 0
              );
            });
        }

        if (item.orderBreakDownItems.chargeByWeight.length > 0) {
          orderBreakDownItems.chargeByWeight =
            item.orderBreakDownItems.chargeByWeight.filter((data) => {
              delete data.isDeleteAble;
              return (
                data.fixedItem !== "" &&
                Number(data.units) > 0 &&
                Number(data.unitPrice) > 0
              );
            });
        }

        const obj = {
          orderCreationType: "createCharge",
          orderId: item._id,
          orderBreakDownItems: item.orderBreakDownItems,
          totalAmount: item.totalAmount,
          yardage: item.yardage,
          tonnage: item.tonnage,
          isEnvironmentalLoadFee: item.isEnvironmentalLoadFee,
          environmentalLoadFeeAmount: item.environmentalLoadFeeAmount,
          isFuelSurchardeFee: item.isFuelSurchardeFee,
          fuelSurchardeFeeAmount: item.fuelSurchardeFeeAmougetProjectByProjectIdnt,
          fuelSurchargeTotal: item.fuelSurchargeTotal,
        };
        payload.orders.push(obj);
      } else {
        let additionalItems = item.additionalItems;
        if (item.additionalItems.length > 0) {
          additionalItems = additionalItems.filter((data) => {
            delete data.isDeleteAble;
            return (
              data.label !== "" &&
              data.name !== "" &&
              Number(data.quantity) > 0 &&
              Number(data.value) > 0
            );
          });
        }
        const obj = {
          additionalItems: additionalItems,
          orderId: item._id,
          netWeight: item.netWeight,
          dumpRateType: item.dumpRateType,
          tonnage: item.tonnage,
          yardage: item.yardage,
          dumpRate: item.dumpRate,
          totalAmount: item.totalAmount,
          capoutPrice: item.capoutPrice,
          tonnageCap: item.tonnageCap,
          tonnageDifference: item.tonnageDifference,
          isEnvironmentalLoadFee: item.isEnvironmentalLoadFegetProjectByProjectIde,
          environmentalLoadFeeAmount: item.environmentalLoadFeeAmount,
          isFuelSurchardeFee: item.isFuelSurchardeFee,
          fuelSurchardeFeeAmount: item.fuelSurchardeFeeAmount,
          fuelSurchargeTotal: item.fuelSurchargeTotal,
          isMinimumPrice:item.isMinimumPrice
        };
        payload.orders.push(obj);
      }
      return null;
    });
    const {
      value: { status },
    } = await this.props.updateInvoiceOrder(payload);
    if (status) {
      this.setState({ pleaseWait: false });
      this.setState({ postPleaseWait: false });
      this.setState({ savePleaseWait: false });
    }
    this.fetchNeedReviewData(
      this.state.searchText,
      this.state.startDate,
      this.state.endDate
    );
  };

  phoneFormate = (phone) => {
    return phone
      .replace(/\D+/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  updateinvoiceDetailData = (data) => {
    const needReviedData = this.state.needReviedData;
    needReviedData[data.index] = data;
    this.setState({ needReviedData: needReviedData });
  };

  numberWithCommas = (x) => {
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };

  openBillingReciept = async (data) => {
    const orderedArray = reOrderArray([data]);
    const {value} = await this.props.batchPrintInvoice({
      invoicelist: orderedArray,
    });
    if (value) {
      const url = URL.createObjectURL(value);
      const printWindow = window.open(url, '_blank');
        printWindow.onload = function() {
          printWindow.print();
        };
      }
    /* let viewInvoiceUrl = `${this.state.API_URL}/invoice_send?invoiceId=${_.get(
      data,
      "invoiceId",
      ""
    )}&companyId=${_.get(data.company[0], "_id", "")}`;
    fetch(viewInvoiceUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.blob(); // Convert the response to a Blob
        } else {
          throw new Error('Failed to fetch the PDF');
        }
      })
      .then(blob => {
        // Create an object URL for the Blob
        const url = URL.createObjectURL(blob);
        const printWindow = window.open(url, '_blank');

        // Print the document in the new tab
        printWindow.onload = function() {
          printWindow.print();
        };
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle errors here
      }); */
    /* let printWindow = "";
    let printData = [data];
    const printPromises = printData.map((items, i) => {
      return new Promise(async (resolve, reject) => {
        let { order } = items;
        const orderData = sort_by_descending(this.reArrangOrdersData(order), "createdAt");
        let body1=''
        if(this.state.isSisterCompany){
          body1 = PrintSisterCompanyInvoiceReceipt(items,this.state.sisterCompanyData);
          body1 += bottomSisterCompanyContent();
        }else{
          body1 = PrintInvoiceReceipt(items);
          body1 += bottomContent();
        }
        for (let i = 0; i < orderData.length; i++) {
          let ordr = orderData[i];
          const { value } = await this.props.fetchOrder(ordr.ticketNumber);
          order = value.data;
          const last = i === orderData.length - 1;
          if(this.state.isSisterCompany){
            body1 += PrintSisterCompanyOrderReceipt(value,last);
          }else{
            body1 += PrintOrderReceipt(value,last);
          }
        }
        printWindow += body1;
        resolve()
      });
    });
  
    Promise.all(printPromises).then(() => {}).finally(()=>{
      let printInvoice = window.open(`invoice`, "_blank");
      if(printInvoice){
      printInvoice.document.write(printWindow);
      printInvoice.document.close(); // Close the document writing
      printInvoice.onload = function () {
        printInvoice.print();
      };
    }
  }) */
  };

  modelOpen = (needReviewindex) => {
    let needReviedData = this.state.needReviedData[needReviewindex];
    needReviedData.index = needReviewindex;
    // this.setState({ isModelOpen: true, modelData:needReviedData});
    this.getinvoiceDetailData(needReviedData.invoiceId);
  };
  getinvoiceDetailData = async (invoiceId) => {
    const payload = {
      invoiceId: invoiceId,
    };
    const {
      value: {
        data: { invoiceDetail ,projectDetails},
      },
    } = await this.props.getInvoiceDetalByInvoiceId(payload);
    let modelData = invoiceDetail[0];
    modelData["customAttachments"] = [];
    modelData["showAttachments"] = [];
    this.setState({ isModelOpen: true, modelData: modelData , projectData : projectDetails });
  };
  assignUrl = (url) => {
    let customerId = "";
    const path = url.split("/");
    if (path[1] !== "order-details" && this.state.customerId === "") {
      customerId = path[2];
    } else {
      customerId = this.state.customerId;
    }

    if (path[1] === "order-details" || path[1] === "edit-customer") {
      if (path[1] === "order-details") {
        this.props.navigate(url);
        this.setState({ isCustomerModelOpen: false });
      }
      if (path[1] === "edit-customer") {
        this.props.navigate({
          pathname: "/edit-customer",
          state: {
            id: customerId,
          },
        });
      }
    } else {
      if (path[3] === "general") {
        this.props.navigate(
          `/needReview/customer-detail/${customerId}/general`
        );
      }
      if (path[3] === "billing" && path[4] === "invoice") {
        this.props.navigate(
          `/needReview/customer-detail/${customerId}/billing/invoice`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "billing" && path[4] === "payment-receipt") {
        this.props.navigate(
          `/needReview/customer-detail/${customerId}/billing/payment-receipt`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "billing" && path[4] === "credit-memo") {
        this.props.navigate(
          `/needReview/customer-detail/${customerId}/billing/credit-memo`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "past-weigh-ins") {
        this.props.navigate(
          `/needReview/customer-detail/${customerId}/past-weigh-ins`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "rates") {
        this.props.navigate(`/needReview/customer-detail/${customerId}/rates`);
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "drivers") {
        this.props.navigate(
          `/needReview/customer-detail/${customerId}/drivers`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "trucks") {
        this.props.navigate(`/needReview/customer-detail/${customerId}/trucks`);
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "payment-methods") {
        this.props.navigate(`/needReview/customer-detail/${customerId}/payment-methods`);
      }
      if (path[3] === "projects") {
        this.props.navigate(`/needReview/customer-detail/${customerId}/projects`);
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "history") {
        this.props.navigate(`/needReview/customer-detail/${customerId}/history`);
        this.setState({ isCustomerModelOpen: true });
      }
    }
  };

  closeModel = () => {
    this.setState({ isModelOpen: false, isPrintRecipt: false });
  };
  closeCustomerDetatilModel = () => {
    this.setState({ isCustomerModelOpen: false });
    this.props.navigate("/billing/needs-review");
  };

  calculateTotalScaleAmount(needReviewIndex, orderIndex) {
    const needReviedData = this.state.needReviedData;
    const order = needReviedData[needReviewIndex].order[orderIndex];
    let showYardageValue = false;
    // const selectPaymentMethod = "terminal"
    const additionalItems =
      needReviedData[needReviewIndex]?.order[orderIndex]?.additionalItems;
    const yardage = needReviedData[needReviewIndex]?.order[orderIndex].yardage
      ? needReviedData[needReviewIndex]?.order[orderIndex].yardage
      : 0;
    const prisingTierCapoutPrice = Number(
      needReviedData[needReviewIndex]?.order[orderIndex]?.pricingTierData
        .capoutPrice
    )
      ? Number(
          needReviedData[needReviewIndex]?.order[orderIndex]?.pricingTierData
            .capoutPrice
        )
      : 0;
    const prisingTierMinimumPrice = Number(
      needReviedData[needReviewIndex]?.order[orderIndex]?.pricingTierData
        .minimumPrice
    )
      ? Number(
          needReviedData[needReviewIndex]?.order[orderIndex]?.pricingTierData
            .minimumPrice
        )
      : 0;
    const company = needReviedData[needReviewIndex].company[0];
    if (
      needReviedData[needReviewIndex].order[orderIndex].dumpRateType.indexOf(
        "Yard"
      ) !== -1
    ) {
      showYardageValue = true;
    }
    if (
      needReviedData[needReviewIndex].order[orderIndex].dumpRateType.indexOf(
        "Yard"
      ) === -1
    ) {
      showYardageValue = false;
    }

    // const { order, showYardageValue, user, selectPaymentMethod, additionalItems } = this.state;

    const netWeight =
      _.get(order, "inboundWeight", "") -
      _.get(order, "outboundWeight", "");
    const tonnage = needReviedData[needReviewIndex].order[orderIndex].tonnage;
    const dumpRate = needReviedData[needReviewIndex]?.order[orderIndex]
      ?.dumpRate
      ? this.round2decimal(
          needReviedData[needReviewIndex]?.order[orderIndex]?.dumpRate
        )
      : 0;
    //New Calculation
    let _total = showYardageValue
      ? this.round2decimal(
          this.round2decimal(dumpRate) * this.round2decimal(yardage)
        )
      : this.round2decimal(
          this.round2decimal(dumpRate) * this.round2decimal(tonnage)
        );
    let environmentalLoadFeeAmount = 0;
    let isEnvironmentalLoadFee = false;
    let isFuelSurchardeFee = false;
    let fuelSurchardeFeeAmount = 0;
    let fuelSurchargeTotal = 0;
    
    let capoutPrice = 0;
    let tonnageCap = 0;
    let tonnageDifference = 0;
    let isCapped = "";
    let isMinimumPrice = false
    if (showYardageValue) {
      capoutPrice = this.round2decimal(
        this.round2decimal(yardage) * this.round2decimal(prisingTierCapoutPrice)
      );
      tonnageCap = this.round2decimal(
        this.round2decimal(capoutPrice) / this.round2decimal(dumpRate)
      );
      tonnageDifference = this.round2decimal(
        this.round2decimal(tonnageCap) - this.round2decimal(tonnage)
      );
      isCapped = prisingTierCapoutPrice > 0 && prisingTierCapoutPrice < dumpRate ?  "Yes" : "No";
      _total =
        isCapped === "Yes" &&
        // _.get(order, "customer.isCapoutPrice", false)
        prisingTierCapoutPrice > 0
          ? this.round2decimal(capoutPrice)
          : _total;
    }
    isMinimumPrice =  prisingTierMinimumPrice !== 0 &&  prisingTierMinimumPrice > _total ? true  :false
    console.log("Total before minimum",_total,capoutPrice)
    _total =
      prisingTierMinimumPrice !== 0
        ? prisingTierMinimumPrice < _total
          ? this.round2decimal(_total)
          : this.round2decimal(prisingTierMinimumPrice)
        : this.round2decimal(_total);
    _total = isNaN(_total) ? 0 : this.round2decimal(_total);
    console.log("Total",_total)

    for (let i = 0; i < additionalItems.length; i++) {
      if (additionalItems[i].quantity) {
        _total += this.round2decimal(
          this.round2decimal(additionalItems[i].value) *
            this.round2decimal(additionalItems[i].quantity)
        );
      }
    }
    if (_.get(company, "isEnvironmentalLoadFee", false)) {
      isEnvironmentalLoadFee = _.get(company, "isEnvironmentalLoadFee", false);
      environmentalLoadFeeAmount = _.get(
        company,
        "environmentalLoadFeeAmount",
        0
      );
      _total = this.round2decimal(
        this.round2decimal(_total) +
          this.round2decimal(environmentalLoadFeeAmount)
      );
    }
    // When minimum amount is greater than the actual
    if (_.get(company, "isFuelSurchardeFee", false)) {
      isFuelSurchardeFee = _.get(company, "isFuelSurchardeFee", false);
      fuelSurchardeFeeAmount = 
        _.get(company, "fuelSurchardeFeeAmount", 0)
      
      fuelSurchargeTotal = this.round2decimal(
        fuelSurchardeFeeAmount / 100 *
          this.round2decimal(_total)
      );
      this.setState({
        fuelSurchargeTotal: this.round2decimal(fuelSurchargeTotal),
      });
      _total =
        this.round2decimal(_total) + this.round2decimal(fuelSurchargeTotal);
    }
    
    const _totalActualAmount = isNaN(_total) ? 0 : this.round2decimal(_total);
    let totalWithFee = { amount: 0, fee: 0, total: _total };
    let totalActualAmountWithFee = {
      amount: 0,
      fee: 0,
      total: this.round2decimal(_totalActualAmount),
    };
    // if (_.get(company, "chargeStripeCardFees", false) && selectPaymentMethod === "credit-card") {
    //   totalWithFee = this.calcFee(_total, "USD");
    //   totalActualAmountWithFee = this.calcFee(_totalActualAmount, "USD");
    // }

    needReviedData[needReviewIndex].order[orderIndex].netWeight = netWeight;
    needReviedData[needReviewIndex].order[orderIndex].tonnage = tonnage;
    needReviedData[needReviewIndex].order[orderIndex].dumpRate = dumpRate;
    needReviedData[needReviewIndex].order[orderIndex].isMinimumPrice = isMinimumPrice;
    needReviedData[needReviewIndex].order[orderIndex].totalAmount = isNaN(
      totalWithFee.total
    )
      ? 0
      : totalWithFee.total;
    needReviedData[needReviewIndex].order[orderIndex].capoutPrice = isNaN(
      capoutPrice
    )
      ? 0
      : capoutPrice;
    needReviedData[needReviewIndex].order[orderIndex].tonnageCap = isNaN(
      tonnageCap
    )
      ? 0
      : tonnageCap;
    needReviedData[needReviewIndex].order[orderIndex].tonnageDifference = isNaN(
      tonnageDifference
    )
      ? 0
      : tonnageDifference;
    needReviedData[needReviewIndex].order[orderIndex].isEnvironmentalLoadFee =
      isEnvironmentalLoadFee;
    needReviedData[needReviewIndex].order[
      orderIndex
    ].environmentalLoadFeeAmount = environmentalLoadFeeAmount;
    needReviedData[needReviewIndex].order[orderIndex].isFuelSurchardeFee =
      isFuelSurchardeFee;
    needReviedData[needReviewIndex].order[orderIndex].fuelSurchardeFeeAmount =
      fuelSurchardeFeeAmount;
    needReviedData[needReviewIndex].order[orderIndex].fuelSurchargeTotal =
      fuelSurchargeTotal;
    let totalOrdersAmount = 0;
    let totalOrignalAmount = 0;
    if (needReviedData[needReviewIndex]?.order.length > 0) {
      needReviedData[needReviewIndex].order.map((item) => {
        totalOrdersAmount = totalOrdersAmount + item?.totalAmount;
        if (item.orderCreationType === "createCharge") {
          totalOrignalAmount =
            totalOrignalAmount + item?.orderBreakDownItems?.totalOrderAmout;
        } else {
          totalOrignalAmount = totalOrignalAmount + item.totalAmount;
        }
        return null;
      });
    }
    needReviedData[needReviewIndex].totalOrdersAmount = totalOrdersAmount;
    needReviedData[needReviewIndex].totalOrignalAmount = totalOrignalAmount;
    this.setState({
      needReviedData: needReviedData,
      reRender: !this.state.reRender,
    });

    return {
      yardage,
      netWeight,
      tonnage,
      dumpRate,
      totalActualAmount: isNaN(totalActualAmountWithFee.total)
        ? 0
        : totalActualAmountWithFee.total,
      total: isNaN(totalWithFee.total) ? 0 : totalWithFee.total,
      capoutPrice: isNaN(capoutPrice) ? 0 : capoutPrice,
      tonnageCap: isNaN(tonnageCap) ? 0 : tonnageCap,
      tonnageDifference: isNaN(tonnageDifference) ? 0 : tonnageDifference,
      isCapped,
      stripeFeeOnTotal: totalWithFee,
      stripeFeeOnTotalActualAmount: totalActualAmountWithFee,
      isEnvironmentalLoadFee,
      environmentalLoadFeeAmount,
      isFuelSurchardeFee,
      fuelSurchardeFeeAmount,
      fuelSurchargeTotal,
      isMinimumPrice
    };
  }

  calculateTotalOrderAmount = async (needReviewIndex, orderIndex) => {
    const needReviedData = this.state.needReviedData;
    let addSelectedFixedItems = [];
    let addSelectedChargeByWeight = [];
    let pricingTier = {};
    addSelectedFixedItems =
      needReviedData[needReviewIndex]?.order[orderIndex]?.orderBreakDownItems
        ?.fixedItem;
    addSelectedChargeByWeight =
      needReviedData[needReviewIndex]?.order[orderIndex]?.orderBreakDownItems
        ?.chargeByWeight;
    pricingTier =
      needReviedData[needReviewIndex]?.order[orderIndex]?.pricingTierData;

    let totalChargeByWeight = 0;
    let totalunitPrice = 0;
    let totalUnits = 0;
    let totalWithOutYardPrice = 0;
    let totalWithYardPrice = 0;
    let totalCapoutPrice = 0;
    let totalfixedItemAmout = 0;
    let totalOrderAmout = 0;
    let minimumPrice = 0;
    let totalAmountDue = 0;
    let totalTonnageUnit = 0;
    let capPrice = 0;
    let isminimumPrice = false;
    let isCapOutPrice = false;
    let envLoadFee = 0;
    let fuelSurchargeFee = 0
    console.log(minimumPrice);
    isCapOutPrice = false;
    addSelectedFixedItems.map((item) => {
      totalfixedItemAmout = totalfixedItemAmout + item.unitPrice * item.units;
      let yerdArray = item?.fixedItem.split(" ");
      if (yerdArray[2] === "Yard") {
        if (
          Number(pricingTier.capoutPrice) < item.unitPrice &&
          Number(pricingTier.capoutPrice) > 0
        ) {
          capPrice = capPrice + pricingTier.capoutPrice * item.units;
          isCapOutPrice = true;
          this.setState({ isCapOutPrice: true });
        } else {
          totalWithYardPrice =
            totalWithYardPrice + Number(item.unitPrice) * Number(item.units);
        }
        totalunitPrice = totalunitPrice + Number(item.unitPrice);
        totalUnits = totalUnits + Number(item.units);
      } else {
        totalWithOutYardPrice =
          totalWithOutYardPrice + Number(item.unitPrice) * Number(item.units);
      }
      return null;
    });

    addSelectedChargeByWeight.map((item) => {
      totalChargeByWeight =
        totalChargeByWeight + Number(item.unitPrice * item.units);
      totalTonnageUnit = totalTonnageUnit + Number(item.units);
      return null;
    });

    totalOrderAmout = totalfixedItemAmout + totalChargeByWeight;
    totalCapoutPrice =
      totalChargeByWeight +
      totalWithOutYardPrice +
      capPrice +
      totalWithYardPrice;
      let totalTonAndYard = totalChargeByWeight + capPrice +totalWithYardPrice
      if(totalTonAndYard < Number(pricingTier.minimumPrice)){
        totalAmountDue= Number(pricingTier.minimumPrice)+Number(totalWithOutYardPrice)
        isminimumPrice=true
      }else{
        isminimumPrice=false
        totalAmountDue = isCapOutPrice ? totalCapoutPrice : totalOrderAmout
      }
    // if ( pricingTier.capoutPrice > 0) {
    //   // totalCapoutPrice = totalChargeByWeight + totalWithOutYardPrice + Number(pricingTier.capoutPrice) * totalUnits
    //   totalCapoutPrice = totalChargeByWeight + totalWithOutYardPrice + capPrice +totalWithYardPrice
    //   // this.setState({ isCapOutPrice: true })
    // } else {
    //   this.setState({ isCapOutPrice: false })
    // }
    if(_.get(needReviedData[needReviewIndex].company[0],"isEnvironmentalLoadFee",true)){
      // totalAmountDue =isminimumPrice ? totalAmountDue :  totalAmountDue + needReviedData[needReviewIndex].company[0].environmentalLoadFeeAmount
      needReviedData[needReviewIndex].order[
        orderIndex
      ].environmentalLoadFeeAmount = needReviedData[needReviewIndex].company[0].environmentalLoadFeeAmount
   envLoadFee = needReviedData[needReviewIndex].company[0].environmentalLoadFeeAmount
   totalOrderAmout+= envLoadFee
    }
    if(_.get(needReviedData[needReviewIndex].company[0],"isFuelSurchardeFee",true)){
      const fuelSurchargeAmount = this.round2decimal(( isminimumPrice ? totalAmountDue+ envLoadFee : isCapOutPrice ? totalCapoutPrice + envLoadFee :totalOrderAmout) * (_.get(needReviedData[needReviewIndex].company[0],"fuelSurchardeFeeAmount") / 100))
      const fuelSurchargeForTotal =this.round2decimal(( totalOrderAmout) * (_.get(needReviedData[needReviewIndex].company[0],"fuelSurchardeFeeAmount") / 100))
      totalOrderAmout = fuelSurchargeForTotal+totalOrderAmout
      // totalAmountDue = isminimumPrice ? totalAmountDue : totalAmountDue + fuelSurchargeAmount
      fuelSurchargeFee = fuelSurchargeAmount
      needReviedData[needReviewIndex].order[
        orderIndex
      ].orderBreakDownItems.fuelSurchargeAmmount = fuelSurchargeAmount
      needReviedData[needReviewIndex].order[
        orderIndex
      ].fuelSurchardeFeeAmount = fuelSurchargeAmount
    }
    totalAmountDue+=fuelSurchargeFee+envLoadFee
    // if (isCapOutPrice) {
    //   const totalAmm = totalCapoutPrice + envLoadFee + fuelSurchargeFee
    //   if (
    //     totalAmm < Number(pricingTier.minimumPrice) &&
    //     Number(pricingTier.minimumPrice) > 0
    //   ) {
    //     minimumPrice = totalAmm;
    //     totalAmountDue = Number(pricingTier.minimumPrice);
    //     isminimumPrice = true;
    //   } else {
    //     isminimumPrice = false;
    //     totalAmountDue = totalAmm;
    //   }
    // } else {
    //   const totalAmm = totalOrderAmout
    //   if (
    //     totalAmm < pricingTier.minimumPrice &&
    //     Number(pricingTier.minimumPrice) > 0
    //   ) {
    //     minimumPrice = totalAmm;
    //     totalAmountDue = Number(pricingTier.minimumPrice);
    //     isminimumPrice = true;
    //   } else {
    //     isminimumPrice = false;
    //     totalAmountDue = totalAmm;
    //   }
    // }
    needReviedData[needReviewIndex].order[
      orderIndex
    ].orderBreakDownItems.totalAmountDue = totalAmountDue;
    needReviedData[needReviewIndex].order[
      orderIndex
    ].orderBreakDownItems.isCapOutPrice = isCapOutPrice;
    needReviedData[needReviewIndex].order[
      orderIndex
    ].orderBreakDownItems.isminimumPrice = isminimumPrice;
    needReviedData[needReviewIndex].order[
      orderIndex
    ].orderBreakDownItems.minimumPrice = pricingTier?.minimumPrice;
    needReviedData[needReviewIndex].order[
      orderIndex
    ].orderBreakDownItems.capoutPrice = pricingTier?.capoutPrice;
    needReviedData[needReviewIndex].order[
      orderIndex
    ].orderBreakDownItems.totalCapoutPrice = totalCapoutPrice;
    needReviedData[needReviewIndex].order[
      orderIndex
    ].orderBreakDownItems.totalOrderAmout = totalOrderAmout;
    needReviedData[needReviewIndex].order[orderIndex].tonnage =
      totalTonnageUnit;
    needReviedData[needReviewIndex].order[orderIndex].yardage = totalUnits;
    needReviedData[needReviewIndex].order[orderIndex].totalAmount =
      totalAmountDue;
    let totalOrdersAmount = 0;
    let totalOrignalAmount = 0;
    if (needReviedData[needReviewIndex]?.order.length > 0) {
      needReviedData[needReviewIndex].order.map((item) => {
        totalOrdersAmount = totalOrdersAmount + item?.totalAmount;
        if (item.orderCreationType === "createCharge") {
          totalOrignalAmount =
            totalOrignalAmount + item?.orderBreakDownItems?.totalOrderAmout;
        } else {
          totalOrignalAmount = totalOrignalAmount + item.totalAmount;
        }
        return null;
      });
    }
    needReviedData[needReviewIndex].totalOrdersAmount = totalOrdersAmount;
    needReviedData[needReviewIndex].totalOrignalAmount = totalOrignalAmount;
    this.setState({
      needReviedData: needReviedData,
      reRender: !this.state.reRender,
    });
    // this.setState({ totalMinimumPrice: minimumPrice, totalCapoutPrice: totalCapoutPrice, totalOrderAmout: totalOrderAmout, totalAmountDue: totalAmountDue,totalYardageunit:totalUnits,totalTonnageUnit:totalTonnageUnit})
    //   this.props.totalAmountDue(totalAmountDue)
    //   this.props.yardage(totalUnits)
    //   this.props.tonnage(totalTonnageUnit)
    //   this.props.assignFixedItem(this.state.addSelectedFixedItems, this.state.addSelectedChargeByWeight)
    //    const payalod={
    //     fixedItem:addSelectedFixedItems,
    //     chargeByWeight:addSelectedChargeByWeight,
    //     isCapOutPrice:isCapOutPrice,
    //     isminimumPrice:isminimumPrice,
    //     minimumPrice:pricingTier.minimumPrice,
    //     capoutPrice:pricingTier.capoutPrice,
    //     totalAmountDue:totalAmountDue,
    //     totalCapoutPrice:totalCapoutPrice,
    //     totalOrderAmout:totalOrderAmout,
    //    }
    // this.props.orderDetails(payalod)
  };

  assignFixedItems = async (pricingTier, index, needReviewindexs, item) => {
    const chargeByWeight = [];
    const fixedItem = [];
    const additionalItem = [];
    const fixedItemsArray = []
    const chargeByWeightArray = []
    for (const key in pricingTier.dumpRates) {
      if (pricingTier?.dumpRates.hasOwnProperty(key)) {
          const label = _.startCase(key);
          if (key.includes("pricePerYard")) {
            fixedItemsArray.push({
              value: pricingTier?.dumpRates[key],
              label: label
            })
          } else if (key.includes("pricePerTon")) {
            chargeByWeightArray.push({
              value: pricingTier?.dumpRates[key],
              label: label
            })
          }
      }
    }

    if (item?.orderCreationType === "createCharge") {
      const needReviedData = this.state.needReviedData;
      fixedItemsArray.map((item) => {
        if (Number(item.value) > 0) {
          fixedItem.push(item);
        }
        return null;
      });
      pricingTier.additionalItems.map((item) => {
        let obj = {};
        if (Number(item.value) > 0) {
          obj["value"] = item.value;
          obj["label"] = item.label;
          obj["dumpRateType"]="fixedItem"
          fixedItem.push(obj);
        }
        return null;
      });

      chargeByWeightArray.map((item) => {
        if (Number(item.value) > 0) {
          chargeByWeight.push(item);
        }
        return null;
      });
      const localPricingTierData = {
        fixedItem: fixedItem,
        chargeByWeightArray: chargeByWeight,
        capoutPrice: pricingTier?.capoutPrice,
        minimumPrice: pricingTier?.minimumPrice,
        isActive: pricingTier?.isActive,
      };
      needReviedData[needReviewindexs].order[index].pricingTierData =
        localPricingTierData;
      this.setState({
        needReviedData: needReviedData,
        reRender: !this.state.reRender,
      });
    } else {
      const needReviedData = this.state.needReviedData;
      const fixedItem = {};
      Object.keys(pricingTier?.dumpRates).map((item) => {
        if (
          Number(pricingTier?.dumpRates[item]) > 0 &&
          item !== "defaultOption" &&
          item !== "capoutPrice" &&
          item !== "minimumPrice"
        ) {
          fixedItem[`${item}`] = pricingTier?.dumpRates[item];
        }
        return null;
      });
      pricingTier.additionalItems.map((item) => {
        let obj = {};
        if (Number(item.value) > 0) {
          obj["value"] = item.value;
          obj["label"] = item.label;
          obj["quantity"] = item.quantity;
          additionalItem.push(obj);
        }
        return null;
      });
      const localPricingTierData = {
        fixedItem: fixedItem,
        chargeByWeightArray: chargeByWeight,
        additionalItem: additionalItem,
        capoutPrice: pricingTier?.capoutPrice,
        minimumPrice: pricingTier?.minimumPrice,
        isActive: pricingTier?.isActive,
      };
      needReviedData[needReviewindexs].order[index].pricingTierData =
        localPricingTierData;
      needReviedData[needReviewindexs].order[index].pricingTierData =
        localPricingTierData;
      this.setState({
        needReviedData: needReviedData,
        reRender: !this.state.reRender,
      });
    }

    const needReviedData = this.state.needReviedData;
    let totalOrdersAmount = 0;
    let totalOrignalAmount = 0;
    if (needReviedData[needReviewindexs]?.order.length > 0) {
      needReviedData[needReviewindexs].order.map((item) => {
        totalOrdersAmount = totalOrdersAmount + item?.totalAmount;
        if (item.orderCreationType === "createCharge") {
          totalOrignalAmount =
            totalOrignalAmount + item?.orderBreakDownItems?.totalOrderAmout;
        } else {
          totalOrignalAmount = totalOrignalAmount + item.totalAmount;
        }
        return null;
      });
    }

    needReviedData[needReviewindexs].totalOrdersAmount = totalOrdersAmount;
    needReviedData[needReviewindexs].totalOrignalAmount = totalOrignalAmount;
    // needReviedData[needReviewindexs].totalOrdersAmount=totalOrdersAmount
    this.setState({
      needReviedData: needReviedData,
      reRender: !this.state.reRender,
    });
    // this.setState({ fixedItemsArray: fixedItem });
    // this.setState({ chargeByweightItemsArray: chargeByWeight });
  };

  fetchPricingTierById = async (order, needReviewindexs) => {
    if (order.length > 0) {
      order.map(async (item, index) => {
        const {
          value: {
            data: { pricing },
          },
        } = await this.props.getPricingTierById(item.pricingTierId);
        this.assignFixedItems(pricing, index, needReviewindexs, item);
        return pricing;
      });
    }
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidUpdate = async () => {
    let startDate = this.props.date.startDate;
    let endDate = this.props.date.endDate;
    if (this.state.startDate !== startDate || this.state.endDate !== endDate) {
      this.setState({ startDate: startDate, endDate: endDate });
      // let searchText = "";
      const { searchText } = this.state;
      this.fetchNeedReviewData(searchText, startDate, endDate);
    }
  };

  fetchInvoiceDeliveryData = async () => {
    const {
      value: { data },
    } = await this.props.fetchInvoiceDeliveryData();
    this.setState({ invoiceDeliveryData: data });
  };

  openFilterList = async (value) => {
    const filtersData = this.state.filtersData;
    if (filtersData.includes(value)) {
      var index = filtersData.indexOf(value);
      if (index !== -1) {
        filtersData.splice(index, 1);
      }
    } else {
      filtersData.push(value);
    }
    this.setState(
      { filtersData: filtersData, reRender: !this.state.reRender },
      () => {
        sessionStorage.setItem('need_review_filtersData', JSON.stringify(this.state.filtersData))
        this.fetchNeedReviewData(
          this.state.searchText,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  selectedCheckBox = (data, type, index) => {
    const { selectedCheckBoxData, needReviedData } = this.state;
    if (type === "all") {
      if (
        this.state.needReviedData.length ===
        this.state.selectedCheckBoxData.length
      ) {
        this.setState({ selectedCheckBoxData: [] });
        needReviedData.map((item, index) => {
          needReviedData[index].checkBox = false;
          return null;
        });
        this.setState({
          needReviedData: needReviedData,
          reRender: !this.state.reRender,
        });
      } else {
        needReviedData.map((item, index) => {
          needReviedData[index].checkBox = true;
          if (!selectedCheckBoxData.includes(item._id))
            selectedCheckBoxData.push(item._id);
          return null;
        });
        this.setState({
          selectedCheckBoxData: selectedCheckBoxData,
          reRender: !this.state.reRender,
        });
      }
    } else {
      const arrayIndex = selectedCheckBoxData.indexOf(data._id);
      if (selectedCheckBoxData.includes(data._id)) {
        needReviedData[index].checkBox = data.checkBox ? false : true;
        selectedCheckBoxData.splice(arrayIndex, 1);
      } else {
        needReviedData[index].checkBox = data.checkBox ? false : true;
        selectedCheckBoxData.push(data._id);
      }
      this.setState({
        needReviedData: needReviedData,
        selectedCheckBoxData: selectedCheckBoxData,
        reRender: !this.state.reRender,
      });
    }
  };

  fetchNeedReviewData = async (searchText, startDate, endDate) => {
    // this.setState({isLoading:true})
    let needReviewData = [];
    let needReview = [];
    // startDate=this.props.startDate
    // endDate=this.props.endDate
    // console.log(startDate,endDate)
    const payload = {
      searchText: searchText,
      startDate: moment(startDate)?.startOf("day")?.toISOString(),
      endDate: moment(endDate)?.endOf("day")?.toISOString(),
      filterList: this.state.filtersData,
      searchStartsWith: this.state.searchStartsWith
    };
    const {
      value: { data },
    } = await this.props.fetchNeedReview(payload);
    needReview = data?.needreview;
    this.setState({invoicesTotal : data?.invoicesTotal})
    // const path = window.location.pathname.split("/")
    //   if(path[1]==="needReview"){
    //  this.setState({isCustomerModelOpen:true,customerId:needReview[0]?.customer?.customerId})
    //   }
      if(needReview[0]?.company[0].sisterCompany){
        const {
          value: { data },
        } = await this.props.fetchSetting(needReview[0]?.company[0].sisterCompany);
        if(data.status){
         this.setState({sisterCompanyData:data,isSisterCompany:true})
        }
      }

    if (needReview.length > 0) {

      for (let needReviewIndex = 0; needReviewIndex < needReview.length; needReviewIndex++) {
        let item = needReview[needReviewIndex];
        let totalOrderAmount = 0;
        let totalOriginalAmount = 0;
      
        for (let orderIndex = 0; orderIndex < item.order.length; orderIndex++) {
          let orderItem = item.order[orderIndex];
          totalOrderAmount += orderItem.totalAmount;
      
          if (orderItem.orderCreationType === "createCharge") {
            let fixedItem = [];
            let chargeByWeight = [];
      
            if (orderItem.orderBreakDownItems.fixedItem.length > 0) {
              for (let i = 0; i < orderItem.orderBreakDownItems.fixedItem.length; i++) {
                fixedItem.push({ ...orderItem.orderBreakDownItems.fixedItem[i], isDeleteAble: false });
              }
            }
      
            if (orderItem.orderBreakDownItems.chargeByWeight.length > 0) {
              for (let i = 0; i < orderItem.orderBreakDownItems.chargeByWeight.length; i++) {
                chargeByWeight.push({ ...orderItem.orderBreakDownItems.chargeByWeight[i], isDeleteAble: false });
              }
            }
      
            needReview[needReviewIndex].order[orderIndex].orderBreakDownItems.fixedItem = fixedItem;
            needReview[needReviewIndex].order[orderIndex].orderBreakDownItems.chargeByWeight = chargeByWeight;
            totalOriginalAmount += orderItem.orderBreakDownItems.totalOrderAmount;
          } else {
            let additionalItems = [];
      
            if (orderItem.additionalItems.length > 0) {
              for (let i = 0; i < orderItem.additionalItems.length; i++) {
                additionalItems.push({ ...orderItem.additionalItems[i], isDeleteAble: false });
              }
            }
      
            totalOriginalAmount += orderItem.totalAmount;
            needReview[needReviewIndex].order[orderIndex].additionalItems = additionalItems;
          }
        }
      
        needReviewData.push({
          ...item,
          showDetail: false,
          totalOrdersAmount: totalOrderAmount,
          totalOriginalAmount: totalOriginalAmount,
          showAttachments: [],
          customAttachments: [],
          checkBox: false,
        });
      }

      // needReview.map((item, needReviewIndex) => {
      //   let totalOrderAmout = 0;
      //   let totalOrignalAmount = 0;
      //   item.order.map((item, orderIndex) => {
      //     totalOrderAmout = totalOrderAmout + item.totalAmount;
      //     if (item?.orderCreationType === "createCharge") {
      //       let fixedItem = [];
      //       let chargeByWeight = [];
      //       if (item.orderBreakDownItems.fixedItem.length > 0) {
      //         item.orderBreakDownItems.fixedItem.map((fixedItems) => {
      //           fixedItem.push({ ...fixedItems, isDeleteAble: false });
      //           return null;
      //         });
      //       }
      //       if (item?.orderBreakDownItems?.chargeByWeight.length > 0) {
      //         item.orderBreakDownItems.chargeByWeight.map((fixedItems) => {
      //           chargeByWeight.push({ ...fixedItems, isDeleteAble: false });
      //           return null;
      //         });
      //       }
      //       needReview[needReviewIndex].order[
      //         orderIndex
      //       ].orderBreakDownItems.fixedItem = fixedItem;
      //       needReview[needReviewIndex].order[
      //         orderIndex
      //       ].orderBreakDownItems.chargeByWeight = chargeByWeight;
      //       totalOrignalAmount =
      //         totalOrignalAmount + item?.orderBreakDownItems?.totalOrderAmout;
      //     } else {
      //       let additionalItems = [];
      //       if (item?.additionalItems.length > 0) {
      //         item.additionalItems.map((additionalItem) => {
      //           additionalItems.push({
      //             ...additionalItem,
      //             isDeleteAble: false,
      //           });
      //           return null;
      //         });
      //       }
      //       totalOrignalAmount = totalOrignalAmount + item.totalAmount;
      //       needReview[needReviewIndex].order[orderIndex].additionalItems =
      //         additionalItems;
      //     }
      //     return null;
      //   });
      //   needReviewData.push({
      //     ...item,
      //     showDetail: false,
      //     totalOrdersAmount: totalOrderAmout,
      //     totalOrignalAmount: totalOrignalAmount,
      //     showAttachments: [],
      //     customAttachments: [],
      //     checkBox: false,
      //   });
      //   return null;
      // });
    }
    this.setState({ needReviedData: needReviewData,isLoading:false,reRender:!this.state.reRender });
  };

  editMenu = (needReviewIndex) => {
    const needReviedData = this.state.needReviedData;
    needReviedData[needReviewIndex].showDetail = true;
    this.setState({
      needReviedData: needReviedData,
      reRender: !this.state.reRender,
    });
  };

  afterBulkOptionUse = () => {
    let needReviedData = this.state.needReviedData;
    this.setState({ selectedCheckBoxData: [] });
    needReviedData.map((item, index) => {
      needReviedData[index].checkBox = false;
      return null;
    });
    this.setState({
      needReviedData: needReviedData,
      reRender: !this.state.reRender,
      batchAction: "",
    });
  };
  
  bulkOptions = async (event) => {
    this.setState({ batchAction: event.target.value });
    const emailPromise = [];
    const needReviedData = this.state.needReviedData;
    if (event.target.value === "send") {
      this.setState({ spinning: true });
      const filtered_data = needReviedData?.filter(
        (item) => item?.customer?.billingEmailAddress?.length > 0 && item?.checkBox
      );
      needReviedData.map((item) => {
        if (item.checkBox) {
          this.setState({ bulkInvoiceEmailPleaseWait: true });
           if (filtered_data?.length <= 0) {
            this.setState({ showModal: true });
        }
    else{
          const billingEmail = item?.customer?.secondaryBillingContacts ? 
          item?.customer?.secondaryBillingContacts.map((v)=> v.billingEmailAddress)
          :[]
          const payload = {
            company: {
              logo: item?.company[0]?.logo,
              businessInfo: item?.company[0]?.businessInfo,
              email: item?.company[0]?.email,
              domain: item?.company[0]?.domain,
              companyId: item?.company[0]?._id,
              companyName: item?.company[0]?.companyName,
              customerName: item?.customer?.customerName,
              customerId: item?.customer?._id,
              chargeStripeCardFees: item?.company[0].chargeStripeCardFees,

            },
            customer: {
              email: item?.customer.email,
              billingEmailAddress: [item?.customer?.billingEmailAddress,...billingEmail],
              companyName: item?.customer?.companyName,
            },
            invoice: {
              invoiceData: item,
              invoiceId: item?.invoiceId,
              dueDate: item?.dueDate,
              balacnce: item?.total - item?.amountPaid,
              total: item?.total,
              amountPaid: item?.amountPaid,
            },
          };
          emailPromise.push(this.props.sendInvoiceEmail(payload));
          // const {
          //   value: { status },
          // } = this.props.sendInvoiceEmail(payload);
          // if (status) {
          //   this.setState({ bulkInvoiceEmailPleaseWait: false })
          // }
        }

        return null;
      } return null; });
      const sendEmailResponse = await Promise.all(emailPromise);
      if (sendEmailResponse) {
        this.setState({ spinning: false, selectedCheckBoxData: [] });
        this.afterBulkOptionUse();
      }
    } else if (event.target.value === "print") {
      this.setState({ spinning: true });
      //  = postedOrderData.filter((item) => item.checkBox === true);
      const printData = needReviedData.filter((item) => item.checkBox === true);
      const orderedArray = reOrderArray(printData);
      const {value} = await this.props.batchPrintInvoice({
        invoicelist: orderedArray,
      });
      if (value) {
        const url = URL.createObjectURL(value);
        const printWindow = window.open(url, '_blank');
          printWindow.onload = function() {
            printWindow.print();
          };
        
        this.setState({
          needReviedData: needReviedData,
          reRender: !this.state.reRender,
          selectedCheckBoxData: [],
          spinning: false,
        });
        this.afterBulkOptionUse()
      }
     /*  let printWindow = "";
      const printData = needReviedData.filter((item) => item.checkBox === true);
      this.setState({ spinning: true });
      let currentLength = 0;
      let totalLength = printData.length;
      const printPromises = printData.map((items, i) => {
        return new Promise(async (resolve, reject) => {
          let { order } = items;
          const Company = items.company && items.company[0];
          const orderData = this.reArrangOrdersData(sort_by_descending(order, "createdAt"));
          totalLength+=orderData.length;
          items.company = [Company];
          let body1 = "";
          if(this.state.isSisterCompany){
            body1 += PrintSisterCompanyInvoiceReceipt(items,this.state.sisterCompanyData);
          }else{
            body1 += PrintInvoiceReceipt(items);
          }
          currentLength+=1;
          for (let i = 0; i < orderData.length; i++) {
            let ordr = orderData[i];
            const { value } = await this.props.fetchOrder(ordr.ticketNumber);
            order = value.data;
            currentLength+=1; 
            const isLastPromise = currentLength === totalLength
            const last = orderData.length-1 === i && isLastPromise
            if(this.state.isSisterCompany){
              body1 += PrintSisterCompanyOrderReceipt(value,last);
            }else{
              body1 += PrintOrderReceipt(value,last);
            }
          }
          printWindow += body1;
          resolve();
        });
      });
      if(this.state.isSisterCompany){
        printWindow += bottomSisterCompanyContent();
      }else{
        printWindow += bottomContent();
      }
      
      Promise.all(printPromises).then(() => {
        needReviedData.forEach((item) => {
          item.checkBox = false;
        });
      }).finally(()=>{
        let printInvoice = window.open(`invoice`, "_blank");
        if(printInvoice){
        printInvoice.document.write(printWindow);
        printInvoice.document.close(); 
        printInvoice.onload = function () {
          printInvoice.print();
        };
      }
    })
      this.setState({
        needReviedData: needReviedData,
        reRender: !this.state.reRender,
        selectedCheckBoxData: [],
        spinning: false,
      });
      this.afterBulkOptionUse(); */
    } else if (event.target.value === "download") {
      this.setState({ spinning: true });
      const printData = needReviedData.filter((item) => item.checkBox === true);
      const {
        value: { data },
      } = await this.props.downloadBulkInvoice({
        invoicelist: printData,
      });
      needReviedData.map((item, index) => {
        needReviedData[index].checkBox = false;
        return null;
      });
      this.setState({
        needReviedData: needReviedData,
        reRender: !this.state.reRender,
        selectedCheckBoxData: [],
        spinning: false,
      });
      this.afterBulkOptionUse();
      window.open(data);
    } else if (event.target.value === "reminderInvoice") {
      this.setState({ spinning: true });
      const filtered_data = needReviedData?.filter(
        (item) => item?.customer?.billingEmailAddress?.length > 0 && item?.checkBox
      );
      needReviedData.map((item) => {
        if (item.checkBox) {
          this.setState({ bulkInvoiceEmailPleaseWait: true });
          if (filtered_data?.length <= 0) {
            this.setState({ showModal: true });
        }
    else{
          const billingEmail = item?.customer?.secondaryBillingContacts ? 
            item?.customer?.secondaryBillingContacts.map((v)=> v.billingEmailAddress)
           :[]
          const payload = {
            company: {
              logo: item?.company[0]?.logo,
              businessInfo: item?.company[0]?.businessInfo,
              email: item?.company[0]?.email,
              domain: item?.company[0]?.domain,
              companyId: item?.company[0]?._id,
              companyName: item?.company[0]?.companyName,
              customerName: item?.customer?.customerName,
              customerId: item?.customer?._id,
              chargeStripeCardFees: item?.company[0].chargeStripeCardFees
            },
            customer: {
              email: item?.customer.email,
              billingEmailAddress: [item?.customer?.billingEmailAddress,...billingEmail],
              companyName: item?.customer?.companyName,
            },
            invoice: {
              invoiceData: item,
              invoiceId: item?.invoiceId,
              dueDate: item?.dueDate,
              total: item?.total,
              balance: item?.total - item?.amountPaid,
              amountPaid: item?.amountPaid,
            },
          };
          emailPromise.push(this.props.sendReminderInvoiceEmail(payload));
          // const {
          //   value: { status },
          // } = this.props.sendInvoiceEmail(payload);
          // if (status) {
          //   this.setState({ bulkInvoiceEmailPleaseWait: false })
          // }
        }}
        return null;
      });
      const sendEmailResponce = await Promise.all(emailPromise);
      if (sendEmailResponce) {
        this.setState({ spinning: false });
        this.afterBulkOptionUse();
      }
    } else if (event.target.value === "posted"){
      this.setState({ spinning: true });
      const postedData = needReviedData.filter((item) => item.checkBox === true);
      const {
        value: { status },
      } = await this.props.movedInvoiceBulkAction({
        data: postedData, updateType: "post", billingStatus:"posted"
      });
      if(status){
        this.setState({ spinning: false });
        this.afterBulkOptionUse()
        this.fetchNeedReviewData(
          this.state.searchText,
          this.state.startDate,
          this.state.endDate
        );
      }
    }
  };

  review = async (needReviewindex, item) => {
    let updateIsShowDetail = this.state.needReviedData;
    if (!item.showDetail) {
      const updatedUpdateIsShowDetail = [...updateIsShowDetail]; // Create a new copy of the array
      updatedUpdateIsShowDetail[needReviewindex] = {
        ...updatedUpdateIsShowDetail[needReviewindex],
        showDetail: !item?.showDetail,
      };
      await this.fetchPricingTierById(item?.order, needReviewindex);
      this.setState({
        needReviedData: updatedUpdateIsShowDetail,
        reRender: !this.state.reRender,
      });
    } else {
      this.fetchNeedReviewData(
        this.state.searchText,
        this.state.startDate,
        this.state.endDate
      );
    }
  };

  cancleAttachments = (needReviewIndex, attachmentsIndex, type) => {
    const needReviedData = this.state.needReviedData;
    if (type === "FromDB") {
      needReviedData[needReviewIndex].attachments.splice(attachmentsIndex, 1);
    } else {
      needReviedData[needReviewIndex].showAttachments.splice(
        attachmentsIndex,
        1
      );
      needReviedData[needReviewIndex].customAttachments.splice(
        attachmentsIndex,
        1
      );
    }
    this.setState({
      needReviedData: needReviedData,
      reRender: !this.state.reRender,
    });
  };

  menu = (index, item) => {
    const { user } = this.props;
    return (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() => {
            this.modelOpen(index);
          }}
        >
          View
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => {
            this.review(index, item);
          }}
        >
          Edit
        </Menu.Item>
        {(user?.permissions?.permissionOptions && user?.permissions?.permissionOptions?.voidInvoices === true) ?
         <Menu.Item
          onClick={() => {
            this.updateInvoiceDetail(index, "post", "finalized");
          }}
          key="2"
        >
          Void
        </Menu.Item>:null}
        <Menu.Item
          key="3"
          onClick={() => {
            this.updateInvoiceDetail(index, "post", "posted");
          }}
        >
          Post
        </Menu.Item>
        {/* <Menu.Item
          onClick={() => {
            this.props.navigate(
              `/needReview/receive-payment/${this.state.needReviedData[index].customerId}`
            );
          }}
          key="4"
        >
          Receive Payment
        </Menu.Item> */}
        <Menu.Item
          onClick={() => {
            this.openBillingReciept(this.state.needReviedData[index]);
          }}
          key="4"
        >
          Print
        </Menu.Item>
      </Menu>
    );
  };

  changeLimit = async (e) => {
    this.setState(
      { limit: e.target.value === "results" ? 20 : Number(e.target.value) },
      () => {
        this.fetchNeedReviewData(
          this.state.searchText,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
    await localStorage.setItem(
      "needReviewLimit",
      e.target.value === "results" ? "20" : e.target.value
    );
  };

  render() {
    const { searchText, startDate, endDate, needReviedData } = this.state;
    return (
      <div className="need__review-wrapper">
        <section className="table__filter-section">
        <>
          <div className="table__filter-inner">
            <div className="filter-form">
              <DebounceInput
                type="text"
                value={this.state.searchText.trimStart()}
                onChange={(e) => {
                  this.props.handleSearchText(e.target.value.trimStart(), this.state.searchStartsWith)
                  this.setState({
                   searchText: e.target.value.trimStart(),
                  //  needReviedData: [],
                   isLoading: this.state.searchText.trimStart()!=="" && needReviedData.length === 0
                  }, () => {
                    const func = () => {
                      (this.state.searchText.length === 0 || e?.target?.value?.length === 0 || e?.target?.value?.trim().length) && this.fetchNeedReviewData(
                        this.state.searchText?.trim(),
                        this.state.startDate,
                        this.state.endDate
                        );
                    }
                   func()
                  });
                }}
                placeholder="Search by company name or invoice #... "
                className="form-control"
                minLength={2}
                debounceTimeout={1000}
              />
              <button
                onClick={() => {
                  this.setState({ visible: true });
                }}
                className="btn btn-dark btn-sm btn-outline-black filter-btn"
              >
                {/* <img className="icon" src={filterIcon} alt="" /> */}
                <FilterIcon />
                Filter{" "}
                {this.state.filtersData.length > 0 &&
                  this.state.filtersData.length}
              </button>
            </div>
            <div className="d-flex align-items-center w-auto">
              <div className="custom-select-spiner ml-2">
                {this.state.spinning && (
                  <Spin indicator={antIcon} spinning={true} />
                )}
                <select
                  className="form-control custom-select material-textfield-input batch-action-btn"
                  name="dateType"
                  required
                  value={this.state.batchAction}
                  onChange={(e) => {
                    this.bulkOptions(e);
                  }}
                  disabled={!this.state.selectedCheckBoxData.length > 0}
                >
                  {!this.state.spinning && (
                    <>
                      <option value="">Batch Action</option>
                      <option value="send">Send</option>
                      <option value="print">Print</option>
                      <option value="download">Download</option>
                      <option value="reminderInvoice">Reminder invoices</option>
                      <option value="posted">Move to Posted</option>
                    </>
                  )}
                </select>
              </div>
            
              <select
                className="form-control custom-select material-textfield-input batch-action-btn result-select ml-2"
                value={this.state.limit.toString()}
                onChange={(e) => {
                  this.changeLimit(e);
                }}
              >
                <option value="results">Results</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          {this.state.showModal && <EmailAlert
      isClose={this.state?.showModal}
       showEmailAlert={this.state.showModal}
        onCloseAlert={()=> {
          this.closeModalAlert()
          }}
         />}
          </>
        </section>
        <SearchCheckboxComponent
          searchStartsWith={this.state.searchStartsWith}
          handleSearchStartsWith={(value) => this.setState({ searchStartsWith: value }, () => {
            this.state.searchText!== "" && this.fetchNeedReviewData(this.state.searchText?.trim(), this.state.startDate, this.state.endDate)
            this.props.handleSearchText(this.state.searchText, this.state.searchStartsWith)
          })
        }
        />
        <section className="table__section">
          <div className="card">
            <div className="table-responsive">
            
              <table className="table custom-table fixTableHead">
                <thead>
                  <tr>
                    <th className="first-child">
                      <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                        <input
                          checked={
                            this.state.selectedCheckBoxData.length > 0 &&
                            this.state.needReviedData.length ===
                              this.state.selectedCheckBoxData.length
                          }
                          onChange={() => {
                            this.selectedCheckBox(
                              this.state.needReviedData,
                              "all"
                            );
                          }}
                          type="checkbox"
                          className="custom-checkbox--input"
                        />
                        <span className="checkmark"></span>
                      </label>
                      Customer
                    </th>
                    <th>Invoice #</th>
                    <th>Type</th>
                    <th>Invoice Date</th>
                    <th>Due Date</th>
                    <th>Balance</th>
                    <th>Total</th>
                    <th>Transaction Frequency</th>
                    <th>Invoice Delivery</th>
                    <th className={this.state.actionClass ? "pr-40 " : ""}>
                      Action
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {!this.state.isLoading ? this.state.needReviedData.length > 0 ? (
                    this.state.needReviedData
                      .slice(0, this.state.limit)
                      .map((item, needReviewindex) => (
                        <>
                          <tr className={item?.showDetail ? "active__tr" : ""}>
                            <td className="first-child underline">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox underline">
                                <input
                                  checked={item.checkBox}
                                  onChange={() => {
                                    this.selectedCheckBox(
                                      item,
                                      "single",
                                      needReviewindex
                                    );
                                  }}
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <span
                                onClick={() => {
                                  this.setState(
                                    {
                                      isCustomerModelOpen: true,
                                      customerId: item?.customer?.customerId,
                                    },
                                    () => {
                                      this.props.navigate(
                                        `/needReview/customer-detail/${item?.customer?.customerId}/general`
                                      );
                                    }
                                  );
                                }}
                              >
                                {item?.customer?.displayName !== ""
                                  ? item?.customer?.displayName
                                  : "N/A"}
                              </span>
                            </td>
                            <td
                              onClick={() => {
                                this.modelOpen(needReviewindex);
                              }}
                              className="underline"
                            >
                              {item?.invoiceId}
                            </td>
                            <td
                              onClick={() => {
                                this.review(needReviewindex, item);
                              }}
                              className="cursor-pointer"
                            >
                              {item?.invoiceType.charAt(0).toUpperCase() +
                                item?.invoiceType.slice(1)}
                            </td>
                            <td
                              onClick={() => {
                                this.review(needReviewindex, item);
                              }}
                              className="cursor-pointer"
                            >
                              {moment(item?.invoiceDate)?.tz(this.state?.tz)?.format("L")}
                            </td>
                            <td
                              onClick={() => {
                                this.review(needReviewindex, item);
                              }}
                              className="cursor-pointer"
                            >
                              {moment(item?.dueDate)?.tz(this.state?.tz)?.format("L")}
                            </td>
                            <td
                              onClick={() => {
                                this.review(needReviewindex, item);
                              }}
                              className="cursor-pointer"
                            >
                              {Number(item?.total - item?.amountPaid)
                                ? "$" +
                                  this.numberWithCommas(
                                    Number(
                                      Number(item?.total - item?.amountPaid)
                                    )
                                  )
                                : "-"}
                            </td>
                            <td
                              onClick={() => {
                                this.review(needReviewindex, item);
                              }}
                              className="cursor-pointer"
                            >
                              $
                              {item?.totalOrdersAmount
                                ? this.numberWithCommas(Number(item?.total))
                                : "-"}
                            </td>
                            <td
                              onClick={() => {
                                this.review(needReviewindex, item);
                              }}
                              className="cursor-pointer"
                            >
                              {item?.invoiceFrequency === "auto"
                                ? "Each Transaction"
                                : item?.invoiceFrequency
                                    .charAt(0)
                                    .toUpperCase() +
                                  item?.invoiceFrequency.slice(1)}
                            </td>
                            <td
                              onClick={() => {
                                this.review(needReviewindex, item);
                              }}
                              className="cursor-pointer"
                            >
                              {item?.invoiceDelivery}
                            </td>
                            <td
                              className={
                                item?.showDetail
                                  ? "last-child- text-center"
                                  : "last-child-"
                              }
                            >
                              <button
                                onClick={() => {
                                  this.review(needReviewindex, item);
                                  this.setState({ actionClass: true });
                                }}
                                className={
                                  item?.showDetail
                                    ? "btn btn-outline-black btn-small"
                                    : "btn btn-dark btn-small"
                                }
                              >
                                {" "}
                                {item?.showDetail ? "Cancel" : "Review"}
                              </button>
                            </td>
                            {  item.showDetail && <td>&nbsp;</td>}
                          {!item?.showDetail && (
                          <td className="sticky-block">
                           
                              <Dropdown
                                overlay={() => this.menu(needReviewindex, item)}
                                trigger={["click"]}
                                overlayClassName="profile--dropdown--overlay dots-dropdown_link"
                              >
                                <button className="btn ant-dropdown-link pl-14- pr-6- three-dot">
                                  <MoreIcon />
                                </button>
                              </Dropdown>
                          </td>
                           )}
                          </tr>
                          {item?.showDetail && (
                            <tr
                              className={
                                item?.showDetail
                                  ? "collapse__tr open"
                                  : "collapse__tr"
                              }
                            >
                              <td colSpan={11}>
                                <table className="table invoice__table">
                                  <tbody>
                                    {item?.order?.length &&
                                      item?.order.map(
                                        (Orderitems, OrderitemIndex) => (
                                          <>
                                            {Orderitems?.orderCreationType ===
                                            "createCharge" ? (
                                              <tr>
                                                <td
                                                  className="lp-10 tp-10"
                                                  colSpan="8"
                                                >
                                                  <table className="table custom-table layout-not-fix pricing-table">
                                                    <thead>
                                                      <th>Ticket #</th>
                                                      <th>Service</th>
                                                      <th>Unit Price</th>
                                                      <th>Qty</th>
                                                      <th>Amount</th>
                                                    </thead>
                                                    <tbody>
                                                      {Orderitems
                                                        ?.orderBreakDownItems
                                                        ?.fixedItem.length >
                                                        0 &&
                                                        Orderitems?.orderBreakDownItems?.fixedItem.map(
                                                          (
                                                            orderBreakDownFixedItems,
                                                            index
                                                          ) => (
                                                            <>
                                                              <tr>
                                                                {index ===
                                                                Number(0) ? (
                                                                  <td
                                                                    className="cursor-pointer underline"
                                                                    onClick={() => {
                                                                      this.props.navigate(
                                                                        `/order-details/${Orderitems?.ticketNumber}`
                                                                      );
                                                                    }}
                                                                  >
                                                                    {
                                                                      Orderitems?.ticketNumber
                                                                    }
                                                                  </td>
                                                                ) : (
                                                                  <td>
                                                                    &nbsp;
                                                                  </td>
                                                                )}
                                                                <td className="position-relative d-flex align-items-center">
                                                                  {orderBreakDownFixedItems?.isDeleteAble ? (
                                                                    <img
                                                                      className="delete-icon"
                                                                      onClick={() => {
                                                                        const fixedItemArray =
                                                                          needReviedData;
                                                                        fixedItemArray[
                                                                          needReviewindex
                                                                        ].order[
                                                                          OrderitemIndex
                                                                        ].orderBreakDownItems.fixedItem.splice(
                                                                          index,
                                                                          1
                                                                        );
                                                                        this.setState(
                                                                          {
                                                                            needReviedData:
                                                                              fixedItemArray,
                                                                            reRender:
                                                                              !this
                                                                                .state
                                                                                .reRender,
                                                                          },
                                                                          () => {
                                                                            this.calculateTotalOrderAmount(
                                                                              needReviewindex,
                                                                              OrderitemIndex
                                                                            );
                                                                          }
                                                                        );
                                                                      }}
                                                                      src={
                                                                        deleteIcon
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  ) : (
                                                                    <img
                                                                      className="delete-icon"
                                                                      alt=""
                                                                    />
                                                                  )}
                                                                  <select
                                                                    className="form-control custom-select w-100 w-auto"
                                                                    name="dateType"
                                                                    value={
                                                                      orderBreakDownFixedItems?.fixedItem
                                                                    }
                                                                    onChange={(e) => {
                                                                      const fixedItemArray = needReviedData;
                                                                      const pricingTierData =Orderitems?.pricingTierData.fixedItem;
                                                                      let obj =
                                                                        {
                                                                          fixedItem: "",
                                                                          unitPrice: 0,
                                                                          units:"",
                                                                          isDeleteAble:orderBreakDownFixedItems?.isDeleteAble,
                                                                        };
                                                                      pricingTierData.forEach((element) => {
                                                                          if (
                                                                            element.label ===e.target.value) {
                                                                            obj.fixedItem = e.target.value;
                                                                            obj.unitPrice = element.value;
                                                                            obj.units =  "";
                                                                            if(element?.dumpRateType === "fixedItem"){
                                                                              obj["dumpRateType"]="fixedItem"
                                                                            }
                                                                            // obj =
                                                                            //   { fixedItem: e.target.value,
                                                                            //  unitPrice : element.value,
                                                                            //     units: "",
                                                                            //     isDeleteAble:
                                                                            //       orderBreakDownFixedItems?.isDeleteAble,
                                                                            //   };
                                                                          }
                                                                        }
                                                                      );
                                                                      fixedItemArray[needReviewindex].order[OrderitemIndex].orderBreakDownItems.fixedItem[index] = obj;
                                                                      this.setState(
                                                                        {
                                                                          needReviedData:fixedItemArray,
                                                                          reRender:!this.state.reRender,
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    <option value="">Select</option>
                                                                    {Orderitems?.pricingTierData?.fixedItem &&
                                                                      Orderitems?.pricingTierData?.fixedItem.map(
                                                                        (data, index ) => {
                                                                          return (
                                                                            <option  value={data.label}> { data.label } </option>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </select>
                                                                  {/* <label className="material-textfield-label">Date </label> */}
                                                                </td>
                                                                <td>
                                                                 
                                                                    <>
                                                                    <input
                                                                    type="number"
                                                                    min="0"
                                                                    onChange={(e ) => {
                                                                      const fixedItemArray =needReviedData;
                                                                      const obj ={
                                                                          fixedItem: orderBreakDownFixedItems?.fixedItem,
                                                                          unitPrice: e.target.value,
                                                                          units:orderBreakDownFixedItems?.units,
                                                                          isDeleteAble: orderBreakDownFixedItems?.isDeleteAble,
                                                                        };
                                                                        if(orderBreakDownFixedItems?.dumpRateType==="fixedItem"){
                                                                          obj["dumpRateType"]="fixedItem"
                                                                        }
                                                                       fixedItemArray[needReviewindex].order[OrderitemIndex].orderBreakDownItems.fixedItem[index] = obj;
                                                                       this.setState(
                                                                        {
                                                                          needReviedData:
                                                                            fixedItemArray,
                                                                          reRender:
                                                                            !this
                                                                              .state
                                                                              .reRender,
                                                                        },
                                                                        () => {
                                                                          this.calculateTotalOrderAmount(
                                                                            needReviewindex,
                                                                            OrderitemIndex
                                                                          );
                                                                        }
                                                                      );
                                                                    }}
                                                                    value={
                                                                      Number(
                                                                        parseFloat(
                                                                          orderBreakDownFixedItems?.unitPrice
                                                                        ).toFixed(
                                                                          2
                                                                        )
                                                                      )
                                                                    }
                                                                    className="form-control"
                                                                  />
                                                                    </>
                                                                </td>
                                                                <td>
                                                                  <input
                                                                    type="number"
                                                                    min="0"
                                                                    onChange={(e ) => {
                                                                      const fixedItemArray =needReviedData;
                                                                      const obj ={
                                                                          fixedItem: orderBreakDownFixedItems?.fixedItem,
                                                                          unitPrice: orderBreakDownFixedItems?.unitPrice,
                                                                          units:e.target.value,
                                                                          isDeleteAble: orderBreakDownFixedItems?.isDeleteAble,
                                                                        };
                                                                        if(orderBreakDownFixedItems?.dumpRateType==="fixedItem"){
                                                                          obj["dumpRateType"]="fixedItem"
                                                                        }
                                                                       fixedItemArray[needReviewindex].order[OrderitemIndex].orderBreakDownItems.fixedItem[index] = obj;
                                                                       this.setState(
                                                                        {
                                                                          needReviedData:
                                                                            fixedItemArray,
                                                                          reRender:
                                                                            !this
                                                                              .state
                                                                              .reRender,
                                                                        },
                                                                        () => {
                                                                          this.calculateTotalOrderAmount(
                                                                            needReviewindex,
                                                                            OrderitemIndex
                                                                          );
                                                                        }
                                                                      );
                                                                    }}
                                                                    value={
                                                                      orderBreakDownFixedItems?.units
                                                                    }
                                                                    className="form-control"
                                                                  />
                                                                </td>
                                                                <td>
                                                                  {orderBreakDownFixedItems?.unitPrice &&
                                                                    orderBreakDownFixedItems?.units && (
                                                                      <>
                                                                        $
                                                                        {this.numberWithCommas(
                                                                          Number(
                                                                            parseFloat(
                                                                              orderBreakDownFixedItems?.unitPrice *
                                                                                Number(
                                                                                  orderBreakDownFixedItems?.units
                                                                                )
                                                                            ).toFixed(
                                                                              2
                                                                            )
                                                                          )
                                                                        )}{" "}
                                                                      </>
                                                                    )}
                                                                </td>
                                                              </tr>
                                                            </>
                                                          )
                                                        )}

                                                      {Orderitems
                                                        ?.orderBreakDownItems
                                                        ?.chargeByWeight
                                                        .length > 0 &&
                                                        Orderitems?.orderBreakDownItems?.chargeByWeight.map(
                                                          (
                                                            orderBreakDownChargeByWeight,
                                                            index
                                                          ) => (
                                                            <>
                                                              <tr>
                                                                <td>&nbsp;</td>
                                                                <td className="position-relative d-flex align-items-center">
                                                                  {orderBreakDownChargeByWeight?.isDeleteAble && (
                                                                    <img
                                                                      className="delete-icon"
                                                                      onClick={() => {
                                                                        const fixedItemArray =
                                                                          needReviedData;
                                                                        fixedItemArray[
                                                                          needReviewindex
                                                                        ].order[
                                                                          OrderitemIndex
                                                                        ].orderBreakDownItems.chargeByWeight.splice(
                                                                          index,
                                                                          1
                                                                        );
                                                                        this.setState(
                                                                          {
                                                                            needReviedData:
                                                                              fixedItemArray,
                                                                            reRender:
                                                                              !this
                                                                                .state
                                                                                .reRender,
                                                                          },
                                                                          () => {
                                                                            this.calculateTotalOrderAmount(
                                                                              needReviewindex,
                                                                              OrderitemIndex
                                                                            );
                                                                          }
                                                                        );
                                                                      }}
                                                                      src={
                                                                        deleteIcon
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  )}
                                                                  <select
                                                                    className="form-control custom-select w-100 w-auto"
                                                                    name="dateType"
                                                                    value={
                                                                      orderBreakDownChargeByWeight?.fixedItem
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      const fixedItemArray =
                                                                        needReviedData;
                                                                      const chargeByWeightArray =
                                                                        Orderitems
                                                                          ?.pricingTierData
                                                                          .chargeByWeightArray;
                                                                      let obj =
                                                                        {
                                                                          fixedItem:
                                                                            "",
                                                                          unitPrice: 0,
                                                                          units:
                                                                            "",
                                                                          isDeleteAble:
                                                                            orderBreakDownChargeByWeight?.isDeleteAble,
                                                                        };
                                                                      chargeByWeightArray.forEach(
                                                                        (
                                                                          element
                                                                        ) => {
                                                                          if (
                                                                            element.label ===
                                                                            e
                                                                              .target
                                                                              .value
                                                                          ) {
                                                                            obj.fixedItem =
                                                                              e.target.value;
                                                                            obj.unitPrice =
                                                                              element.value;
                                                                            obj.units =
                                                                              "";
                                                                          }
                                                                        }
                                                                      );
                                                                      fixedItemArray[
                                                                        needReviewindex
                                                                      ].order[
                                                                        OrderitemIndex
                                                                      ].orderBreakDownItems.chargeByWeight[
                                                                        index
                                                                      ] = obj;
                                                                      this.setState(
                                                                        {
                                                                          needReviedData:
                                                                            fixedItemArray,
                                                                          reRender:
                                                                            !this
                                                                              .state
                                                                              .reRender,
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    <option value="">
                                                                      Select
                                                                    </option>
                                                                    {Orderitems
                                                                      ?.pricingTierData
                                                                      ?.chargeByWeightArray &&
                                                                      Orderitems?.pricingTierData?.chargeByWeightArray.map(
                                                                        (
                                                                          data,
                                                                          index
                                                                        ) => {
                                                                          return (
                                                                            <option
                                                                              value={
                                                                                data.label
                                                                              }
                                                                            >
                                                                              {
                                                                                data.label
                                                                              }
                                                                            </option>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </select>
                                                                  {/* <label className="material-textfield-label">Date </label> */}
                                                                </td>
                                                                <td>
                                                                  <input
                                                                    type="number"
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      const needReviedDataArray =
                                                                        needReviedData;
                                                                      const obj =
                                                                        {
                                                                          fixedItem:
                                                                            orderBreakDownChargeByWeight?.fixedItem,
                                                                          unitPrice: e.target.value,
                                                                          units: orderBreakDownChargeByWeight?.units,
                                                                          isDeleteAble:
                                                                            orderBreakDownChargeByWeight?.isDeleteAble,
                                                                        };
                                                                      needReviedDataArray[
                                                                        needReviewindex
                                                                      ].order[
                                                                        OrderitemIndex
                                                                      ].orderBreakDownItems.chargeByWeight[
                                                                        index
                                                                      ] = obj;
                                                                      this.setState(
                                                                        {
                                                                          needReviedData:
                                                                            needReviedDataArray,
                                                                          reRender:
                                                                            !this
                                                                              .state
                                                                              .reRender,
                                                                        },
                                                                        () => {
                                                                          this.calculateTotalOrderAmount(
                                                                            needReviewindex,
                                                                            OrderitemIndex,
                                                                            index
                                                                          );
                                                                        }
                                                                      );
                                                                    }}
                                                                    value={
                                                                       Number(
                                                                      parseFloat(
                                                                        orderBreakDownChargeByWeight?.unitPrice
                                                                      ).toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                    }
                                                                    className="form-control"
                                                                  />
                                                                </td>
                                                                <td>
                                                                  <input
                                                                    type="number"
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      const needReviedDataArray =
                                                                        needReviedData;
                                                                      const obj =
                                                                        {
                                                                          fixedItem:
                                                                            orderBreakDownChargeByWeight?.fixedItem,
                                                                          unitPrice:
                                                                            orderBreakDownChargeByWeight?.unitPrice,
                                                                          units:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                          isDeleteAble:
                                                                            orderBreakDownChargeByWeight?.isDeleteAble,
                                                                        };
                                                                      needReviedDataArray[
                                                                        needReviewindex
                                                                      ].order[
                                                                        OrderitemIndex
                                                                      ].orderBreakDownItems.chargeByWeight[
                                                                        index
                                                                      ] = obj;
                                                                      this.setState(
                                                                        {
                                                                          needReviedData:
                                                                            needReviedDataArray,
                                                                          reRender:
                                                                            !this
                                                                              .state
                                                                              .reRender,
                                                                        },
                                                                        () => {
                                                                          this.calculateTotalOrderAmount(
                                                                            needReviewindex,
                                                                            OrderitemIndex,
                                                                            index
                                                                          );
                                                                        }
                                                                      );
                                                                    }}
                                                                    value={
                                                                      orderBreakDownChargeByWeight?.units
                                                                    }
                                                                    className="form-control"
                                                                  />
                                                                </td>
                                                                <td>
                                                                  {orderBreakDownChargeByWeight?.unitPrice &&
                                                                    orderBreakDownChargeByWeight?.units && (
                                                                      <>
                                                                        $
                                                                        {this.numberWithCommas(
                                                                          Number(
                                                                            parseFloat(
                                                                              orderBreakDownChargeByWeight?.unitPrice *
                                                                                Number(
                                                                                  orderBreakDownChargeByWeight?.units
                                                                                )
                                                                            ).toFixed(
                                                                              2
                                                                            )
                                                                          )
                                                                        )}{" "}
                                                                      </>
                                                                    )}
                                                                </td>
                                                              </tr>
                                                            </>
                                                          )
                                                        )}
                                                      <tr>
                                                        <td
                                                          colSpan={5}
                                                          className="border-b-1"
                                                        >
                                                          <ul className="list add__items-list">
                                                            <li className="items">
                                                              <button
                                                                className="plush-icon"
                                                                onClick={() => {
                                                                  const fixedItemArray =
                                                                    needReviedData;
                                                                  const obj = {
                                                                    fixedItem:
                                                                      "",
                                                                    unitPrice: 0,
                                                                    units: "",
                                                                    isDeleteAble: true,
                                                                  };
                                                                  fixedItemArray[
                                                                    needReviewindex
                                                                  ].order[
                                                                    OrderitemIndex
                                                                  ].orderBreakDownItems.fixedItem.push(
                                                                    obj
                                                                  );
                                                                  this.setState(
                                                                    {
                                                                      needReviedData:
                                                                        fixedItemArray,
                                                                      reRender:
                                                                        !this
                                                                          .state
                                                                          .reRender,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <PlushIcon />
                                                              </button>
                                                              Add Fixed Item(s)
                                                            </li>
                                                            <li className="items">
                                                              <button
                                                                className="plush-icon"
                                                                onClick={() => {
                                                                  const fixedItemArray =
                                                                    needReviedData;
                                                                  const obj = {
                                                                    fixedItem:
                                                                      "",
                                                                    unitPrice: 0,
                                                                    units: "",
                                                                    isDeleteAble: true,
                                                                  };
                                                                  fixedItemArray[
                                                                    needReviewindex
                                                                  ].order[
                                                                    OrderitemIndex
                                                                  ].orderBreakDownItems.chargeByWeight.push(
                                                                    obj
                                                                  );
                                                                  this.setState(
                                                                    {
                                                                      needReviedData:
                                                                        fixedItemArray,
                                                                      reRender:
                                                                        !this
                                                                          .state
                                                                          .reRender,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <PlushIcon />
                                                              </button>
                                                              Add Weight
                                                            </li>
                                                          </ul>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            ) : (
                                              <tr>
                                                <td
                                                  className="lp-10 tp-10"
                                                  colSpan={8}
                                                >
                                                  <table className="table custom-table layout-not-fix pricing-table">
                                                    <thead>
                                                      <th>Ticket #</th>
                                                      <th>Service</th>
                                                      <th>Unit Price</th>
                                                      <th>Qty</th>
                                                      <th>Amount</th>
                                                    </thead>
                                                    <tbody>
                                                      <>
                                                        <tr>
                                                          <td
                                                            className="cursor-pointer underline"
                                                            onClick={() => {
                                                              this.props.navigate(
                                                                `/order-details/${Orderitems?.ticketNumber}`
                                                              );
                                                            }}
                                                          >
                                                            {
                                                              Orderitems?.ticketNumber
                                                            }
                                                          </td>
                                                          <td className="position-relative d-flex align-items-center">
                                                            <select
                                                              className="form-control custom-select w-100 w-auto"
                                                              name="dateType"
                                                              value={
                                                                Orderitems?.dumpRateType
                                                              }
                                                              onChange={(e) => {
                                                                const fixedItemArray =
                                                                  needReviedData;
                                                                const pricingTierData =
                                                                  Orderitems
                                                                    ?.pricingTierData
                                                                    .fixedItem;
                                                                fixedItemArray[
                                                                  needReviewindex
                                                                ].order[
                                                                  OrderitemIndex
                                                                ].dumpRateType =
                                                                  e.target.value;
                                                                fixedItemArray[
                                                                  needReviewindex
                                                                ].order[
                                                                  OrderitemIndex
                                                                ].dumpRate =
                                                                  pricingTierData[
                                                                    e.target.value
                                                                  ];
                                                                this.setState(
                                                                  {
                                                                    needReviedData:
                                                                      fixedItemArray,
                                                                    reRender:
                                                                      !this
                                                                        .state
                                                                        .reRender,
                                                                  },
                                                                  () => {
                                                                    this.calculateTotalScaleAmount(
                                                                      needReviewindex,
                                                                      OrderitemIndex
                                                                    );
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              <option value="">
                                                                Select
                                                              </option>
                                                              {Orderitems
                                                                ?.pricingTierData
                                                                ?.fixedItem &&
                                                                Object.keys(
                                                                  Orderitems
                                                                    ?.pricingTierData
                                                                    ?.fixedItem
                                                                ).map(
                                                                  (
                                                                    data,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <option
                                                                        value={
                                                                          data
                                                                        }
                                                                      >
                                                                        {_.startCase(
                                                                          data
                                                                        )}
                                                                      </option>
                                                                    );
                                                                  }
                                                                )}
                                                            </select>
                                                            {/* <label className="material-textfield-label">Date </label> */}
                                                          </td>
                                                          <td>
                                                          <input
                                                                type="number"
                                                                min='0'
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const fixedItemArray =
                                                                    needReviedData;
                                                                  fixedItemArray[
                                                                    needReviewindex
                                                                  ].order[
                                                                    OrderitemIndex
                                                                  ].dumpRate =
                                                                    e.target.value;
                                                                  this.setState(
                                                                    {
                                                                      needReviedData:
                                                                        fixedItemArray,
                                                                      reRender:
                                                                        !this
                                                                          .state
                                                                          .reRender,
                                                                    },
                                                                    () => {
                                                                      this.calculateTotalScaleAmount(
                                                                        needReviewindex,
                                                                        OrderitemIndex
                                                                      );
                                                                    }
                                                                  );
                                                                }}
                                                                value={
                                                                  Number(
                                                                    parseFloat(
                                                                      Orderitems?.dumpRate
                                                                    ).toFixed(2)
                                                                  )
                                                                }
                                                                className="form-control"
                                                              />
                                                          </td>
                                                          <td>
                                                            {!Orderitems?.dumpRateType.includes(
                                                              "Ton"
                                                            ) ? (
                                                              <input
                                                                type="number"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const fixedItemArray =
                                                                    needReviedData;
                                                                  fixedItemArray[
                                                                    needReviewindex
                                                                  ].order[
                                                                    OrderitemIndex
                                                                  ].yardage =
                                                                    e.target.value;
                                                                  this.setState(
                                                                    {
                                                                      needReviedData:
                                                                        fixedItemArray,
                                                                      reRender:
                                                                        !this
                                                                          .state
                                                                          .reRender,
                                                                    },
                                                                    () => {
                                                                      this.calculateTotalScaleAmount(
                                                                        needReviewindex,
                                                                        OrderitemIndex
                                                                      );
                                                                    }
                                                                  );
                                                                }}
                                                                value={
                                                                  Orderitems?.yardage
                                                                }
                                                                className="form-control"
                                                              />
                                                            ) : (
                                                              <input
                                                                type="number"
                                                                disabled
                                                                // onChange={(e) => {
                                                                //   const fixedItemArray = needReviedData
                                                                //   fixedItemArray[needReviewindex].order[OrderitemIndex].yardage = e.target.value
                                                                //   this.setState({ needReviedData: fixedItemArray, reRender: !this.state.reRender }
                                                                //     , () => { this.calculateTotalScaleAmount(needReviewindex, OrderitemIndex) })
                                                                // }}
                                                                value={
                                                                  Orderitems?.tonnage
                                                                }
                                                                className="form-control"
                                                              />
                                                            )}
                                                          </td>
                                                          <td>
                                                            $
                                                            {!Orderitems?.dumpRateType.includes(
                                                              "Ton"
                                                            )
                                                              ? this.numberWithCommas(
                                                                  Number(
                                                                    parseFloat(
                                                                      Number(
                                                                        Orderitems?.dumpRate
                                                                      ) *
                                                                        Number(
                                                                          Orderitems?.yardage
                                                                        )
                                                                    ).toFixed(2)
                                                                  )
                                                                )
                                                              : this.numberWithCommas(
                                                                  Number(
                                                                    parseFloat(
                                                                      Number(
                                                                        Orderitems?.dumpRate
                                                                      ) *
                                                                        Number(
                                                                          Orderitems?.tonnage
                                                                        )
                                                                    ).toFixed(2)
                                                                  )
                                                                )}
                                                          </td>
                                                        </tr>
                                                      </>

                                                      {Orderitems
                                                        ?.additionalItems
                                                        .length > 0 &&
                                                        Orderitems?.additionalItems.map(
                                                          (
                                                            additionalItem,
                                                            additionalItemindex
                                                          ) => (
                                                            <>
                                                              <tr>
                                                                <td>&nbsp;</td>

                                                                <td className="position-relative d-flex align-items-center">
                                                                  {additionalItem?.isDeleteAble && (
                                                                    <img
                                                                      className="delete-icon"
                                                                      onClick={() => {
                                                                        const fixedItemArray =
                                                                          needReviedData;
                                                                        fixedItemArray[
                                                                          needReviewindex
                                                                        ].order[
                                                                          OrderitemIndex
                                                                        ].additionalItems.splice(
                                                                          additionalItemindex,
                                                                          1
                                                                        );
                                                                        this.setState(
                                                                          {
                                                                            needReviedData:
                                                                              fixedItemArray,
                                                                            reRender:
                                                                              !this
                                                                                .state
                                                                                .reRender,
                                                                          },
                                                                          () => {
                                                                            this.calculateTotalScaleAmount(
                                                                              needReviewindex,
                                                                              OrderitemIndex
                                                                            );
                                                                          }
                                                                        );
                                                                      }}
                                                                      src={
                                                                        deleteIcon
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  )}
                                                                  <select
                                                                    className="form-control custom-select w-100 w-auto"
                                                                    name="dateType"
                                                                    value={
                                                                      additionalItem?.label
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      const fixedItemArray =
                                                                        needReviedData;
                                                                      const additionalItems =
                                                                        Orderitems
                                                                          ?.pricingTierData
                                                                          ?.additionalItem;
                                                                      let obj =
                                                                        {
                                                                          label:
                                                                            "",
                                                                          name: "",
                                                                          value:
                                                                            "",
                                                                          quantity:
                                                                            "",
                                                                          isDeleteAble:
                                                                            additionalItem?.isDeleteAble,
                                                                        };
                                                                      additionalItems.forEach(
                                                                        (
                                                                          element
                                                                        ) => {
                                                                          if (
                                                                            element.label ===
                                                                            e
                                                                              .target
                                                                              .value
                                                                          ) {
                                                                            obj.label =
                                                                              e.target.value;
                                                                            obj.name =
                                                                              e.target.value.toLowerCase();
                                                                            obj.value =
                                                                              element.value;
                                                                            obj.quantity =
                                                                              "";
                                                                          }
                                                                        }
                                                                      );
                                                                      fixedItemArray[
                                                                        needReviewindex
                                                                      ].order[
                                                                        OrderitemIndex
                                                                      ].additionalItems[
                                                                        additionalItemindex
                                                                      ] = obj;
                                                                      this.setState(
                                                                        {
                                                                          needReviedData:
                                                                            fixedItemArray,
                                                                          reRender:
                                                                            !this
                                                                              .state
                                                                              .reRender,
                                                                        },
                                                                        () => {
                                                                          this.calculateTotalScaleAmount(
                                                                            needReviewindex,
                                                                            OrderitemIndex
                                                                          );
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    <option value="">
                                                                      Select
                                                                    </option>
                                                                    {Orderitems
                                                                      ?.pricingTierData
                                                                      ?.additionalItem &&
                                                                      Orderitems?.pricingTierData?.additionalItem.map(
                                                                        (
                                                                          data,
                                                                          index
                                                                        ) => {
                                                                          return (
                                                                            <option
                                                                              value={
                                                                                data.label
                                                                              }
                                                                            >
                                                                              {
                                                                                data.label
                                                                              }
                                                                            </option>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </select>
                                                                  {/* <label className="material-textfield-label">Date </label> */}
                                                                </td>
                                                                <td>
                                                                <input
                                                                    type="number"
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      const needReviedDataArray =
                                                                        needReviedData;
                                                                      let obj =
                                                                        {
                                                                          label:
                                                                            additionalItem.label,
                                                                          name: additionalItem.name,
                                                                          value:e.target.value,
                                                                          quantity: additionalItem?.quantity,
                                                                          isDeleteAble:
                                                                            additionalItem?.isDeleteAble,
                                                                        };
                                                                      needReviedDataArray[
                                                                        needReviewindex
                                                                      ].order[
                                                                        OrderitemIndex
                                                                      ].additionalItems[
                                                                        additionalItemindex
                                                                      ] = obj;
                                                                      this.setState(
                                                                        {
                                                                          needReviedData:
                                                                            needReviedDataArray,
                                                                          reRender:
                                                                            !this
                                                                              .state
                                                                              .reRender,
                                                                        },
                                                                        () => {
                                                                          this.calculateTotalScaleAmount(
                                                                            needReviewindex,
                                                                            OrderitemIndex
                                                                          );
                                                                        }
                                                                      );
                                                                    }}
                                                                    value={
                                                                      Number(
                                                                        additionalItem?.value
                                                                          ? additionalItem?.value
                                                                          : ""
                                                                      )
                                                                    }
                                                                    className="form-control"
                                                                  />
                                                                </td>
                                                                <td>
                                                                  <input
                                                                    type="number"
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      const needReviedDataArray =
                                                                        needReviedData;
                                                                      let obj =
                                                                        {
                                                                          label:
                                                                            additionalItem.label,
                                                                          name: additionalItem.name,
                                                                          value:
                                                                            additionalItem.value,
                                                                          quantity:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                          isDeleteAble:
                                                                            additionalItem?.isDeleteAble,
                                                                        };
                                                                      needReviedDataArray[
                                                                        needReviewindex
                                                                      ].order[
                                                                        OrderitemIndex
                                                                      ].additionalItems[
                                                                        additionalItemindex
                                                                      ] = obj;
                                                                      this.setState(
                                                                        {
                                                                          needReviedData:
                                                                            needReviedDataArray,
                                                                          reRender:
                                                                            !this
                                                                              .state
                                                                              .reRender,
                                                                        },
                                                                        () => {
                                                                          this.calculateTotalScaleAmount(
                                                                            needReviewindex,
                                                                            OrderitemIndex
                                                                          );
                                                                        }
                                                                      );
                                                                    }}
                                                                    value={
                                                                      additionalItem?.quantity
                                                                    }
                                                                    className="form-control"
                                                                  />
                                                                </td>
                                                                <td>
                                                                  {additionalItem?.value &&
                                                                    additionalItem?.quantity && (
                                                                      <>
                                                                        $
                                                                        {this.numberWithCommas(
                                                                          Number(
                                                                            additionalItem?.value
                                                                          ) *
                                                                            Number(
                                                                              additionalItem?.quantity
                                                                            )
                                                                        )}
                                                                      </>
                                                                    )}
                                                                </td>
                                                              </tr>
                                                            </>
                                                          )
                                                        )}
                                                      <tr>
                                                        <td colSpan={5}>
                                                          <ul className="list add__items-list">
                                                            <li className="items">
                                                              <button
                                                                className="plush-icon"
                                                                onClick={() => {
                                                                  const fixedItemArray =
                                                                    needReviedData;
                                                                  const obj = {
                                                                    label: "",
                                                                    name: "",
                                                                    value: "",
                                                                    quantity:
                                                                      "",
                                                                    isDeleteAble: true,
                                                                  };
                                                                  fixedItemArray[
                                                                    needReviewindex
                                                                  ].order[
                                                                    OrderitemIndex
                                                                  ].additionalItems.push(
                                                                    obj
                                                                  );
                                                                  this.setState(
                                                                    {
                                                                      needReviedData:
                                                                        fixedItemArray,
                                                                      reRender:
                                                                        !this
                                                                          .state
                                                                          .reRender,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                <PlushIcon />
                                                              </button>
                                                              Add Fixed Item(s)
                                                            </li>
                                                            {/* <li className="items">
                                                <button className="plush-icon" onClick={() => {
                                                  const fixedItemArray = needReviedData
                                                  const obj = {
                                                    fixedItem: "",
                                                    unitPrice: 0,
                                                    unnits: ""
                                                  }
                                                  fixedItemArray[needReviewindex].order[OrderitemIndex].orderBreakDownItems.chargeByWeight.push(obj)
                                                  this.setState({ needReviedData: fixedItemArray, reRender: !this.state.reRender })
                                                }}><PlushIcon /></button>Add Weight
                                              </li> */}
                                                          </ul>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            )}
                                          </>
                                        )
                                      )}
                                    <tr>
                                      <td colSpan={5}>
                                        <div className="needs__review-nots">
                                          <div className="needs__review-left">
                                            <ul className="list nots__list">
                                              <li className="items">
                                                <h3 className="label">Notes</h3>
                                                <textarea
                                                  value={
                                                    needReviedData[
                                                      needReviewindex
                                                    ]?.notes
                                                  }
                                                  onChange={(e) => {
                                                    const InvoiceNote =
                                                      needReviedData;
                                                    InvoiceNote[
                                                      needReviewindex
                                                    ].notes = e.target.value;
                                                    this.setState({
                                                      needReviedData:
                                                        InvoiceNote,
                                                      reRender:
                                                        !this.state.reRender,
                                                    });
                                                  }}
                                                ></textarea>
                                              </li>
                                              <li className="items">
                                                <h3 className="label">
                                                  Attachments
                                                </h3>
                                                <div className="d-flex">
                                                  <button
                                                    onClick={() => {
                                                      document
                                                        .getElementById(
                                                          "Filepicker"
                                                        )
                                                        .click();
                                                    }}
                                                    className="attachments-btn"
                                                  >
                                                    <span>
                                                      <PlushIcon className="icon" />
                                                    </span>
                                                  </button>
                                                  <input
                                                    type="file"
                                                    id="Filepicker"
                                                    style={{ display: "none" }}
                                                    onChange={(e) => {
                                                      this.onChangeFile(
                                                        e,
                                                        needReviewindex
                                                      );
                                                    }}
                                                  ></input>
                                                  <section className="attachments__section">
                                                    {/* <h3 className="label">Attachments</h3> */}
                                                    <ul className="list">
                                                      {item?.attachments
                                                        ?.length > 0 &&
                                                        item?.attachments.map(
                                                          (item, index) => (
                                                            <li className="items">
                                                              <button className="attachments-btn attachments-img">
                                                                <img
                                                                  src={item}
                                                                  alt=""
                                                                />
                                                              </button>
                                                              {/* <CloseIcon className="closeIcon"/> */}
                                                              <img
                                                                onClick={() => {
                                                                  this.cancleAttachments(
                                                                    needReviewindex,
                                                                    index,
                                                                    "FromDB"
                                                                  );
                                                                }}
                                                                className="closeIcon"
                                                                src={closeIcon}
                                                                alt=""
                                                              />
                                                            </li>
                                                          )
                                                        )}
                                                      {item?.showAttachments
                                                        .length > 0 &&
                                                        item?.showAttachments.map(
                                                          (item, index) => (
                                                            <li className="items">
                                                              <button className="attachments-btn attachments-img">
                                                                <img
                                                                  src={item}
                                                                  alt=""
                                                                />
                                                              </button>
                                                              {/* <CloseIcon className="closeIcon"/> */}
                                                              <img
                                                                onClick={() => {
                                                                  this.cancleAttachments(
                                                                    needReviewindex,
                                                                    index,
                                                                    ""
                                                                  );
                                                                }}
                                                                className="closeIcon"
                                                                src={closeIcon}
                                                                alt=""
                                                              />
                                                            </li>
                                                          )
                                                        )}
                                                    </ul>
                                                  </section>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="needs__review-right">
                                            <div className="total__amount">
                                              <ul className="list">
                                                <li className="items">
                                                  <span>Invoice Amount</span>
                                                  <span>
                                                    $
                                                    {this.numberWithCommas(
                                                      Number(
                                                        item?.totalOrdersAmount
                                                      )
                                                    )}
                                                  </span>
                                                </li>
                                                <li className="items">
                                                  <span>Amount Paid</span>
                                                  <span>
                                                    $
                                                    {this.numberWithCommas(
                                                      Number(item?.amountPaid)
                                                    )}
                                                  </span>
                                                </li>
                                                <li className="items">
                                                  <span>Original Amount</span>
                                                  <span>
                                                    $
                                                    {this.numberWithCommas(
                                                      Number(
                                                        item?.totalOrignalAmount
                                                      )
                                                    )}
                                                  </span>
                                                </li>
                                                <li className="items">
                                                  <span>
                                                    <strong>Amount Due</strong>
                                                  </span>
                                                  <span>
                                                    <strong>
                                                      $
                                                      {this.numberWithCommas(
                                                        Number(
                                                          item?.totalOrdersAmount -
                                                            item?.amountPaid
                                                        )
                                                      )}
                                                    </strong>
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="row">
                                              <div className="col-lg-12">
                                                <div className="post__send-btn">
                                                  <button
                                                    onClick={() => {
                                                      this.updateInvoiceDetail(
                                                        needReviewindex,
                                                        "save",
                                                        "needReview"
                                                      );
                                                    }}
                                                    className="btn btn-outline-black btn-small"
                                                  >
                                                    {this.state.pleaseWait
                                                      ? "Please Wait..."
                                                      : "Save"}
                                                  </button>
                                                  <button
                                                    disabled={
                                                      this.state.pleaseWait
                                                    }
                                                    onClick={() => {
                                                      this.updateInvoiceDetail(
                                                        needReviewindex,
                                                        "postAndSend",
                                                        "posted"
                                                      );
                                                    }}
                                                    className="btn btn-outline-black btn-small"
                                                  >
                                                    {this.state.pleaseWait
                                                      ? "Please Wait..."
                                                      : "Post & Send"}
                                                  </button>
                                                  <button
                                                    disabled={
                                                      this.state.postPleaseWait
                                                    }
                                                    onClick={() => {
                                                      this.updateInvoiceDetail(
                                                        needReviewindex,
                                                        "post",
                                                        "posted"
                                                      );
                                                    }}
                                                    className="btn btn-dark btn-small"
                                                  >
                                                    {" "}
                                                    {this.state.postPleaseWait
                                                      ? "Please wait .."
                                                      : "Post"}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </>
                      ))
                  ) : (
                    <>
                      <tr>
                        <td colSpan={10}>
                          <div className="empty__section">
                            <div className="empty__section-inner">
                              <p className="empty__text">
                                No Need Review Invoices
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ): (
                    <>
                      <tr>
                        <td colSpan={10}>
                          <div className="empty__section">
                            <div className="empty__section-inner">
                              <p className="empty__text">
                                <Spin indicator={antIcon} spinning />
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
             
            </div>
          </div>
        </section>
        <Drawer
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          className="select__driver-drawer"
        >
          <div className="head-section-header d-flex align-items-center justify-content-between">
            <button
              className="btn btn-dark btn-sm clear-btn"
              onClick={() => {
                this.setState(
                  { filtersData: [], reRender: !this.state.reRender },
                  () => {
                    sessionStorage.removeItem('posted_filtersData')
                    sessionStorage.removeItem('finalized_filtersData')
                    this.fetchNeedReviewData(searchText, startDate, endDate);
                  }
                );
              }}
            >
              Clear
            </button>
            <div className="head-section-title">Filters</div>
            <div className="head-section-cta">
              <div onClick={this.onClose} className="btn btn-link btn-back">
                <CloseIcon />
              </div>
            </div>
          </div>
          <ul className="filter__list">
            {this.state.invoiceDeliveryData?.length > 0 &&
              this.state.invoiceDeliveryData.map((item, index) => (
                <>
                  <li className="filter__list-item">
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={(e) => this.openFilterList(item?.name)}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes(item?.name)}
                        />
                        <span className="checkmark"></span>
                        <p>{item?.name}</p>
                      </label>
                    </span>
                    <div
                    // className={`filter__list-item-inner ${
                    //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                    // }`}
                    ></div>
                  </li>
                </>
              ))}
            <li className="mt-4 mb-1 frequencyHeading">Billing Frequency</li>
            {this.state.transactionFrequency.map((item) => (
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList(item.value)}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes(item.value)}
                    />
                    <span className="checkmark"></span>
                    <p>{item.name}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
            ))}
          </ul>
        </Drawer>
        <div className="total-amount-list">
          <h3 className="title">Total Invoices</h3> : ${this.numberWithCommas(this.state?.invoicesTotal && this.state?.invoicesTotal > 0 ? this.state.invoicesTotal : 0)} 
        </div>
        {this.state.isModelOpen && (
          <InvoiceDetailComponent
            updateinvoiceDetailData={this.updateinvoiceDetailData}
            isModelOpen={this.state.isModelOpen}
            closeModel={this.closeModel}
            modelData={this.state.modelData}
            projectData={this.state.projectData}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            printByDropDown={this.state.isPrintRecipt}
            uploadImage={this.props.uploadImage}
            updateInvoiceOrder={this.props.updateInvoiceOrder}
            updateInvoicDetail={this.props.updateInvoicDetail}
            reArrangOrdersData={this.reArrangOrdersData}
            getOrderInvoicePdf={this.props.getOrderInvoicePdf}
            {...this.props}
          />
        )}
        {this.state.isCustomerModelOpen && (
          <CustomerDetailComponent
            syncPostedOrder={this.props.syncPostedOrder}
            customerId={this.state.customerId}
            getMe={this.props.getMe}
            fetchCustomer={this.props.fetchCustomer}
            changeUrl={this.assignUrl}
            closeModel={this.closeCustomerDetatilModel}
            updateDriver={this.props.updateDriver}
            updateTruck={this.props.updateTruck}
            deletePaymentMethod={this.props.deletePaymentMethod}
            defaultUrl={this.state.defaultUrl}
            getInvoiceDeliveryByIdData={this.props.getInvoiceDeliveryByIdData}
            getRecivingPaymentInvoices={this.props.getRecivingPaymentInvoices}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            printByDropDown={this.state.isPrintRecipt}
            uploadImage={this.props.uploadImage}
            updateInvoiceOrder={this.props.updateInvoiceOrder}
            updateInvoicDetail={this.props.updateInvoicDetail}
            getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
            getCustomercreditsForCustomers={
              this.props.getCustomercreditsForCustomers
            }
            getReceivePaymentForCustomers={
              this.props.getReceivePaymentForCustomers
            }
            customerInvoicesByCustomerId={
              this.props.customerInvoicesByCustomerId
            }
            {...this.props}
          />
        )}
      </div>
    );
  }
}
