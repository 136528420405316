import React, { PureComponent } from "react";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import DownloadArrow from "./../../images/download_arrow.svg";
import CloseWhite from "./../../images/close_white.svg";
import { ExportIcon,DateIcon } from "../../components/icons";
import _ from "lodash";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { DownarrowIcon,BackArrowIcon, FilterIcon, CloseIcon, StateUpArrowIcon, ZipIcon } from "../../components/icons";
import "./styles.scss";
import moment from "moment-timezone";
import {dumpRatesArray, numberFormat } from "../../constants/common";
import NumberFormat from "react-number-format";
import NotFound from "../NoRecords/component";
import ReactExport from "react-data-export";
import { Drawer, Pagination, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addYears,
  isSameDay,
} from "date-fns";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const paymentTypes = [
  { type: "cash", label: "COD-Cash" },
  { type: "check", label: "COD-Check" },
  { type: "credit-memo", label: "COD-Credit-Memo" },
  { type: "credit-card", label: "COD-Credit-Card" },
  { type: "account", label: "Account" },
  { type: "terminal", label: "Terminal" }
];

// const timezone = moment().format("Z");
export default class ReportsComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    const tz =  localStorage.getItem("timezone") || moment?.tz?.guess() 
    super(props);

    this.state = {
      toggleMenu: false,
      isCreateDrawerOpen: false,
      filterCustomers: [],
      filterDrivers: [],
      filterUsers: [],
      customers: [],
      users: [],
      drivers: [],
      orders: [],
      customerSearchText: "",
      userSearchText: "",
      driverSearchText: "",
      orderFetchInProgress: false,
      typingTimeout: 0,
      sortOrder: { ticketNumber: -1 },
      selectedDate: "",
      dateType: "today",
      datePickerModal: false,
      dataSet: [],
      progressBar: false,
      status: 1,
      spinning: false,
      loading: false,
      totalItems: 0,
      skip: 0,
      limit: 20,
      currentPage: 1,
      pdfLoadar: false,
      pdfLoadarIndex: null,
      ticketNumbers: [],
      paymentTypeFilter: false,
      filterPayments: [],
      zipProgress: false,
      mainSearchText: "",
      tz,
      serviceAddress: this.props?.user?.company?.serviceAddress ?? false,
      startDate: moment()?.tz(tz)?.startOf("month")?.toDate(),
      endDate: moment()?.tz(tz)?.endOf('month')?.toDate(),
      DateRangePickerDroDown: false,
      date: {
        startDate: moment()?.tz(tz)?.startOf("month")?.toDate(),
        endDate: moment()?.tz(tz)?.endOf('month')?.toDate(),
        key: 'selection'
      },
      material: "All",
    };
    this.openFilterList = this.openFilterList.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setAsyncState = newState => new Promise(resolve => this.setState(newState, resolve));

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    document.title = "Reports | Weighworks";
    const reportFilter = JSON.parse(localStorage.getItem("reportFilter"));
    if (reportFilter) {
      const { startDate, endDate } = this.state
      await this.setAsyncState({
        startDate: moment(startDate).isValid()
          ? moment(startDate)?.clone()?.tz(this.state?.tz)?.startOf('day')?.toDate()
          : moment()?.clone()?.tz(this.state?.tz)?.startOf('day')?.toDate(),
        endDate: moment(endDate).isValid() ? moment(endDate)?.clone()?.tz(this.state?.tz)?.endOf('day')?.toDate() : moment()?.tz(this.state?.tz)?.endOf('day')?.toDate(),
        filterCustomers: _.get(reportFilter, "filterCustomers", []),
        filterDrivers: _.get(reportFilter, "filterDrivers", []),
        filterUsers: _.get(reportFilter, "filterUsers", []),
        sortOrder: _.get(reportFilter, "sortOrder", { ticketNumber: -1 }),
        dateType:  _.get(this.state, "dateType", "today"),
        selectedDate: _.get(this.state, "selectedDate", ""),
        filterPayments: _.get(reportFilter, "filterPayments", [])
      });
    }
    window.addEventListener('click', this.handleClickOutside);
    this.fetchReports({isLoading: true});
    this.fetchCustomersList();
    this.fetchDriversList();
    this.fetchUsersList();
    await this.props.fetchMe();
  };
  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleClickOutside);
  }

  fetchReports = async ({isLoading}) => {
    isLoading && this.setState({loading: true})
    const { fetchReports } = this.props;
    const {
      startDate,
      endDate,
      filterCustomers,
      filterDrivers,
      filterPayments,
      filterUsers,
      sortOrder,
      skip,
      limit,
      dateType,
      selectedDate,
      mainSearchText,
      material
    } = this.state;
    await this.setAsyncState({ orderFetchInProgress: true });
    const {
      value: {
        data: { orders, ordersCount }
      }
    } = await fetchReports({
      from: moment(startDate)
        ?.clone()  
        // ?.tz(this.state?.tz)
        // ?.startOf("day")
        ?.toISOString(),
      to: moment(endDate)
        ?.clone()
        // ?.tz(this.state?.tz)
        // ?.endOf("day")
        ?.toISOString(),
      filterCustomers: filterCustomers.map(c => ({ ...c, companyName: escape(c.displayName) })),
      filterDrivers,
      filterUsers,
      filterPayments,
      sortOrder,
      skip,
      limit,
      mainSearchText: escape(mainSearchText),
      ProjectPage: false,
      material : material && material !== "All" ? material : undefined
    });
    this.setState({ orders, totalItems: ordersCount, orderFetchInProgress: false, loading: false });
    localStorage.setItem(
      "reportFilter",
      JSON.stringify({
        startDate,
        endDate,
        filterCustomers,
        filterDrivers,
        filterUsers,
        filterPayments,
        sortOrder,
        dateType,
        selectedDate
      })
    );
  };

  onCloseClick = () => {
    this.setState({ isCreateDrawerOpen: false }, () => {
      this.fetchReports({isLoading: true});
    });
  };

  openFilterList(e) {
    this.setState({
      dateFilter: false,
      userFilter: false,
      driverFilter: false,
      companyFilter: false,
      paymentTypeFilter: false,
      [e.target.name]: e.target.checked
    });
    switch (e.target.name) {
      case "dateFilter":
        if (!e.target.checked) {
          this.setState({
            from: "",
            to: ""
          });
        }
        break;
      case "userFilter":
        if (!e.target.checked) {
          this.setState({
            user: []
          });
        }
        break;
      case "driverFilter":
        if (!e.target.checked) {
          this.setState({
            drivers: []
          });
        }
        break;
      case "companyFilter":
        if (!e.target.checked) {
          this.setState({
            customers: []
          });
        }
        break;
      default:
        break;
    }
  }

  onChangePage = page => {
    this.setState({ currentPage: page, skip: (page - 1) * this.state.limit, ticketNumbers: [] }, () => {
      this.fetchReports({isLoading: true});
    });
  };

  fetchDriversList = async () => {
    const { fetchCompanyDrivers } = this.props;
    const { driverSearchText } = this.state;
    const {
      value: { data }
    } = await fetchCompanyDrivers({ searchText: driverSearchText });
    this.setState({ drivers: data });
  };

  fetchCustomersList = async () => {
    const { fetchCustomers } = this.props;
    const { customerSearchText, status } = this.state;
    const {
      value: {
        data: { customers }
      }
    } = await fetchCustomers({ searchText: customerSearchText, status, sortOrder: { companyName: 1 }, limit: 5000 });
    this.setState({ customers });
  };

  fetchUsersList = async () => {
    const { fetchCompanyUsers } = this.props;
    const { userSearchText } = this.state;
    const {
      value: {
        data: { users }
      }
    } = await fetchCompanyUsers({ searchText: userSearchText });
    this.setState({ users });
  };

  selectCustomer(customer, callback) {
    const customers = [...this.state.filterCustomers];
    const index = _.findIndex(customers, function(c) {
      return String(c._id) === String(customer._id);
    });
    if (index === -1) {
      customers.push({
        _id: String(customer._id),
        companyName: customer.displayName
      });
    } else {
      customers.splice(index, 1);
    }
    this.setState({ filterCustomers: customers, skip: 0, currentPage: 1 }, () => {
      callback && this.fetchReports({isLoading: true});
    });
  }

  selectUser(user, callback) {
    const users = [...this.state.filterUsers];
    const index = _.findIndex(users, function(u) {
      return String(u._id) === String(user._id);
    });
    if (index === -1) {
      users.push({
        _id: String(user._id),
        name: `${user.firstName} ${user.lastName}`
      });
    } else {
      users.splice(index, 1);
    }
    this.setState({ filterUsers: users, skip: 0, currentPage: 1 }, () => {
      callback && this.fetchReports({isLoading: true});
    });
  }

  selectPayment(payment, callback) {
    const filterPayments = [...this.state.filterPayments];
    const index = _.findIndex(filterPayments, function(u) {
      return String(u) === String(payment.type);
    });
    if (index === -1) {
      filterPayments.push(payment.type);
    } else {
      filterPayments.splice(index, 1);
    }
    this.setState({ filterPayments, skip: 0, currentPage: 1 }, () => {
      callback && this.fetchReports({isLoading: true});
    });
  }

  selectDriver(driver, callback) {
    const drivers = [...this.state.filterDrivers];
    const index = _.findIndex(drivers, function(d) {
      return String(d._id) === String(driver._id);
    });
    if (index === -1) {
      drivers.push({
        _id: String(driver._id),
        name: `${driver.firstName} ${driver.lastName}`
      });
    } else {
      drivers.splice(index, 1);
    }
    this.setState({ filterDrivers: drivers, skip: 0, currentPage: 1 }, () => {
      callback && this.fetchReports({isLoading: true});
    });
  }

  handleCustomerSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      customerSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchCustomersList();
      }, 200)
    });
  };

  handleDriverSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      driverSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchDriversList();
      }, 200)
    });
  };

  handleUserSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      userSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchUsersList();
      }, 200)
    });
  };

  handleMainSearchText(event) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      mainSearchText: event.target.value,
      skip: 0,
      currentPage: 1, 
      typingTimeout: setTimeout(() => {
        this.fetchReports({isLoading: true});
      }, 200)
    });
  }

  handleFromChange = date => {
    this.setState({ from: date });
  };

  handleToChange = date => {
    this.setState({ to: date });
  };

  async setOrder(field) {
    const { sortOrder } = this.state;
    const sort = { [field]: sortOrder[field] === 1 ? -1 : 1 };
    await this.setAsyncState({ sortOrder: sort });
    this.fetchReports({isLoading: true});
  }

  onSelect = dates => {
    const self = this;
    self.setState(
      {
        dateType: "custom",
        selectedDate: `${moment(dates.start)?.clone()?.toDate()} - ${moment(dates.end)?.clone()?.toDate()}`,
        startDate: moment(dates.start)?.tz(this.state?.tz)?.toDate(),
        endDate: moment(dates.end)?.tz(this.state?.tz)?.toDate(),
        datePickerModal: false,
        skip: 0,
        currentPage: 1
      },
      () => {
        self.fetchReports();
      }
    );
  };

  setDate(e) {
    const self = this;
    const val = e.target.value;
    if (val === "today") {
      self.setState(
        {
          dateType: "today",
          selectedDate: "",
          startDate: moment()?.tz(this.state?.tz)?.startOf('day')?.toDate(),
          endDate: moment()?.tz(this.state?.tz)?.endOf('day')?.toDate(),
          skip: 0,
          currentPage: 1
        },
        () => {
          self.fetchReports();
        }
      );
    } else if (val === "this week") {
      self.setState(
        {
          dateType: "this week",
          selectedDate: "",
          startDate: moment()
            ?.clone()
            ?.tz(this.state?.tz)
            ?.startOf("isoWeek")
            ?.toDate(),
          endDate: moment()
            ?.clone()
            ?.tz(this.state?.tz)
            ?.endOf("isoWeek")
            ?.toDate(),
          skip: 0,
          currentPage: 1
        },
        () => {
          self.fetchReports();
        }
      );
    } else if (val === "this month") {
      self.setState(
        {
          dateType: "this month",
          selectedDate: "",
          startDate: moment()
            ?.clone()
            ?.tz(this.state?.tz)
            ?.startOf("month")
            ?.toDate(),
          endDate: moment()
            ?.clone()
            ?.tz(this.state?.tz)
            ?.endOf("month")
            ?.toDate(),
          skip: 0,
          currentPage: 1
        },
        () => {
          self.fetchReports();
        }
      );
    } else if (val === "custom") {
      self.setState({ datePickerModal: true });
    }
  }

  openDatePicker = e => {
    const val = e.target.value;
    if (val === "custom") {
      this.setState({ datePickerModal: true });
    }
  };

  closeModal = () => {
    this.setState({ datePickerModal: false });
  };

  exportOrder = async () => {
    this.setAsyncState({ progressBar: true});
    const { fetchReports } = this.props;
    const {
      startDate,
      endDate,
      filterCustomers,
      filterDrivers,
      filterPayments,
      filterUsers,
      sortOrder,
      skip,
      mainSearchText,
      material
    } = this.state;
    await this.setAsyncState({ orderFetchInProgress: true });
    const {
      value: {
        data: { orders }
      }
    } = await fetchReports({
      from: moment(startDate)
        ?.clone()  
        // ?.tz(this.state?.tz)
        // ?.startOf("day")
        ?.toISOString(),
      to: moment(endDate)
        ?.clone()
        // ?.tz(this.state?.tz)
        // ?.endOf("day")
        ?.toISOString(),
      filterCustomers: filterCustomers.map(c => ({ ...c, companyName: escape(c.displayName) })),
      filterDrivers,
      filterUsers,
      filterPayments,
      sortOrder,
      skip,
      mainSearchText: escape(mainSearchText),
      ProjectPage: false,
      material : material && material !== "All" ? material : undefined
    });
    let filtered_data = [];
    for (let i = 0; i < orders.length; i++) {
      const obj = orders[i];
      if (this.state?.serviceAddress !== true) {
        if ("serviceLocation" in obj) {
          const { serviceLocation, ...rest } = obj;
          filtered_data.push(rest);
        } else {
          filtered_data.push(obj);
        }
      }
    }
    await this.setAsyncState({ dataSet: filtered_data.length > 0 ? filtered_data : orders, progressBar: false});
    setTimeout(() => {
     
      this.setState({ dataSet: [], progressBar: false });
    }, 1000);
  };

  async downloadRecord({ ticketNumber }) {
    const {
      value: { data }
    } = await this.props.generatePdf({ ticketNumber, timezone: this.state?.tz });
    this.setState({ pdfLoadarIndex: null, pdfLoadar: false });
    window.open(data, "_blank");
  }

  onClickCheckbox(ticketNumber) {
    const ticketNumbers = [...this.state.ticketNumbers];
    const index = ticketNumbers.indexOf(ticketNumber);
    if (index === -1) {
      ticketNumbers.push(ticketNumber);
    } else {
      ticketNumbers.splice(index, 1);
    }
    this.setState({ ticketNumbers });
  }

  generateMultiplePdf = async () => {
    const { ticketNumbers } = this.state;
    if (ticketNumbers.length === 0) {
      return;
    }
    this.setState({ zipProgress: true });
    // const ticketNumbers_all = this.state?.orders.map(order => order.ticketNumber).flat();
    // console.log(ticketNumbers_all, ">ticketNumbers_all<<<")
    const {
      value: { data, status }
    } = await this.props.generateMultiplePdf({ ticketNumbers, timezone: this.state?.tz });
    this.setState({ zipProgress: false });
    if (status) {
      window.open(data);
    }
  };

  clearFilter = () => {
    this.setState(
      {
        dateFilter: false,
        userFilter: false,
        driverFilter: false,
        companyFilter: false,
        paymentTypeFilter: false,
        from: "",
        to: "",
        filterUsers: [],
        filterCustomers: [],
        filterDrivers: [],
        filterPayments: [],
        skip: 0,
        currentPage: 1
      },
      () => {
        this.fetchReports({isLoading: true});
      }
    );
  };
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    }, () =>  this.fetchReports({isLoading: true}));
  };

  getPaymentType(order) {
    let paymentType = ""
    if(order.paymentTerms === "account"){
     paymentType = "Account"
    }else{
      if(order.paymentType === "" && order.splitPayment.length > 0){
        let type=''
        order.splitPayment.map((payment,index)=>{
          type +=  _.capitalize(payment.paymentType)
          if((order.splitPayment.length -1) > index){
            type += ", "
          }
          return null
        })
        paymentType = type
      }
    }
    // let btnClass = "btn btn-for-account";
    // if (paymentType === "" || order?.paymentType === "account") {
    //   paymentType = "ACCOUNT";
    // } else {
    //   paymentType = `COD ${paymentType}`;
    // }
    // if (paymentType === "COD - Credit Card") {
    //   btnClass = "btn btn-for-cc";
    // } else if (paymentType === "COD - Cash" || paymentType === "COD - Check") {
    //   btnClass = "btn btn-for-cash";
    // } else if (paymentType === "COD - Terminal") {
    //   btnClass = "btn btn-for-terminal";
    // }
    return { paymentType };
  }

  showPaymentTypes = (type, payment) => {
    let paymentType = payment !== "" ? `- ${_.startCase(payment)}` : "";
    let btnClass = "btn btn-for-account";
    if (paymentType === "") {
      paymentType = "ACCOUNT";
    } else {
      paymentType = `COD ${paymentType}`;
    }
    if (paymentType === "COD - Credit Card") {
      btnClass = "btn btn-for-cc";
    } else if (paymentType === "COD - Cash" || paymentType === "COD - Check") {
      btnClass = "btn btn-for-cash";
    } else if (paymentType === "COD - Terminal") {
      btnClass = "btn btn-for-terminal";
    }
    if (type === "css") {
      return btnClass
    } else {
      return paymentType
    }
  }
  handleSelect = (ranges) => {
    ranges = {
      ...ranges,
      selection: {
        ...ranges?.selection,
        startDate: moment(ranges.selection.startDate)?.startOf("day").toDate(),
        endDate: moment(ranges.selection.endDate)?.endOf("day")?.toDate()
      }
    }
    this.setState({
      date: ranges.selection,
      // isPaymentsDateRangePickerOpen: false
    });
    this.setState({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate, skip: 0, currentPage: 1 },()=> this.fetchReports({isLoading: true}))
  }
  handleClickOutside = (event) => {
    if (this.dateRangePickerRef && !this.dateRangePickerRef.contains(event.target)) {
      this.setState({ DateRangePickerDroDown: false });
    }
  };

  handleValueChange = (e) => {
    const { value } = e.target;
    this.setState({ material: value, skip: 0, currentPage: 1 }, () => {
      this.fetchReports({isLoading: true});
    });
  }
  calculateTons = (orders) => {
    let totalTons = 0;
    orders.forEach(order => {
      totalTons += Number(order.tonnage);
    });
    return totalTons?.toFixed(2);
  }
  calculateYardage = (orders) => {
    let totalYardage = 0;
    orders.forEach(order => {
      totalYardage += Number(order?.yardage);
    });
    return totalYardage.toFixed(2);
  }
  calculateAverageDumpRate = (orders) => {
    let totalDumpRate = 0;
    orders.forEach(order => {
      totalDumpRate += Number(order?.dumpRate);
    });
    return (totalDumpRate / orders.length).toFixed(2) !== "NaN" ? (totalDumpRate / orders.length).toFixed(2) : 0;
}
  calculateTotalRevenue = (orders) => {
    let totalRevenue = 0;
    orders.forEach(order => {
      totalRevenue += Number(order?.totalAmount);
    });
    return totalRevenue.toFixed(2);
  }

  render() {
    const {
      orders,
      customers,
      filterCustomers,
      filterDrivers,
      filterUsers,
      users,
      drivers,
      // orderFetchInProgress,
      sortOrder,
      dataSet,
      progressBar,
      pdfLoadar,
      pdfLoadarIndex,
      ticketNumbers,
      filterPayments,
      zipProgress,
      mainSearchText
    } = this.state;
    let filterCount = 0;
    if (filterCustomers.length) {
      filterCount += 1;
    }
    if (filterDrivers.length) {
      filterCount += 1;
    }
    if (filterUsers.length) {
      filterCount += 1;
    }
    if (filterPayments.length) {
      filterCount += 1;
    }
    const { user } = this.props;
    return (
      <div className="layout-has-sidebar">
        <TopNavigation
          {...this.props}
          onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
        />
        <SidebarNavigation
          {...this.props}
          toggleMenu={this.state.toggleMenu}
          onClickToggleMenu={() => this.setState({ toggleMenu: false })}
        />
     
        <Spin indicator={antIcon} spinning={this.state?.loading}>
        <main className="dashboard-layout-content reports__wrapper">
        <div className="btn-back-header">
              <button className="btn btn-link btn-back">
                <Link to={"/dashboard"}>
                  <BackArrowIcon />
                  Back to dashboard
                </Link>
              </button>
            </div>
          <div className="page-header"  ref={ref => this.dateRangePickerRef = ref}>
            <div className="page-header-inner">
              <h2 className="heading-title">Reports</h2>
              <div className="page-header-btn-grp">
                {/* <button
                  disabled={progressBar}
                  onClick={this.exportOrder}
                  className="btn btn-dark btn-sm btn-exportorders"
                >
                  <ExportArrow />
                  {progressBar ? "Please wait..." : "Export Orders"}
                </button> */}
                {dataSet.length > 0 && (
                  <ExcelFile hideElement filename="Reports">
                    <ExcelSheet data={dataSet} name="Orders">
                      <ExcelColumn label="Ticket" value="ticketNumber" />
                      <ExcelColumn label="Company Name" value={col => col?.customer?.displayName} />
                      <ExcelColumn label="Driver Name" value={col => col?.driver ? col?.driver?.firstName + " " + col?.driver?.lastName:"N/A"} />
                      <ExcelColumn label="Total Order Amount" value={col => '$' + numberFormat(col.totalAmount)}/>
                      <ExcelColumn
                        label="Payment Type"
                        value={col => (this.getPaymentType(col).paymentType)}
                      />
                      {
                      this.state?.serviceAddress !== false ?
                      <ExcelColumn
                      label="Service Location"
                      value={col => _.startCase(col.serviceLocation)}
                      /> : <></>
                     }
                    </ExcelSheet>
                  </ExcelFile>
                )}
                {/* <button
                  onClick={this.showDrawer}
                  // onClick={() => this.setState({ isCreateDrawerOpen: true })}
                  className="btn btn-dark btn-sm btn-exportorders"
                >
                  <FilterIcon />
                  Filters {filterCount ? filterCount : ""}
                </button> */}
              </div>
            </div>
            {<button
              className="btn btn-dark btn-sm btn-exportorders" onClick={() => { this.setState({ DateRangePickerDroDown: !this.state.DateRangePickerDroDown }) }}>
                <DateIcon />
              {this.state.date.startDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })} - {this.state.date.endDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })}
            </button>}
            {this.state.DateRangePickerDroDown &&
              <DateRangePicker
              className="datepicker-orders"
                //onChange={(e)=>{this.handleSelect(e.dateRange) ;this.deshboardPayments(e.target.value); this.paymentDatePicker()}}
                onChange={this.handleSelect}
                ranges={[this.state.date]}
                editableDateInputs={true}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                calendarFocus="forwards"
                staticRanges={[
                  ...defaultStaticRanges,
                  {
                    label: "Last Year",
                    range: () => ({
                      startDate: startOfYear(addYears(new Date(), -1)),
                      endDate: endOfYear(addYears(new Date(), -1))
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    }
                  },
                  {
                    label: "This Year",
                    range: () => ({
                      startDate: startOfYear(new Date()),
                      endDate: endOfDay(new Date())
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    }
                  }
                ]}

              />}
          </div>
          {(user?.permissions?.permissionOptions && user?.permissions?.permissionOptions?.exportOrdersAndReports === true) ?
          <div className="export__section">
            <div className="cursor-pointer d-flex" disabled={progressBar} onClick={()=>{this.exportOrder()}}>
              <ExportIcon />
              <h3>{progressBar ? "Please wait..." : "Export Orders"}</h3>
            </div>
            <div className="cursor-pointer d-flex" onClick={this.generateMultiplePdf} disabled={zipProgress}>
              <ZipIcon />
              <h3>{zipProgress ? "Please wait..." : "Create Zip"}</h3>
            </div>
          </div>:null}
          {/*<button
            onClick={this.generateMultiplePdf}
            disabled={zipProgress}
            className="btn btn-dark btn-sm btn__creat-zip text-right"
          >
            {zipProgress ? "Please wait..." : "Create Zip"}
          </button>*/}
          <div className="row">
            <div className="col-md-9">
              <div className="order__no">
                {this.state.totalItems}
                <span>{this.state.totalItems === 1 ? "Order" : "Orders"}</span>
              </div>
            </div>
            <div className="col-md-3">
            <div className="form-group material-textfield ml-3">
                <div className="form-group material-textfield">
                  <select
                    onChange={(e)=>{this.handleValueChange(e)}}
                    className="form-control custom-select w-150 material-textfield-input pr-22"
                    required
                    value={this.state.material ? this.state.material : ""}
                  >
                    <option selected value="All">All</option>
                    {dumpRatesArray?.slice()?.sort((a, b) =>
                          a.label.localeCompare(b.label)
                        )?.map(material => {
                      return (
                        <option value={material.value} key={material.value}>
                          {material.label}
                        </option>
                      );
                    })}
                  </select>
                  <label className="material-textfield-label">
                    Material
                  </label>
                </div>
                </div>
            </div>
          </div>
          <div className="search__box">
            <DebounceInput
              className="form-control"
              placeholder="Search by company name... "
              value={mainSearchText}
              onChange={e => this.handleMainSearchText(e)}
              minLength={2}
              debounceTimeout={1000}
            />
            <button onClick={this.showDrawer} className="btn btn-dark btn-sm btn-exportorders">
              <FilterIcon />
              Filters {filterCount ? filterCount : ""}
            </button>
          </div>
          <div className="row">
            <div className="col-md-12">
            <div className="total-amount-list">
            <div className="total-amount-list-items">
            <h3 className="title">Total Tons</h3> : {this.calculateTons(this.state.orders)}
            </div>
            <div className="total-amount-list-items">
            <h3 className="title">Total Yarage</h3> : {this.calculateYardage(this.state.orders)}
            </div>
            <div className="total-amount-list-items">
            <h3 className="title">Average Dump Rate</h3> : ${this.calculateAverageDumpRate(this.state.orders)}
            </div>
            <div className="total-amount-list-items">
            <h3 className="title">Total Revenue</h3> : ${this.calculateTotalRevenue(this.state.orders)}
            </div>
            </div>
          </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="filter__button-group">
                {filterCustomers &&
                  filterCustomers.map(fc => {
                    return (
                      <button className="btn btn-dark btn-sm" key={fc._id}>
                        {fc.companyName}
                        <span>
                          <img className="img-fluid" src={CloseWhite} alt="" onClick={() => {
                            this.selectCustomer(fc, true)
                            }} />
                        </span>
                      </button>
                    );
                  })}
                {filterDrivers &&
                  filterDrivers.map(fd => {
                    return (
                      <button className="btn btn-dark btn-sm" key={fd._id}>
                        {fd.name}
                        <span>
                          <img className="img-fluid" src={CloseWhite} alt="" onClick={() => {
                            this.selectDriver(fd, true)
                            }} />
                        </span>
                      </button>
                    );
                  })}
                {filterUsers &&
                  filterUsers.map(fu => {
                    return (
                      <button className="btn btn-dark btn-sm" key={fu._id}>
                        {fu.name}
                        <span>
                          <img className="img-fluid" src={CloseWhite} alt="" onClick={() => {
                            this.selectUser(fu, true)
                            }} />
                        </span>
                      </button>
                    );
                  })}
                {filterPayments &&
                  filterPayments.map(fu => {
                    const label = paymentTypes[paymentTypes.map(({ type }) => type).indexOf(fu)].label;
                    return (
                      <button className="btn btn-dark btn-sm" key={fu}>
                        {label}
                        <span>
                          <img
                            className="img-fluid"
                            src={CloseWhite}
                            alt=""
                            onClick={() => {
                              this.selectPayment({ type: fu, label }, true)
                            }}
                          />
                        </span>
                      </button>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {orders.length === 0 ? (
                <NotFound title="No Orders Found" />
              ) : (
                <div className="table-responsive">
                  <table className="table custom-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th onClick={() => this.setOrder("ticketNumber")}>
                          Ticket # {sortOrder["ticketNumber"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                        </th>
                        <th onClick={() => this.setOrder("customer.companyName")}>
                          Company Name{" "}
                          {sortOrder["customer.companyName"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                        </th>
                        <th onClick={() => this.setOrder("driver.firstName")}>
                          Driver Name {sortOrder["driver.firstName"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                        </th>
                        <th onClick={() => this.setOrder("totalAmount")}>
                          Total Order Amount {sortOrder["totalAmount"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                        </th>
                        <th onClick={() => this.setOrder("paymentType")}>
                          Payment Type {sortOrder["paymentType"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                        </th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order, i) => {
                        // const { paymentType, btnClass } = this.getPaymentType(order);
                        return (
                          <tr key={order._id}>
                            <td>
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={ticketNumbers.indexOf(order.ticketNumber) !== -1 ? true : false}
                                  onChange={() => this.onClickCheckbox(order.ticketNumber)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </td>
                            <td>{order.ticketNumber}</td>
                            <td>{_.get(order, "customer.displayName", "")}</td>
                            <td>{`${_.get(order, "driver.firstName", "")} ${_.get(order, "driver.lastName", "")}`}</td>
                            <td>
                              <NumberFormat
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                displayType={"text"}
                                value={order.totalAmount}
                              />
                            </td>
                            <td>
                              {
                                order?.paymentTerms === "account" ?
                                  <button className={this.showPaymentTypes("css", '')}>{this.showPaymentTypes("paymentType", '')}</button>
                                  : order.paymentType === "" ? order.splitPayment.length > 0 && order.splitPayment.map((payment, index) => {
                                    return (
                                      <button className={(this.showPaymentTypes("css", payment.paymentType)) + (index < order.splitPayment.length - 1 ? " mr-2" : "")}>{this.showPaymentTypes("paymentType", payment.paymentType)}</button>)
                                  })
                                    :
                                    <button className={this.showPaymentTypes("css", order.paymentType)}>{this.showPaymentTypes("paymentType", order.paymentType)}</button>
                              }
                            </td>
                            <td onClick={() => this.downloadRecord(order)}>
                              {pdfLoadar && pdfLoadarIndex === i ? (
                                <Spin indicator={antIcon} spinning />
                              ) : (
                                <img
                                  className="img-fluid cursor-pointer download__icon"
                                  src={DownloadArrow}
                                  alt=""
                                  onClick={() => this.setState({ pdfLoadarIndex: i, pdfLoadar: true })}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
          {/*Order Filters*/}
          <Drawer
            title="Basic Drawer"
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
            className="select__driver-drawer"
          >
            <div className="head-section-header d-flex align-items-center justify-content-between">
              <button className="btn btn-dark btn-sm clear-btn" onClick={this.clearFilter.bind(this)}>
                Clear
              </button>
              <div className="head-section-title">Filters</div>
              <div className="head-section-cta">
                <div onClick={this.onClose} className="btn btn-link btn-back">
                  <CloseIcon />
                </div>
              </div>
            </div>
            <ul className="filter__list">
              {/* <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={this.openFilterList}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.dateFilter}
                    />
                    <span className="checkmark"></span>
                    <p>Date</p>
                  </label>
                </span>
                <div className={`filter__list-item-inner ${this.state.dateFilter ? "show-list-detail" : ""}`}>
                  <div className="calendar__form">
                    <div className="input-from">
                      <div className="form-group">
                        <DatePicker
                          name="from"
                          selected={this.state.from}
                          onChange={this.handleFromChange}
                          className="form-control material-textfield-input"
                          placeholder="10/01/20"
                          required
                          autoComplete="off"
                        />
                        <label className="calendar-label">From</label>
                      </div>
                    </div>
                    <div className="input-to">
                      <div className="form-group">
                        <DatePicker
                          name="to"
                          selected={this.state.to}
                          onChange={this.handleToChange}
                          className="form-control material-textfield-input"
                          placeholder="10/01/20"
                          required
                          autoComplete="off"
                        />
                        <label className="calendar-label">To</label>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={this.openFilterList}
                      name="companyFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.companyFilter}
                    />
                    <span className="checkmark"></span>
                    <p>Companies</p>
                  </label>
                </span>
                <div className={`filter__list-item-inner ${this.state.companyFilter ? "show-list-detail" : ""}`}>
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      onChange={this.handleCustomerSearch}
                      value={this.state.companySearchText}
                      className="form-control material-textfield-input"
                      required
                    />
                    <label className="material-textfield-label">Search companies...</label>
                  </div>
                  <div className="select_driver-wrapper">
                    <div>
                      <ul className="select_driver-list box-shadow">
                        {customers.map(customer => {
                          const fcIndex = _.findIndex(filterCustomers, function(fc) {
                            return String(fc._id) === String(customer._id);
                          });
                          return (
                            <li className={fcIndex !== -1 ? "active" : ""} key={customer._id}>
                              <div className="d-flex align-items-center">
                                <p className="driver__name">{_.get(customer, "displayName", "")}</p>
                              </div>
                              <label className="custom-checkbox custom-checkbox-line">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  onChange={() => this.selectCustomer(customer)}
                                  checked={fcIndex !== -1 ? true : false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={this.openFilterList}
                      name="driverFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.driverFilter}
                    />
                    <span className="checkmark"></span>
                    <p>Drivers</p>
                  </label>
                </span>
                <div className={`filter__list-item-inner ${this.state.driverFilter ? "show-list-detail" : ""}`}>
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control material-textfield-input"
                      onChange={this.handleDriverSearch}
                      value={this.state.driverSearchText}
                      required
                    />
                    <label className="material-textfield-label">Search drivers...</label>
                  </div>
                  <div className="select_driver-wrapper">
                    <div>
                      <ul className="select_driver-list box-shadow">
                        {drivers.map(driver => {
                          const fdIndex = _.findIndex(filterDrivers, function(fd) {
                            return String(fd._id) === String(driver._id);
                          });
                          return (
                            <li className={fdIndex !== -1 ? "active" : ""} key={driver._id}>
                              <div className="d-flex align-items-center">
                                <p className="driver__name">
                                  {driver.firstName} {driver.lastName}
                                </p>
                              </div>
                              <label className="custom-checkbox custom-checkbox-line">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  onChange={() => this.selectDriver(driver)}
                                  checked={fdIndex !== -1 ? true : false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={this.openFilterList}
                      name="userFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.userFilter}
                    />
                    <span className="checkmark"></span>
                    <p>Users</p>
                  </label>
                </span>
                <div className={`filter__list-item-inner ${this.state.userFilter ? "show-list-detail" : ""}`}>
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control material-textfield-input"
                      onChange={this.handleUserSearch}
                      value={this.state.userSearchText}
                      required
                    />
                    <label className="material-textfield-label">Search users...</label>
                  </div>
                  <div className="select_driver-wrapper">
                    <div>
                      <ul className="select_driver-list box-shadow">
                        {users.map(user => {
                          const fuIndex = _.findIndex(filterUsers, function(fu) {
                            return String(fu._id) === String(user._id);
                          });
                          return (
                            <li className={fuIndex !== -1 ? "active" : ""} key={user._id}>
                              <div className="d-flex align-items-center">
                                <p className="driver__name">
                                  {user.firstName} {user.lastName}
                                </p>
                              </div>
                              <label className="custom-checkbox custom-checkbox-line">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  onChange={() => this.selectUser(user)}
                                  checked={fuIndex !== -1 ? true : false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={this.openFilterList}
                      name="paymentTypeFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.paymentTypeFilter}
                    />
                    <span className="checkmark"></span>
                    <p>Payment Type</p>
                  </label>
                </span>
                <div className={`filter__list-item-inner ${this.state.paymentTypeFilter ? "show-list-detail" : ""}`}>
                  <div className="select_driver-wrapper">
                    <div>
                      <ul className="select_driver-list box-shadow">
                        {paymentTypes.map(payment => {
                          const fuIndex = _.findIndex(filterPayments, function(fu) {
                            return String(fu) === String(payment.type);
                          });
                          return (
                            <li className={fuIndex !== -1 ? "active" : ""} key={payment.label}>
                              <div className="d-flex align-items-center">
                                <p className="driver__name">{payment.label}</p>
                              </div>
                              <label className="custom-checkbox custom-checkbox-line">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  onChange={() => this.selectPayment(payment)}
                                  checked={fuIndex !== -1 ? true : false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </Drawer>
          {/*Order Filters End*/}       

        </main>
        {/* <DateRangePicker
          datePickerModal={this.state.datePickerModal}
          closeModal={this.closeModal}
          setDate={() => {
            this.setState(
              {
                dateType: "custom",
                skip: 0,
                currentPage: 1,
                selectedDate: `${moment(this.state.startDate)?.clone()?.format("MMM DD, YYYY")} - ${moment(
                  this.state.endDate
                )?.clone()?.format("MMM DD, YYYY")}`
              },
              () => {
                this.closeModal();
                this.fetchReports();
              }
            );
          }}
          handleFromChange={date => this.setState({ startDate: moment(date)?.clone()?.tz(this.state?.tz)?.toDate() })}
          handleToChange={date => this.setState({ endDate: moment(date)?.clone()?.tz(this.state?.tz)?.toDate() })}
          from={this.state.startDate}
          to={this.state.endDate}
        /> */}
        <Pagination
          className="text-center"
          onChange={this.onChangePage}
          hideOnSinglePage
          current={this.state.currentPage}
          total={this.state.totalItems}
          pageSize={this.state.limit}
          showSizeChanger={false}
        />
       </Spin>
      </div>
    );
  }
}
